import React from "react";
import { attemptGetLatestReviews } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import ForexGradSocket from "../../api/WebSocket";
import {BROKERS_CHARTS_CUR_CREATOR} from "../../configs/constants";
import {Link} from "react-router-dom";


class ChartTableItem extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          currensyArray: [
              {
                  id : 1,
                  name : "EURUSD",
                  category : "forex",
                  sysname: "eurusd",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 2,
                  name : "USDJPY",
                  category : "forex",
                  sysname: "usdjpy",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 3,
                  name : "GBPUSD",
                  category : "forex",
                  sysname: "gbpusd",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 4,
                  name : "#S-MSFT",
                  category : "stocks",
                  sysname: "msft",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 5,
                  name : "#S-GOOG",
                  category : "stocks",
                  sysname: "goog",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 6,
                  name : "#S-AAPL",
                  category : "stocks",
                  sysname: "aapl",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 7,
                  name : "SP500",
                  category : "indices",
                  sysname: "sp500",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 8,
                  name : "DJI",
                  category : "indices",
                  sysname: "dji",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 9,
                  name : "XAUUSD",
                  category : "precious-metals",
                  sysname: "xauusd",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              },
              {
                  id : 10,
                  name : "#C-BRENT",
                  category : "commodities",
                  sysname: "brent",
                  livePrice: "---/---",
                  percent: "---",
                  status: ""
              }
          ],
          instrName: []
      }
  }

  // (item.symbol.slice(0,3), item.symbol.slice(3,6));

  getTableItemQuote() {
      this.state.currensyArray.map(item => {
          return this.state.instrName.push(item.name)
      })
      const Socket = new ForexGradSocket(
      () => Socket.sendQuotes(this.state.instrName),
      data => {
        for(let i = 0; i < this.state.currensyArray.length; i++){
            if(this.state.currensyArray[i].name === data.symbol){
                this.setState(prevState => ({
                    instr: prevState.currensyArray.map(
                        cur => (cur.id === (i+1) ?
                            Object.assign(cur, {
                                percent: data.percent,
                                livePrice: `${data.ask} / ${data.bid}`,
                                status: data.state
                            }) : cur)
                        )
                }));
            }
        }
      }
    );
  }

  getTableItemQuoteClose() {
    const Socket = new ForexGradSocket(() => Socket.close());
  }

  componentDidMount() {
    this.getTableItemQuote();
  }

  componentWillUnmount() {
    this.getTableItemQuoteClose();
  }
  render() {
      return this.state.currensyArray.map((instr) => {
          const {language, id, name, category, sysname, percent, livePrice, status} = instr
          return (
              <tr key={id}>
                  <td>
                      <Link
                      to={BROKERS_CHARTS_CUR_CREATOR(language,category, sysname)} >
                      {name}
                      </Link>
                  </td>
                  <td>{livePrice}</td>
                      <td className={`tableItemStatus${status}`}>
                         {`${
                           status === "up"
                             ? "+"
                            : status === "down"
                           ? "-"
                             : ""
                       }${percent}%`}
                    </td>
              </tr>
          )
      })
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetLatestReviews: (sysname, countPerPage, page, lang) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, page, lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(ChartTableItem)
);
