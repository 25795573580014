import React from "react";
import "./../../assets/css/components/education_steps.css";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";

const arrEdu = [
  {
    titleEduBlk: "INTRODUCTION_TO_TRADING",
    p_1: "EVERYTHING_ABOUT_FOREX",
    p_2: "EVERYTHING_ABOUT_CFD",
    p_3: "MANY_MORE",
    step: 1
    // link_to: "{BROKERS_ABOUT_US}",
  },
  {
    titleEduBlk: "WORKING_ON_A_PLATFORM",
    p_1: "WHAT_IS_A_TRADING_PLATFORM",
    p_2: "NET_TRADE_X",
    p_3: "META_TRADER_4",
    step: 2
  },
  {
    titleEduBlk: "START_TRADING",
    p_1: "OPEN_ACCOUNT",
    p_2: "SINGLE_TRADING_ACCOUNT",
    p_3: "MANY_MORE",
    step: 3
  },
  {
    titleEduBlk: "MARKET_FORECAST",
    p_1: "HOW_TO_FORECAST_THE_MARKET",
    p_2: "TECHNICAL_ANALYSIS",
    p_3: "FUNDAMENTAL_ANALYSIS",
    step: 4
  }
];

class EducationSteps extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container mb-4 d-flex flex-wrap">
        <h2>
          {/*Education*/}
          {t("EDUCATION")}
        </h2>
        {arrEdu.map((item, index) => {
          return (
            <Link key={index} to={"/"} className="education">
              <div className="education_block">
                <div className="edu_text">{t(item.titleEduBlk)}</div>
                <div className="edu_txt_bl">
                  <p>{t(item.p_1)}</p>
                  <p>{t(item.p_2)}</p>
                  <p>{t(item.p_3)}</p>
                </div>
                <div className="edu_num">{item.step}</div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }
}
export default translate("common")(EducationSteps);
