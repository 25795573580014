import React from "react";

const OGlossary = () => (
  <div className="glossary_right_text">
    <div className="right_letter_a">
      <span>O</span>
    </div>
    <div className="right_text">
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Occelerator/Decelerator</h3>
      </a>
      <p>
        TThe Accelerator/Decelerator technical indicator shows the acceleration
        or deceleration of the current market driving force.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Accumulation/Distribution</h3>
      </a>
      <p>
        Accumulation/Distribution is an indicator designed to reflect cumulative
        inflows and outflows of money by comparing closing prices with
        corresponding highs and lows.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Alligator</h3>
      </a>
      <p>
        Alligator is an indicator created to identify the trends and their
        directions.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Arbitrage</h3>
      </a>
      <p>
        Simultaneous purchase of an undervalued financial asset and sale of its
        overvalued equivalent in order to make further risk-free profit from the
        price difference of assets which emerged as a result of temporary market
        inefficiency.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Ascending Triangle</h3>
      </a>
      <p>
        The Ascending triangle graphical price pattern is a chart pattern of an
        existing trend continuation, which is usually formed in an uptrend and
        confirms its further direction.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Ask price</h3>
      </a>
      <p>
        The ask price is the price at which one buys any financial instrument.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Ask Rate</h3>
      </a>
      <p>See ask price</p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Asset</h3>
      </a>
      <p>
        An instrument which has an economic value and may generate income in
        future.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>AUDUSD</h3>
      </a>
      <p>
        The Australian dollar and the US dollar currency pair. In this pair the
        Australian dollar is the base currency, and the US dollar is the quoted
        one.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Aussie</h3>
      </a>
      <p>Slang term for the Australian dollar.</p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Automated Trading</h3>
      </a>
      <p>
        Automated trading gives an opportunity to make the trading process
        absolutely automated.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Average Directional Index (ADX)</h3>
      </a>
      <p>
        Average Directional Index (ADX) is a technical indicator developed by
        Welles Wilder to determine the strength of a trend and the further price
        movement by analyzing the dynamics and the differences between the
        lowest and highest trading prices
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Accelerator/Decelerator</h3>
      </a>
      <p>
        The Accelerator/Decelerator technical indicator shows the acceleration
        or deceleration of the current market driving force.
      </p>
      <a href="https://www.ifcmarkets.com/en/forex-glossary">
        <h3>Accelerator/Decelerator</h3>
      </a>
      <p>
        The Accelerator/Decelerator technical indicator shows the acceleration
        or deceleration of the current market driving force.
      </p>
    </div>
  </div>
);

export default OGlossary;
