import React from "react";
import "./../../assets/css/components/education_steps.css";
import { translate } from "react-i18next";
import {attemptGetVideos} from "../../redux/actions/indexActions";
import {connect} from "react-redux";

const tabs = [
  {
    name: "IFC Markets",
    id: 1,
  },
  {
    name: "FXTM",
    id: 2,
  },
  {
    name: "IG",
    id: 4,
  },
  {
    name: "FSB",
    id: 27,
  }
];
class EducationTabs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  getVideoByComp(brokerID){
    this.props.attemptGetVideos(this.props.language,brokerID)
  }

  render() {
    const { t } = this.props;
    const classNames = "single-tab col-auto mb-2 mr-2 d-inline-block";
    return (
      <div className="ed-tab-cont d-flex justify-content-end align-items-center mb-3">
            {tabs.map((item, index) => {
              return (
                  <div className={this.state.active === index ? "active " + classNames  : classNames}  onClick={() =>
                    this.setState({active: index},() => { this.getVideoByComp(item.id)})} >
                    {t(item.name)}
                  </div>
              );
            })}
      </div>
    );
  }
}

export default connect(
    state => ({
      language: state.language
    }),
    dispatch => ({
      attemptGetVideos: (lang,brokerID) => dispatch(attemptGetVideos(lang,brokerID)),
    })
)(translate('common')(EducationTabs));

