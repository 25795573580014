import React from "react";
import "./../assets/css/components/trading_platforms.css";
import "./../assets/css/components/video_lessons.css";
import "./../assets/css/components/education_traders_glossary.css";
import TopBrokersContainer from "../containers/TopBrokers";

import { Link } from "react-router-dom";
import EducationSteps from "../components/content/EducationSteps";
import EducationSections from "../containers/education/EducationSections";

import AGlossary from "../containers/Glossary/AGlossary";
import BGlossary from "../containers/Glossary/BGlossary";
import CGlossary from "../containers/Glossary/CGlossary";
import DGlossary from "../containers/Glossary/DGlossary";
import EGlossary from "../containers/Glossary/EGlossary";
import FGlossary from "../containers/Glossary/FGlossary";
import GGlossary from "../containers/Glossary/GGlossary";
import HGlossary from "../containers/Glossary/HGlossary";
import IGlossary from "../containers/Glossary/IGlossary";
import JGlossary from "../containers/Glossary/JGlossary";
import KGlossary from "../containers/Glossary/KGlossary";
import LGlossary from "../containers/Glossary/LGlossary";
import MGlossary from "../containers/Glossary/MGlossary";
import NGlossary from "../containers/Glossary/NGlossary";
import OGlossary from "../containers/Glossary/OGlossary";
import PGlossary from "../containers/Glossary/PGlossary";
import QGlossary from "../containers/Glossary/QGlossary";
import RGlossary from "../containers/Glossary/RGlossary";
import SGlossary from "../containers/Glossary/SGlossary";
import TGlossary from "../containers/Glossary/TGlossary";
import UGlossary from "../containers/Glossary/UGlossary";
import VGlossary from "../containers/Glossary/VGlossary";
import WGlossary from "../containers/Glossary/WGlossary";
import XGlossary from "../containers/Glossary/XGlossary";
import YGlossary from "../containers/Glossary/YGlossary";
import ZGlossary from "../containers/Glossary/ZGlossary";

// const arr = [
//     {
//         img: "video_les",
//         title: "What is Forex | Forex (FX) Trading Market",
//         p: "Forex Market is a decentralized global market where all the world's currencies are traded against each other, and traders make a profit or loss from the … currencies’ value changes.",
//         pdfIcon: "ifcm-pdf",
//         pdfText: "Forex Trading Tutorial",
//         href: "/en/video-other",
//         btnHref: "/about-us",
//         btnText: "Read More"
//     },
//     {
//         img: "video_les",
//         title: "What is Forex | Forex (FX) Trading Market",
//         p: "Forex Market is a decentralized global market where all the world's currencies are traded against each other, and traders make a profit or loss from the … currencies’ value changes.",
//         pdfIcon: "ifcm-pdf",
//         pdfText: "Forex Trading Tutorial",
//         href: "/en/video-other",
//         btnHref: "/about-us",
//         btnText: "Read More"
//     },
//
// ];

const glossaryElements = [
  { letter: "A", component: <AGlossary /> },
  { letter: "B", component: <BGlossary /> },
  { letter: "C", component: <CGlossary /> },
  { letter: "D", component: <DGlossary /> },
  { letter: "E", component: <EGlossary /> },
  { letter: "F", component: <FGlossary /> },
  { letter: "G", component: <GGlossary /> },
  { letter: "H", component: <HGlossary /> },
  { letter: "I", component: <IGlossary /> },
  { letter: "J", component: <JGlossary /> },
  { letter: "K", component: <KGlossary /> },
  { letter: "L", component: <LGlossary /> },
  { letter: "M", component: <MGlossary /> },
  { letter: "N", component: <NGlossary /> },
  { letter: "O", component: <OGlossary /> },
  { letter: "P", component: <PGlossary /> },
  { letter: "Q", component: <QGlossary /> },
  { letter: "R", component: <RGlossary /> },
  { letter: "S", component: <SGlossary /> },
  { letter: "T", component: <TGlossary /> },
  { letter: "U", component: <UGlossary /> },
  { letter: "V", component: <VGlossary /> },
  { letter: "W", component: <WGlossary /> },
  { letter: "X", component: <XGlossary /> },
  { letter: "Y", component: <YGlossary /> },
  { letter: "Z", component: <ZGlossary /> }
];

export default class EducationTradersGlossary extends React.Component {
  state = {
    index: false,
    activeIndex: 0
  };

  render() {
    return (
      <div>
        <EducationSteps />
        <div className="container mb-4 d-flex flex-wrap">
          <div className="video_less_wrap main_with_left">
            <h1>Trading Terminology: Gloassary of Forex and CFD Terms</h1>
            <div className="bg_wrap">
              <div className="glossary_text">
                <div>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
                <div>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
                <div>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </div>
              </div>
              <div className="glossary_second_text">
                <div className="alphabet_block">
                  <div className="left_letter">
                    {glossaryElements.map((item, activeIndex) => (
                      <div
                        key={activeIndex}
                        className={`${
                          this.state.activeIndex === activeIndex
                            ? "active_green"
                            : ""
                        }`}
                        onClick={() => this.setState({ activeIndex })}
                      >
                        {item.letter}
                      </div>
                    ))}
                  </div>
                </div>
                {glossaryElements[this.state.activeIndex].component}
              </div>
            </div>
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <EducationSections />
            </div>
            <div className="expel_class top_5">
              <TopBrokersContainer />
            </div>
            <div className="expel_class">
              <Link
                to={"/"}
                className="btn btn_sm button_grayish_blue demo_btn"
              >
                Start Education on Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
