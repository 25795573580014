import React from "react";
import {Helmet} from "react-helmet";
import Reopen_Buy_image from "./../assets/images/Reopen_Buy.jpg";
import Reopen_Sell_image from "./../assets/images/Reopen_Sell.jpg";
import MACD_Close_image from "./../assets/images/MACD_Close.jpg";
import Ma_cross_image from "./../assets/images/ma_cross.jpg";
import {BUY_ADVISER_MAKER} from "../configs/constants";

const advisers = {
    'Reopen_Buy': {
        'introduction': '\n' +
            '<p>This Expert Advisor allows you to use the situation of a strong support price level for profit.</p>\n' +
            '<p>In the price dynamics of a trading instrument, there is often a situation of price consolidation in approximately the same range. At the same time, the price does not just stand at the same level, but fluctuates in a relatively narrow range. Moreover, if upward fluctuations go to different price levels, then downward fluctuations go to a strong support level, where the financial instrument is quickly redeemed and the price grows. This situation is often related to the following reasons:</p>\n' +
            ' <ul class="ul-tick mb-30">\n' +
            '    <li>A fundamentally sound buying level where market participants are confident that this is a good price for future profits;</li>\n' +
            '    <li>The presence of a large buyer (for example, a large order on which the brokerage company buys any volume);</li>\n' +
            '    <li>Waiting for an important event to occur (for example, the publication of important data) and consolidation continues until this moment;</li>\n' +
            '</ul>',
        'about': '<p>The purpose of the Expert Advisor is to automatically place a BUYLIMIT order at the support price level, at which the last closed BUY position was previously opened (as a rule, this closing was performed with a profit on the associated Take profit order). This order will be placed with the same volume and related orders as this closed position.</p>\n' +
            '<p class="mb-30">Thus, if the price goes down to this support level, then we will actually re-open the already profitable BUY position at the same price level.</p>',
        'parameters': '<p> <strong>Minimum position</strong> (Minpoz) = 3</p>\n' +
            '<p>Additionally, the Expert Advisor has an integer parameter “Minpoz”, which blocks placing a BUYLIMIT order if there are more open Minimum positions and orders of the Buy class on the trading account. The Buy class includes BUY positions, BUYLIMIT orders and BYUSTOP orders. Minpoz = 3 by default, but the user can set a different parameter value when linking this Expert Advisor to a trading instrument chart. If the user sets Minpoz less than or equal to 0, then there is no barrier to setting at the BUYLIMIT order support level.</p>\n' +
            '<p class="mb-30">Note that all these positions and orders refer to one trading instrument.</p>',
        'algorithm': '<p>The trader conducts technical analysis on the price chart and makes sure that there is a price consolidation phase and there is a clearly visible support level, and also believes that this consolidation will last long enough and the price will rise for some time, but then return to the support level.</p>\n' +
            '<p>The trader opens the first buy position at the support level manually, also linking a Take profit order and a Stop loss order to the position.</p>\n' +
            '<p>Next, the trader binds to the trading instrument and launches the Reopen_Buy Expert Advisor.</p>\n' +
            '<p>When the price rises to the level of the associated Take profit order, the position will automatically close and the EA will detect this situation (it will be the last closed position), and immediately open a limit order to buy at the level of opening this position, i.e. at the support level. Moreover, this order will be opened by the EA with the same volume and the same related Take profit and Stop loss orders as this closed position.</p>\n' +
            '<p>When the price drops again to the support level, then a buy position will be opened again on this limit order and we will again wait for the price to rise and close the position profitably.</p>\n' +
            '<p> And this process will continue with profit taking and re-opening of a new position until one of the following events occurs:</p>\n' +
            ' <ul class="ul-tick mb-30">\n' +
            '    <li>The support level will be broken and the price will reach the Stop loss level of the current open position, i.e. the position will be closed and the advisor will no longer be able to place a limit order to buy (after that, the trader must untie the advisor from the trading instrument)</li>\n' +
            '    <li>The trader decides that the situation of consolidation with the support level is over and therefore unties the advisor from the trading instrument and manually closes the current position.</li>\n' +
            '</ul>',
        'image' : Reopen_Buy_image
    },
    'Close_pos_stop': {
        'introduction': '\n' +
            '<p>This Expert Advisor closes all stop orders and open positions of a given volume for a trading instrument selected by the user. Advisor type - non-trading, one-time action; after working out, it is recommended to untie the advisor from the trading instrument.</p>\n' +
            '<p class="mb-30">In active trading, within the framework of certain strategies of the trader and on accounts of the "Hedge" type ("lock" positions are acceptable), a fairly large number of positions and stop orders are opened for one trading instrument, but the trader decided to quickly close all or part of the positions and/or stop orders.</p>\n',
        'about': '<p>Advisor type - non-trading, one-time action.</p>\n' +
        '<p>The purpose of the EA is to automatically close a relatively large number of positions and stop orders previously opened, as a rule, by EAs by placing a series of stop orders (grid trading method on a stable price range).</p>\n' +
        '<p>If a trader needs to quickly close positions and/or stop orders for one trading instrument, then he binds the advisor to the trading instrument or activates the already attached advisor.</p>\n' +
        '<p>Additionally, a trader can set up filters for this operation - set the exact volume and direction of the stop orders to be deleted, as well as add closing positions.</p>\n' +
        '<p>After working out, it is recommended to unlink the advisor from the trading instrument or deactivate it through the general parameters (cancel the "Allow the advisor to trade" mode).</p>\n',
        'parameters': '<ul class="ul-tick">\n' +
            '<li><strong>Lots</strong> = 0.1</li>\n' +
            '</ul>\n' +
            '<p>Parameter values: &gt; 0 - all stop orders (and positions with ClosePos = 1) of the Lots volume are deleted; 0.0 - stop orders (and positions) of any volume are deleted.</p>\n' +
            '<ul class="ul-tick">\n' +
            '<li><strong>BUYSELL</strong> = 0</li>\n' +
            '</ul>\n' +
            '<p>Parameter values: 0 &ndash; both Buy and Sell orders are deleted; 1 - only Buy orders, -1 - only Sell orders.</p>\n' +
            '<ul class="ul-tick mb-30">\n' +
            '<li><strong>ClosePos</strong> = 1</li>\n' +
            '</ul>\n' +
            '<p>Parameter values: 0 - do not close positions, 1 - close positions.</p>' +
            '<p>The purpose of the Expert Advisor is to automatically close a relatively large number of stop orders previously opened, usually by EAs by placing a series of stop orders (grid trading method on a stable price range).</p>' +
            '<p class="mb-30">The user can set other parameter values when linking this Expert Advisor to the chart of a trading instrument.</p>',
        'algorithm': '<p>From the moment the Expert Advisor is activated, a search is performed for all positions and orders of the trading instrument that match the specified filters. Found positions are closed, and stop orders are removed within a few ticks of price changes.</p>\n' +
            '<p>After the completed actions, it is recommended to deactivate the advisor, since the active advisor will constantly look for stop orders and positions, which will prevent trading this trading instrument</p>\n',
    },
    'MACD_close': {
        'introduction': '\n' +
            '<p>This Expert Advisor closes a position based on the sign change of the MACD indicator (the fast average crosses the slow average) for the trading instrument selected by the user. Advisor type - non-trading, one-time action; after working out, it is recommended to unlink the advisor from the trading instrument.</p>\n',
        'about': '<p>In medium-term and long-term trading, the task of closing a position can be solved using the signal of the classic MACD indicator. The EA closes the position on the basis of the sign change of the MACD indicator (the fast average crosses the slow average), which means a change in the sign of the price trend.</p>\n' +
            '<p>The periods of fast and slow exponential averages are set by the trader in the input parameters.</p>'+
            '<p class="mb-30">Advisor type - non-trading, one-time action; after working out, it is recommended to untie the advisor from the trading instrument or deactivate it through the general parameters (cancel the "Allow the advisor to trade" mode).</p>',
        'parameters': '<ul class="ul-tick mb-30">\n' +
            '<li><strong>Fast</strong> = 60; &gt;0 &ndash; fast average period of the MACD indicator.</li>\n' +
            '<li><strong>Slow</strong>=130; &gt;0 is the period of the slow average of the MACD indicator.</li>\n' +
            '<li><strong>Signal</strong> = 0; &gt;0 &ndash; the period of the signal average of the MACD indicator.</li>\n' +
            '</ul>\n' +
            '<p class="mb-30">The user can set other values of the averages of the MACD indicator when linking this Expert Advisor to the chart of a trading instrument.</p>',
        'algorithm': '<p>The EA closes the position on the basis of the sign change of the MACD indicator (the fast average crosses the slow average).</p>\n' +
            '<p>If the fast average of the indicator crosses the slow average from the bottom up, then this is a Buy signal and the sell position is closed; 2. If the fast average of the indicator crosses the slow average from top to bottom, then this is a Sell signal and the buy position is closed.</p>\n' +
            '<p>The additional parameter "Signal average period" does not affect the position closing signal and is used for graphical confirmation of the price growth or fall trend.</p>\n' +
            '<p>After closing a position, it is recommended to untie the EA from the trading instrument, since the active EA will constantly calculate the crossover of MACD averages, which will prevent trading this trading instrument.\n' +
            '\n</p>\n',
        'image' : MACD_Close_image,
    },
    'Reopen_Sell': {
        'introduction': '\n' +
            '<p>This Expert Advisor allows you to use the situation of a strong price resistance level for profit.</p>\n' +
            '<p>In the price dynamics of a trading instrument, there is often a situation of price consolidation in approximately the same range. At the same time, the price does not just stand at the same level, but fluctuates in a relatively narrow range. Moreover, if downward fluctuations go to different price levels, then upward fluctuations go to a strong resistance level, where the financial instrument is quickly sold and the price falls. This situation is often associated with the following motives:</p>\n' +
            ' <ul class="ul-tick mb-30">\n' +
            '    <li>A fundamentally justified selling level, where market participants are sure that this is a good price for profit taking and selling a trading instrument;</li>\n' +
            '    <li>The presence of a large seller (for example, a large order on which the brokerage company sells large volumes);</li>\n' +
            '    <li>Waiting for an important event to occur (for example, the publication of important data) and consolidation continues until this moment.</li>\n' +
            '</ul>',
        'about': '<p>The purpose of the EA is to automatically place a SELLLIMIT order at the price resistance level at which the last closed SELL position was previously opened (as a rule, this was closed with a profit on the associated Take profit order). This order will be placed with the same volume and related orders as this closed position.</p>\n' +
            '<p class="mb-30">Thus, if the price goes up to this resistance level, then we will actually re-open the already profitable SELL position at the same price level.</p>',
        'parameters': '<p> <strong>Minimum position</strong> (Minpoz) = 3</p>\n' +
            '<p>Additionally, the Expert Advisor has an integer parameter “Minpoz”, which blocks placing a SELLLIMIT order if there are more than Minpoz open positions and orders of the SELL class on the trading account. The SSELL class includes BUY positions, SELLLIMIT orders and SELLSTOP orders. Minpoz = 3 by default, but the user can set a different parameter value when linking this Expert Advisor to a trading instrument chart. If the user sets Minpoz less than or equal to 0, then there are no obstacles for placing a SELLLIMIT order at the resistance level.</p>\n' +
            '<p class="mb-30">Note that all these positions and orders refer to one trading instrument.</p>',
        'algorithm': '<p>The trader conducts technical analysis on the price chart and makes sure that there is a price consolidation phase and there is a clearly visible resistance level, and also believes that this consolidation will last long enough and the price will fall for some time, but then return to the resistance level.</p>\n' +
            '<p>The trader opens the first sell position at the resistance level manually, also linking a Take profit order and a Stop loss order to the position.</p>\n' +
            '<p>Next, the trader binds to the trading instrument and launches the Reopen_Sell Expert Advisor.</p>\n' +
            '<p>When the price drops to the level of the linked Take profit order, the position will automatically close and the EA will detect this situation (this will be the last closed position), and immediately open a limit order to buy at the level of opening this position, i.e. at the level of resistance. Moreover, this order will be opened by the EA with the same volume and the same related Take profit and Stop loss orders as this closed position.</p>\n' +
            '<p>When the price rises again to the resistance level, then a sell position will be opened again on this limit order and we will again wait for the price to fall and close the position profitably.</p>\n' +
            '<p>And this process will continue with profit taking and re-opening of a new position until one of the following events occurs:</p>\n' +
            ' <ul class="ul-tick mb-30">\n' +
            '    <li>The resistance level will be broken and the price will reach the Stop loss level of the current open position, i.e. the position will be closed and the advisor will no longer be able to place a limit sell order (after that, the trader must unlink the advisor from the trading instrument);</li>\n' +
            '    <li>The trader decides that the situation of consolidation with the resistance level is over and therefore unties the advisor from the trading instrument and manually closes the current position.</li>\n' +
            '</ul>',
        'image' : Reopen_Sell_image,
    },
    'Del_pos_order': {
        'introduction': '\n' +
            '<p>This Expert Advisor closes positions and orders on the account with a possible selection by volume, direction and trading instrument.</p>\n' +
            '<p class="mb-30">In active trading, within the framework of certain strategies of the trader and on accounts of the "Hedge" type ("lock" positions are acceptable), both for one trading instrument and for all instruments, a fairly large number of positions and orders are opened, but the trader decided to quickly close all or part of positions and/or orders.</p>\n',
        'about': '<p>Advisor type - non-trading, one-time action.</p>\n' +
            '<p>The purpose of the Expert Advisor is to automatically close a relatively large number of positions and orders both for one trading instrument and for all trading instruments of the account.</p>'+
            '<p>If a trader needs to quickly close positions and/or orders for one or all of the trading instruments of the account, then he binds the advisor to the trading instrument or activates the already attached advisor.</p>'+
            '<p>Additionally, a trader can set up filters for this operation - set the exact volume and direction of orders to be deleted and positions to be closed.</p>'+
            '<p class="mb-30">After working out, it is recommended to untie the advisor from the trading instrument or deactivate it through the general parameters (cancel the "Allow the advisor to trade" mode).</p>',
        'parameters': '<ul class="ul-tick mb-30">\n' +
            '<li>\n' +
            '<p><strong>exsym</strong>="USD"; trading instrument ticker ("NULL" &ndash; ticker is not set).</p></li>\n' +
            '<li><p><strong>Lots</strong> = 0.10; Parameter values: n.n &ndash; selection of positions and orders of only a given volume,</p>\n' +
            '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 0.0 &ndash; selection of positions, stop and limit orders of any volume.</p></li>\n' +
            '<li><p><strong>BUYSELL</strong> = 0; Parameter values: 0 &ndash; selection of positions and orders of any direction,</p>\n' +
            '<p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;1 - only BUY, -1 - only SELL, 9 - prohibition of all deletions.</p></li>\n' +
            '<li><p><strong>rej_symb</strong> = 0; Parameter values: 0 - only trading instruments of the EA\'s anchor chart,</p>\n' +
            '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1 - all trading instruments,</p>\n' +
            '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2 &ndash; trading instrument specified in the <strong>exsum</strong> parameter,</p>\n' +
            '<p>&nbsp;&nbsp;&nbsp;&nbsp; -1 - disable all deletions.</p></li>\n' +
            '</ul>' +
            '<p class="mb-30">The user can set other values for these parameters when linking an Expert Advisor to a trading instrument chart.</p>\n',
        'algorithm': '<p>From the moment the Expert Advisor is activated, a search is performed for all positions and orders of the trading instrument that match the specified filters. Found positions are closed, and orders are deleted within a few ticks of price changes.</p>\n' +
            '<p>An interesting feature of this Expert Advisor is that the task of a trading instrument can be in three versions:</p>\n' +
            ' <ul class="ul-tick mb-30">\n' +
            '    <li>Only a trading tool for binding an advisor;</li>\n' +
            '    <li>The trading instrument specified by the “exsum” parameter (regardless of the anchor trading instrument);</li>\n' +
            '    <li>All trading instruments of the account.</li>\n' +
            '</ul>' +
            '<p> After working out, it is recommended to deactivate the advisor, since an active advisor will constantly search for and close orders and positions, which will prevent trading trading instruments.</p>\n',
    },
    'Ma_cross': {
        'introduction': '\n' +
            '<p>This Expert Advisor allows you to use the classic strategy of opening a position for profit based on the beginning of a new trend through the intersection of two exponential averages.</p>\n' +
            '<p class="mb-30">The signal to open a position comes when the fast average crosses the slow average in the direction of the position being opened.</p>\n',
        'about': '<p>The intersection of two exponential averages sets a short, as a rule, beginning of a new price movement, which allows you to quickly open a position in the direction of this new movement. Closing this position and simultaneously opening a new position in the opposite direction is performed at the reverse intersection of these two moving averages.</p>\n' +
            '<p class="mb-30">This approach does not, as a rule, give a good result, because exponential averages change their direction relatively quickly and the opening of a position is most often late. In this regard, a related Take profit order is placed on the position, which allows you to take a small but quick profit on most of the opened positions.</p>\n',
        'parameters': '<ul class="ul-tick mb-30">\n' +
            '<li><strong>ma_fast</strong> = 60; period of fast exponential average</li>\n' +
            '<li><strong>ma_slow</strong> = 240; period of slow exponential average</li>\n' +
            '<li><strong>Lots</strong> = 0.01; &nbsp;&nbsp;&nbsp;&nbsp;volume of opened positions in lots</li>\n' +
            '<li><strong>StopLoss</strong> = 50; &nbsp;&nbsp;StopLoss order tied to the opened position in pips</li>\n' +
            '<li><strong>TakeProfit</strong> = 100; &nbsp;TakeProfit order tied to the opened position in pips</li>\n' +
            '</ul>',
        'algorithm': '<p>Expert Advisor is to automatically open a position at the moment of the intersection of the fast and slow exponential averages (this is a classic indicator of technical analysis), as well as to close all positions in the opposite direction. The direction of the opened position is determined by the following conditions:</p>\n' +
            ' <ul class="ul-tick mb-30">\n' +
            '    <li>If the fast average becomes higher than the slow average, then a BUY position is opened;</li>\n' +
            '    <li>If the fast average becomes lower than the slow average, then a SELL position is opened.</li>\n' +
            '</ul>' +
            '<p> Each position can be closed not only by the reverse crossing of the averages, but also by the attached Take profit or Stop loss orders.</p>\n' +
            '<p>The user can set the values of all parameters when linking the Expert Advisor to the chart of a trading instrument.</p>\n',
        'image': Ma_cross_image,
    },
};

const renderHtml = props => (
    <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class Advisers extends React.Component {
    render() {
        const introduction = advisers[this.props.match.params.slug].introduction
        const about = advisers[this.props.match.params.slug].about
        const parameters = advisers[this.props.match.params.slug].parameters
        const algorithm = advisers[this.props.match.params.slug].algorithm
        return (
            <div>
                <Helmet>
                    <title>{`Advisor ForexGrad | ForexGrad`}</title> todo
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    <h1>Expert Advisor "{this.props.match.params.slug}" </h1>
                    <div className="txt_wrap">
                        <h2>Introduction</h2>
                        {renderHtml(introduction)}
                        <h2>About the Expert Advisor</h2>
                        {renderHtml(about)}
                        {advisers[this.props.match.params.slug].image ? <img className="mb-30 mt-30" src={advisers[this.props.match.params.slug].image} alt={this.props.match.params.slug}/> : ''}
                        <h2>Parameters</h2>
                        {renderHtml(parameters)}
                        <h2>Advisor action algorithm.</h2>
                        {renderHtml(algorithm)}

                        <a
                            className="btn btn_sm button_grayish_blue"
                            href={BUY_ADVISER_MAKER("en",this.props.match.params.slug)}
                            rel="noopener noreferrer"
                        >
                            Buy Advisor
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Advisers ;