import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/global.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common";
import common_fa from "./translations/fa/common";

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    //fallbackLng: localStorage.getItem("language") || "en",
    fallbackLng:  "en",
    debug: true,
    defaultNS: "common", // this namespace will be used if no namespace shared via context
    fallbackNS: "common",
    resources: {
        en: {
            common: common_en // 'common' is our custom namespace
        },
        fa: {
            common: common_fa
        },

    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
