import React from "react";
import BrokerRating from "../components/BrokerRating";

import "./../assets/css/components/broker_rating.css";
import ANALYSIS from "../containers/Analysis";
import {connect} from "react-redux";
import {attemptGetBrokerList} from "../redux/actions/indexActions";
import {translate} from "react-i18next";
import {BROKERS_ROUTE_MAKER} from "../configs/constants";
import {Link} from "react-router-dom";

const BROKER_RATING_HEADERS = [
    "",
    "",
    "",
    "",
    "MINIMUM_SPREAD",
    "MINIMUM_DEPOSIT",
    "FOUNDED",
    ""
];

class BrokerRatings extends React.Component {
    componentDidMount() {
        // document.querySelector('.brokers_rating').style.width = window.innerWidth + 'px';
        this.props.attemptGetBrokerList(this.props.language, 10, 1);
    }

    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        const {t, language} = this.props;
        return (
            <div className="container mb-4 brokers_rating d-flex flex-wrap">
                <div className="brokers_container main_with_left">
                    <h5>
                        {t("BROKERS_RATING")}
                    </h5>
                    <div className="bg_wrap">
                        <div className="swipe">
                            {t("SWIPE_TABLE")} <span className="icon-right-small"></span>
                        </div>
                        <div>
                            <table>
                                <thead className="RatingCustom">
                                <tr>
                                    {BROKER_RATING_HEADERS.map((header, index) => (
                                        <th key={index}>{t(header)}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.brokers ? (
                                    this.props.brokers.map((item, index) => {
                                        let firstFourRowsClass = null;
                                        if (index < 5) {
                                            firstFourRowsClass = "firs_four_yellow";
                                        }
                                        return (
                                            <BrokerRating
                                                id={index}
                                                key={index}
                                                logo={item.logo}
                                                language={language}
                                                name={item.name}
                                                rate={item.averageRate.toFixed(1)}
                                                //minSpread={item.minSpread.toFixed(1)}
                                                minSpread={item.minSpread}
                                                minDeposit={item.minDeposit}
                                                founded={item.founded}
                                                btnText={item.externalUrl}
                                                sysname={item.sysname}
                                                rowClassName={(firstFourRowsClass != null) ? firstFourRowsClass : ""}
                                            />
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td>
                                            <h1 className="loader">
                                                {t("LOADING")}
                                            </h1>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <Link onClick={this.scrolling.bind(this)} to={BROKERS_ROUTE_MAKER(language)}>
                            {t("SEE_ALL_BROKERS")}
                            <span className="right_arrow"><i className="icon-right-small"/></span>
                        </Link>
                    </div>
                </div>
                <div className="right_box_block">
                    <ANALYSIS/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        brokers: store.brokers,
        language: store.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        attemptGetBrokerList: (lang, limit, orderby) =>
            dispatch(attemptGetBrokerList(lang, limit, orderby))
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(BrokerRatings)
);
