
import React from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {ADVISER_INSTRUCTION_MAKER, ADVISER_MAKER, BUY_ADVISER_MAKER} from "../configs/constants";

const advisers = [
   {'name':'Reopen_Buy','description': 'Trading from support level','price':50,'slug':'Reopen_Buy'},
   {'name':'Close_pos_stop','description':'Closing all positions and stop orders','price':30,'slug':'Close_pos_stop'},
   {'name':'MACD_close','description':'Closing a position on MACD indicator change','price':50,'slug':'MACD_close'},
   {'name':'Reopen_Sell','description':'Trading from resistance level','price':50,'slug':'Reopen_Sell'},
   {'name':'Del_pos_order','description':'Closing positions and orders based on volume and instrument filters','price':30,'slug':'Del_pos_order'},
   {'name':'Ma_cross','description':'Trading on the intersection of two moving averages','price':60,'slug':'Ma_cross'},
];

class Advisers extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>{`Advisors ForexGrad | ForexGrad`}</title> todo
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    <div className="row">
                        <div className="col-12">
                            <h1>Expert Advisors for the MetaTrader 4 trading platform</h1>
                            <div className="txt_wrap about_us_cont">
                                <p className="mb20">All investors who trade on the MetaTrader 4 platform can purchase Expert Advisors for automated trading.</p>
                                <p className="mb20">Here you can purchase Expert Advisors for the MetaTrader 4 platform, which will improve your trading results in the financial markets through fully automated trading, as well as simple service actions with orders and positions.</p>
                                <p>Here are two types of advisors - trading and service advisors:</p>
                                <ul className="ul-tick mb-30">
                                    <li>1.	Trading advisors implement trading strategies to work on relatively short time periods. These Expert Advisors only help you to implement your trading strategy and only you choose when to start and stop this program trading.</li>
                                    <li>2.	Service advisors implement a set of simple one-time actions with arrays of orders and positions - closing positions and orders, placing orders, etc. Expert Advisors of this type are tied to the trading instrument you have chosen, and after completing the specified actions, this Expert Advisor must, as a rule, be unlinked from this instrument.</li>
                                </ul>
                                <p className="mb-60">You will be provided with pre-compiled EA programs (no open source code) that you can test against historical data before using them on your live or demo account. The results of our own testing of trading advisors are also displayed for your convenience.</p>
                                <table className="mb-60">
                                    <thead className="">
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Currency Pairs</th>
                                        <th>Price (USD)</th>
                                        <th>Payment</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {advisers.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <Link
                                                        to={ADVISER_MAKER('en',item.slug)}>
                                                        {item.name}
                                                    </Link>
                                                </td>
                                                <td>{item.description}</td>
                                                <td>All</td>
                                                <td><b>{item.price}</b></td>
                                                <td>
                                                    <a
                                                        className="btn btn_sm button_grayish_blue"
                                                        href={BUY_ADVISER_MAKER("en",item.slug)}
                                                        rel="noopener noreferrer"
                                                    >
                                                        Pay
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                <p>After payment, you will receive an "Expert Advisor" at your e-mail address within 1 business day.</p>
                                <p>Instructions for setting up and launching the "Expert Advisor" are in your trading terminal, which you can see on the   <Link
                                    to={ADVISER_INSTRUCTION_MAKER('en')}>
                                    Instructions page.
                                </Link>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Advisers ;