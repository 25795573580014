import React from "react";
import ReactStars from "react-stars";
import { BROKER_DETAILS_PAGE_CREATOR } from "../configs/constants";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";

class BrokerRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listWrap: "none",
      arrow: "down"
    };
  }

  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const props = this.props;
    const { t } = this.props;

    return [
      <tr className={`brokersList ${props.rowClassName}`}>
        <td>
          <div className="broker_order">
            {props.id + 1}
          </div>
        </td>
        <td>
          <div className="number_img">
            <Link
              to={BROKER_DETAILS_PAGE_CREATOR(props.language,props.sysname)}
              onClick={this.scrolling.bind(this)}
            >
              <div className={`brokerImage ${props.logo}`}>
                <img className="top_brokers_logo" src={props.logo} alt={props.name} title={props.name} width="100%" height="50px"/>
              </div>
            </Link>
          </div>
        </td>
        <td>
          <Link
            to={BROKER_DETAILS_PAGE_CREATOR(props.language,props.sysname)}
            onClick={this.scrolling.bind(this)}
          >
            {props.name}
          </Link>
        </td>
        <td>
          <div className="block_rating">
            <ReactStars
              count={5}
              value={parseInt(props.rate)}
              size={20}
              edit={false}
              half={true}
              color1={"#cfcfcf"}
              color2={"#ffcc36"}
              rating={props.rate / 2}
            />
            <span>{props.rate}</span>
          </div>
        </td>
        <td>{props.minSpread}</td>
        <td>${props.minDeposit}</td>
        <td>{props.founded}</td>
        <td>
          <a
            className="btn btn_sm button_grayish_blue"
            href={props.btnText}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("START_TRADING_1")}
          </a>
        </td>
      </tr>,

      <tr className="broker_list_mobile">
        <td className="d-block p-0">
          <table className="m-0">
            <tbody>
            <tr className="border-0">
              <td>
                <div className="broker_list_top">
                  <div className="number_img">
                    <Link
                        to={BROKER_DETAILS_PAGE_CREATOR(props.language,props.sysname)}
                        onClick={this.scrolling.bind(this)}
                    >
                      <div className={`brokerImage ${props.logo}`}>
                        <img className="top_brokers_logo" src={props.logo} alt={props.name}  title={props.name} />
                      </div>
                    </Link>
                  </div>
                  <div className="block_rating">
                    <ReactStars
                        count={5}
                        value={parseInt(props.rate)}
                        size={20}
                        edit={false}
                        half={true}
                        color1={"#cfcfcf"}
                        color2={"#ffcc36"}
                        rating={props.rate}
                    />
                    <span>{props.rate}</span>
                  </div>
                  <div className="block_rating_mobile">
                    <ReactStars
                        count={1}
                        value={props.rate / 2}
                        size={20}
                        edit={false}
                        half={true}
                        color1={"#cfcfcf"}
                        color2={"#ffcc36"}
                        rating={props.rate / 2}
                    />
                    <span>{props.rate}</span>
                  </div>
                  <div
                      className="list_button button_grayish_blue"
                      onClick={() => {
                        if (this.state.arrow === "up") {
                          this.setState({
                            arrow: "down",
                            listWrap: "none"
                          });
                        } else
                          this.setState({
                            arrow: "up",
                            listWrap: ""
                          });
                      }}
                  >
                    <div className={`arrow_${this.state.arrow}`} />
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-0">
              <td>
                <div
                    className="broker_list_wrap"
                    style={{ display: this.state.listWrap }}
                >
                  <div className="broker_list_info">
                    <div>
                      <div>{props.minSpread}</div>
                      <div className="info_min">minSpread</div>
                    </div>
                    <div>
                      <div>{props.minSpread}</div>
                      <div className="info_min">minSpread</div>
                    </div>
                    <div>
                      <div>{props.minSpread}</div>
                      <div className="info_min">minSpread</div>
                    </div>
                  </div>
                  <a
                      className="btn btn_sm button_grayish_blue"
                      href={props.btnText}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    {t("START_TRADING_1")}
                  </a>
                </div>
              </td>
            </tr>
            </tbody>

          </table>
        </td>
      </tr>
    ];
  }
}
export default translate("common")(BrokerRating);
