import React from "react";
import "./MarketMovers.css";
import {
  PERIOD_DATE_RANGE,
  PERIOD_DATE_RANGE_ROUTING,
  SELECT_CATEGORY_ARR,
  SELECT_CATEGORY_ARR_ROUTING
} from "./constants";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Rss from "./Rss";
import Rss2 from "./Rss2";
import TopGainLosersTrigger from "./TopGainLosersTrigger";

const PATH = window.location.href.split("/");
const secondCaseCategory = PATH[PATH.length - 1].split("?")[0];
const secondCaseRange =
  PATH[PATH.length - 1] &&
  PATH[PATH.length - 1].includes("?") &&
  PATH[PATH.length - 1].split("?")[1].split("=")[1];
const currentCategory =
  SELECT_CATEGORY_ARR[SELECT_CATEGORY_ARR_ROUTING.indexOf(secondCaseCategory)];
const currentRange =
  PERIOD_DATE_RANGE[PERIOD_DATE_RANGE_ROUTING.indexOf(secondCaseRange)];

class MarketMoversSelects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: currentCategory || "Forex",
      periodDateRange: currentRange || "Today",
      startDate: new Date(),
      endDate: null
    };
  }

  componentDidMount() {
    this.initialRange();
  }

  handleChangeStartDate = date => {
    this.setState({
      startDate: date,
      periodDateRange: "Custom Range"
    });
  };
  handleChangeEndDate = date => {
    this.setState({
      endDate: date,
      periodDateRange: "Custom Range"
    });
  };
  handleChangeRange = e => {
    this.setState({ periodDateRange: e.target.value }, () =>
      this.initialRange()
    );
  };
  initialRange = () => {
    const date = new Date();
    const now = date.getTime();
    switch (this.state.periodDateRange) {
      case "Today":
        this.setState({ startDate: new Date(), endDate: null });
        break;
      case "Yesterday":
        const last1 = now - 1 * 24 * 60 * 60 * 1000;
        const day1 = new Date(last1);
        this.setState({ startDate: day1, endDate: day1 });
        break;
      case "Within 7 days":
        const last7 = now - 7 * 24 * 60 * 60 * 1000;
        const day7 = new Date(last7);
        this.setState({ startDate: day7, endDate: date });
        break;
      case "Within 30 Days":
        const last30 = now - 30 * 24 * 60 * 60 * 1000;
        const day30 = new Date(last30);
        this.setState({ startDate: day30, endDate: date });
        break;
      case "Within 90 Days":
        const last90 = now - 90 * 24 * 60 * 60 * 1000;
        const day90 = new Date(last90);
        this.setState({ startDate: day90, endDate: date });
        break;
      case "Within 365 Days":
        const last365 = now - 365 * 24 * 60 * 60 * 1000;
        const day365 = new Date(last365);
        this.setState({ startDate: day365, endDate: date });
        break;
      case "From the Start of a Week":
        const first = date.getDate() - date.getDay();
        const weekFirstday = new Date(date.setDate(first));
        this.setState({ startDate: weekFirstday, endDate: date });
        break;
      case "From the Start of a Month":
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        this.setState({ startDate: firstDay, endDate: date });
        break;
      case "From the Start of a Quarter":
        const quarter = Math.floor(date.getMonth() / 3);
        const firstDate = new Date(date.getFullYear(), quarter * 3, 1);
        this.setState({ startDate: firstDate, endDate: date });
        break;
      case "From the Start of a Year":
        const fusirstDayYear = new Date(date.getFullYear(), 0, 1);
        this.setState({ startDate: fusirstDayYear, endDate: date });
        break;
      case "Custom Range":
        this.setState({ endDate: date });
        break;

      default:
        break;
    }
  };

  render() {
    const { selectedCategory, periodDateRange, endDate } = this.state;
    const category =
      selectedCategory === "Forex"
        ? 1
        : selectedCategory === "Index CFDs"
        ? 2
        : selectedCategory === "Commodity Futures"
        ? 3
        : selectedCategory === "Stocks CFDs"
        ? 4
        : selectedCategory === "Precious Metal"
        ? 5
        : selectedCategory === "Gold Instruments"
        ? 6
        : selectedCategory === "CFDs on ETF"
        ? 7
        : selectedCategory === "Personal Instruments"
        ? 8
        : 9;

    const from = Date.parse(endDate) / 1000;

    return (
      <div>
        <div className="container MarketMoversSelects">
          <div className="row custom-row">
            <div className="col">
              <div>
                <label htmlFor="selectedCategory">Select Categories</label>
                <div className="select">
                  <select
                    name=""
                    id="selectedCategory"
                    onChange={e =>
                      this.setState({ selectedCategory: e.target.value })
                    }
                    value={selectedCategory}
                  >
                    {SELECT_CATEGORY_ARR.map(item => {
                      return (
                        <option selected value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col">
              <div>
                <label htmlFor="Range">Select Period or Date Range</label>
                <select
                  name=""
                  id="Range"
                  onChange={this.handleChangeRange}
                  value={periodDateRange}
                >
                  {PERIOD_DATE_RANGE.map(item => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col">
              <div className="DatePicker">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleChangeStartDate}
                />
              </div>
            </div>
            <div className="col">
              <div className="DatePicker">
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleChangeEndDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container stockscfds" style={{ padding: "0 15px" }}>
          {selectedCategory === "Stocks CFDs" ? (
            <div className={"row"}>
              <Rss />
              <Rss2 />
            </div>
          ) : null}
        </div>

        <TopGainLosersTrigger
          category={category}
          range={from}
          periodDate={this.state.periodDateRange}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
      </div>
    );
  }
}

export default MarketMoversSelects;
