import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import Videos from "../../containers/videoOvervie/Videos";
import {getMarketOverviewList} from "../../redux/actions/indexActions";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import Modal from "../rss/Modal";
import Pagination from "react-js-pagination";
import RightBanner from "../../components/RightBanner";
import Analytics from "./../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";

// overwrite style
const modalStyle = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0,0.5)"
    }
};

const scrolling = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

// ifcm-top-trades
// process.env.REACT_APP_API_URL + `/rss/${this.props.currentPage}?lang=${this.props.language}`

const DATA = {
    "gurutrade-news-finance": "GURUTRADE",
    ifc: "ifc",
    "nbc-device": "cnbc",
    "myfxbook-forex-community": "myfxbook",
    "instaforex-news": "instaforex"
};

// import EducationSections from "./MarketOverviewDetail";

const renderHtml = props => <div dangerouslySetInnerHTML={{__html: props}}/>;

class TechnicalAnalysisRss extends React.Component {
    constructor(props) {
        super(props);
        const path = this.props.history.location.pathname.split("/")[2] || "ifc";
        this.state = {
            rssItems: null,
            brokerName:
                DATA[this.props.history.location.pathname.split("/")[2]] || "ifc",
            news: "gurutrade-news-finance",
            brokerData: {value: path, label: DATA[path]},
            show: false,
            imageUrl: "",
            isModalOpen: false,
            isInnerModalOpen: false,

            itemsCount: 0,
            activePage: 1,
            page: 1
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    // close modal (set isModalOpen, true)
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    // open modal (set isModalOpen, false)
    openModal = () => {
        this.setState({
            isModalOpen: true
        });
    };

    getDate = arg => {
        const date = new Date(arg);
        let month = null;
        if (date.getMonth() + 1 < 10) {
            month = "0" + (date.getMonth() + 1);
        } else month = date.getMonth() + 1;
        let day = null;
        if (date.getDate() + 1 < 10) {
            day = "0" + (date.getDate() + 1);
        } else day = date.getDate() + 1;

        return date.getFullYear() + "-" + month + "-" + day;
    };

    handleChangeBroker(name, news) {
        this.setState({brokerName: name, news});
    }

    attemptGetData(lang, activePage) {
        fetch(
            process.env.REACT_APP_API_URL + `/tech-analys/list?lang=${lang}&countPerPage=5&page=${activePage}`,
            {
                method: "get",
                headers: {"Content-Type": "application/x-www-form-urlencoded"}
            }
        )
            .then(res => res.json())
            .then(data => {
                this.setState({rssItems: data.list, itemsCount: data.count}, () => {
                    const images = Array.from(document.querySelectorAll("img"));
                    images.forEach(image => {
                        image.addEventListener("click", () => {
                            // alert(image.src)

                            this.setState({imageUrl: image.src}, () => this.openModal());
                        });
                    });
                });
            })
            .catch();
    }

    componentDidMount() {
        this.attemptGetData(this.props.language, this.state.activePage);
    }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        // this.attemptGetData(this.props.language, this.state.activePage);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.language !== prevProps.language ||
            this.state.activePage !== prevState.activePage
        ) {
            scrolling();
            this.attemptGetData(this.props.language, this.state.activePage);
        }
    }

    render() {
        const {t,language} = this.props;
        const {rssItems} = this.state;

        return (
            <div>
                <Helmet>
                    <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                    <link rel='canonical' href={'https://www.forexgrad.com/' + language + '/analytics'}/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    <div className="main_with_left">
                        <h1>
                            {t("TECHNICAL_ANALYTICS")}
                        </h1>
                        <div className="bg_wrap">
                            {rssItems ? (
                                rssItems.map((item, index) => {

                                    return (
                                        <div key={index} className={`${Analytics.blockItem}`}>
                                            <a
                                                className={`${Analytics.title}`}
                                                target={"_blank"}
                                                rel="noopener noreferrer"
                                                href={item.link}
                                            >
                                                {item.title}
                                            </a>
                                            <div className={`${Analytics.textBlock} ${Analytics.TechAnalysisImg} mt-2`}>
                                                {renderHtml(item.short_text)}
                                            </div>
                                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                                <div className="date_block">{item.date}</div>
                                                <div className="text-justify">
                                                    {t("SOURCE_BY")}: <a href={item.url} target={'_blank'}>{item.sysname}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <h1 className="loader">
                                    {t("LOADING")}
                                </h1>
                            )}
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={5}
                                totalItemsCount={this.state.itemsCount}
                                pageRangeDisplayed={5}
                                onChange={e => this.handlePageChange(e)}
                            />
                        </div>
                    </div>
                    <div className={Global.right_box_block}>
                        <div className="expel_class">
                            <Videos/>
                        </div>
                        <div className="expel_class">
                            <TopBrokers/>
                        </div>
                        <RightBanner/>
                    </div>
                </div>
                <main>
                    <Modal
                        isModalOpen={this.state.isModalOpen}
                        closeModal={this.closeModal}
                        style={modalStyle}
                    >
                        <img
                            width="100%"
                            style={{borderRadius: 3, height: 500, width: "auto"}}
                            src={this.state.imageUrl}
                            alt="unsplash"
                        />
                    </Modal>
                </main>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        language: store.language,
        marketOverviewList: store.marketOverviewList
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getMarketOverviewList: lang => dispatch(getMarketOverviewList(lang))
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(TechnicalAnalysisRss))
);
