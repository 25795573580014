import React from "react";

import "../.././assets/css/components/education/forex_trading_books.css";
import TopBrokers from "../.././containers/TopBrokers";
import EducationSteps from "../../components/content/EducationSteps";
import EducationSections from "../../containers/education/EducationSections";
import Modal from "react-responsive-modal";

const FrTrBook = [
  {
    btnText: "Download",
    bookTitle: "Common Trading Mistakes",
    shortText:
      "Trying trading for a few weeks and coming to failure traders say that it is impossible to win in it. But the matter is that traders tend to make the same mistakes over and ...",
    author: "Author",
    authorName: "Edgar Darbinyan",
    format: "Format",
    type: "PDF",
    pages: "Pages",
    pagesCount: 2,
    lang: "Language",
    langType: "English"
  },
  {
    btnText: "Download",
    bookTitle: "Common Trading Mistakes",
    shortText:
      "Trying trading for a few weeks and coming to failure traders say that it is impossible to win in it. But the matter is that traders tend to make the same mistakes over and ...",
    author: "Author",
    authorName: "Edgar Darbinyan",
    format: "Format",
    type: "PDF",
    pages: "Pages",
    pagesCount: 2,
    lang: "Language",
    langType: "English"
  }
];

const modalStyle = {
  h3: {
    textAlign: "center",
    marginBottom: "25px"
  },
  info: {
    textAlign: "center",
    marginBottom: "30px",
    lineHeight: "24px",
    fontWeight: "normal"
  },
  input: {
    display: "block",
    margin: "0 auto 20px",
    height: "40px",
    maxWidth: "400px",
    width: "100%",
    outline: "none",
    padding: "12px 15px",
    border: "1px solid #ccc",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: "normal"
  },
  button: {
    minWidth: "200px",
    display: "block",
    margin: "0 auto"
  }
};

export default class ForexTradingBooks extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <EducationSteps />
        <div className="container mb-4 d-flex flex-wrap">
          <div className="main_with_left cfd_books_cont">
            <h2>Forex Trading Books - Learn to Trade Forex</h2>
            <div className="bg_wrap">
              {FrTrBook.map((item, index) => {
                return (
                  <div className="trading_books" key={index}>
                    <div className="books_blk_title">{item.bookTitle}</div>
                    <p>{item.shortText}</p>
                    <div className="books_blk_footer">
                      <div className="books_left_part regarding_block">
                        <div className="author_regarding">
                          <div className="img_author" />
                          <div className="block_author">
                            <div>{item.author}</div>
                            <div>{item.authorName}</div>
                          </div>
                        </div>
                        <div className="book_regarding">
                          <div>{item.format}</div>
                          <div>{item.type}</div>
                        </div>
                        <div className="book_regarding">
                          <div>{item.pages}</div>
                          <div>{item.pagesCount}</div>
                        </div>
                        <div className="book_regarding">
                          <div>{item.lang}</div>
                          <div>{item.langType}</div>
                        </div>
                      </div>
                      <button
                        className="btn btn_sm button_green"
                        onClick={this.onOpenModal}
                      >
                        {item.btnText}
                      </button>
                      <Modal open={open} onClose={this.onCloseModal}>
                        <h3 style={modalStyle.h3}>Common Trading Mistakes</h3>
                        <div style={modalStyle.info}>
                          Trying trading for a few weeks and coming to failure
                          traders say that it is impossible to win in it. But
                          the matter is that traders tend to make the same
                          mistakes over and ...
                        </div>
                        <input
                          type="text"
                          id="client_email"
                          name="email"
                          placeholder="Enter your email address"
                          style={modalStyle.input}
                        />
                        <button
                          className="btn btn_md button_green"
                          style={modalStyle.button}
                        >
                          {item.btnText}
                        </button>
                      </Modal>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="right_box_block">
            <div className="expel_class">
              <EducationSections />
            </div>
            <div className="expel_class">
              <TopBrokers />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
