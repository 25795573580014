import React from "react";
import classes from "./../assets/css/components/TopBroker.module.css";
import {Link} from "react-router-dom";
import {
    BROKER_DETAILS_PAGE_CREATOR, BROKERS_ROUTE_MAKER
} from "../configs/constants";
import ReactStars from "react-stars";
import {
    attemptGetBrokerList,
    onClearBrokerInfo
} from "../redux/actions/indexActions";
import {translate} from "react-i18next";
import {connect} from "react-redux";

class TopBrokers extends React.Component {
    componentDidMount() {
        this.props.attemptGetBrokerList(
            this.props.language,
            5,
            1,
            "desc",
            "rating"
        );
    }

    render() {
        const {t,language} = this.props;

    return (
      <div className="right_box">
        <h2>
          {/*Top 5 Brokers***/}
          {t("TOP_5_BROKERS")}
        </h2>
        <div className={classes.top_broker}>
          {this.props.brokers ? (
            this.props.brokers.length ? (
              this.props.brokers.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={classes.left_part_broker}>
                      <Link to={BROKER_DETAILS_PAGE_CREATOR(language,item.sysname)}>
                        <div>
                          <img
                            className={classes.top_brokers_logo}
                            src={item.logo}
                            alt={item.name}
                            title={item.name}
                          />
                        </div>
                      </Link>
                      {/*</div>*/}
                      <div className={classes.rating}>
                        <ReactStars
                          count={1}
                          value={1}
                          size={20}
                          edit={false}
                          half={true}
                          color1={"#cfcfcf"}
                          color2={"#ffcc36"}
                        />
                      </div>
                      {/*<div>{item.averageRate.toFixed(1)}</div>*/}
                      <div>{item.averageRate.toFixed(1)}</div>
                    </div>
                    <a
                      className="btn btn_sm button_grayish_blue"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.externalUrl}
                    >
                      {t("START_TRADING_1")}
                    </a>
                  </div>
                );
              })
            ) : (
              <h3>
                {/*NO DATA FOR SHOW***/}
                {t("NO_DATA_FOR_SHOW")}
              </h3>
            )
          ) : (
            <h1 className="loader">
              {/*loading***/}
              {t("LOADING")}
            </h1>
          )}
          <Link className="see_all" to={BROKERS_ROUTE_MAKER(language)}>
            {t("SEE_ALL_BROKERS")}
            {/*See all brokers{" "}*/}
            <span className={classes.right_arrow}>
              <i className="icon-right-small"></i>
            </span>
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        brokers: store.brokers,
        language: store.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        onClearBrokerInfo: () => dispatch(onClearBrokerInfo()),
        attemptGetBrokerList: (lang, countPerPage, page, orderby, sortBy) =>
            dispatch(attemptGetBrokerList(lang, countPerPage, page, orderby, sortBy))
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(TopBrokers)
);
