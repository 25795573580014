import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import Videos from "../../containers/videoOvervie/Videos";
import {getTechnicalSummariesList} from "../../redux/actions/indexActions";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Pagination from "react-js-pagination";
import Modal from "../rss/Modal";
import RightBanner from "../../components/RightBanner";

// overwrite style
const modalStyle = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0,0.5)"
    }
};

const scrolling = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

const DATA = {
    "gurutrade-news-finance": "GURUTRADE",
    ifc: "ifc",
    "nbc-device": "cnbc",
    "myfxbook-forex-community": "myfxbook",
    "instaforex-news": "instaforex"
};

class TechnicalSummaries extends React.Component {
    constructor(props) {
        super(props);
        const path = this.props.history.location.pathname.split("/")[2] || "ifc";
        this.state = {
            rssItems: null,
            brokerName: DATA[this.props.history.location.pathname.split("/")[2]] || "ifc",
            news: "gurutrade-news-finance",
            brokerData: {value: path, label: DATA[path]},
            show: false,
            imageUrl: "",
            isModalOpen: false,
            isInnerModalOpen: false,
            itemsCount: 0,
            activePage: 1,
            page: 1
        };
    }

    getDate = arg => {
        const date = new Date(arg);
        let month = null;
        if (date.getMonth() + 1 < 10) {
            month = "0" + (date.getMonth() + 1);
        } else month = date.getMonth() + 1;
        let day = null;
        if (date.getDate() + 1 < 10) {
            day = "0" + (date.getDate() + 1);
        } else day = date.getDate() + 1;

        return date.getFullYear() + "-" + month + "-" + day;
    };

    attemptGetData(lang, countPerPage, activePage) {
        fetch(
            process.env.REACT_APP_API_URL + `/technical-summaries?lang=${lang}&countPerPage=${countPerPage}&page=${activePage}`,
            {
                method: "get",
                headers: {"Content-Type": "application/x-www-form-urlencoded"}
            }
        )
            .then(res => res.json())
            .then(data => {
                this.setState({rssItems: data.list, itemsCount: data.count}, () => {
                    const images = Array.from(document.querySelectorAll("img"));
                    images.forEach(image => {
                        image.addEventListener("click", () => {
                            this.setState(
                                {imageUrl: image.src}
                            );
                        });
                    });
                });
            })
            .catch(err => console.log(err));
    }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
    }

    componentDidMount() {
        this.attemptGetData(this.props.language, 5, this.state.activePage);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.language !== prevProps.language ||
            this.state.activePage !== prevState.activePage
        ) {
            this.attemptGetData(this.props.language, 5, this.state.activePage);
            scrolling();
        }
    }

    render() {
        const {t} = this.props;
        const {rssItems} = this.state;

        return (
            <div>
                <Helmet>
                    <title>{`Technical Summary`}</title>
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    <div className="video_less_wrap main_with_left">
                        <h1>
                            {t("TECHNICAL_SUMMARIES")}
                        </h1>
                        <div className="bg_wrap">
                            {rssItems ? (
                                rssItems.map((item, index) => {
                                    return (
                                        <div key={index} className={`${Analytics.blockItem}`}>
                                            <a
                                                className={`${Analytics.title}`}
                                                target={"_blank"}
                                                rel="noopener noreferrer"
                                                href={item.url}
                                            >
                                                Technical Summary | {userFriendlyDateTimeFormat(item.created_at)}
                                            </a>
                                            <div
                                                className="mt-2 mb-3 d-flex justify-content-between align-items-center">
                                                <div
                                                    className="date_block">{userFriendlyDateTimeFormat(item.created_at)}</div>
                                                <div>
                                                    {t("SOURCE_BY")}: <a href={item.url} target="_blank" rel="noopener noreferrer">{item.system_name}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <h1 className="loader">
                                    {t("LOADING")}
                                </h1>
                            )}
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={5}
                                totalItemsCount={this.state.itemsCount}
                                pageRangeDisplayed={5}
                                onChange={e => this.handlePageChange(e)}
                            />
                        </div>
                    </div>
                    <div className="right_box_block">
                        <div className="expel_class">
                            <Videos/>
                        </div>
                        <div className="expel_class">
                            <TopBrokers/>
                        </div>
                        <RightBanner/>
                    </div>
                </div>
                <main>
                    <Modal
                        isModalOpen={this.state.isModalOpen}
                        closeModal={this.closeModal}
                        style={modalStyle}
                    >
                        <img
                            width="100%"
                            style={{borderRadius: 3, height: 500, width: "auto"}}
                            src={this.state.imageUrl}
                            alt="unsplash"
                        />
                    </Modal>
                </main>
            </div>
        );
    }
}

/**
 *
 * @param date
 * @returns {string}
 */
function userFriendlyDateTimeFormat(date) {
    let newDate = new Date(date);

    let dateString =
        newDate.getFullYear()
        + '-' +
        ('0' + (newDate.getMonth() + 1)).slice(-2)
        + '-' +
        ('0' + newDate.getDate()).slice(-2)
    ;

    return dateString;
}

const mapStateToProps = store => {
    return {
        language: store.language,
        technicalSummaryList: store.technicalSummaryList
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getTechnicalSummariesList: lang => dispatch(getTechnicalSummariesList(lang))
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(TechnicalSummaries))
);
