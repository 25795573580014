import React from "react";
import { translate } from "react-i18next";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class PerfectBroker extends React.Component {
  render() {
    const { t, search } = this.props;

    return (
      <>
        {search ? (
          renderHtml(t("CHOOSING_BROKER_MT4"))
        ) : (
          <div>
            <p>{t("CHOOSING_PERFECT_BROKER_FIRST")}</p>
            <br />
            <p>{t("CHOOSING_PERFECT_BROKER_SECOND")}</p>
          </div>
        )}
      </>
    );
  }
}
export default translate("common")(PerfectBroker);
