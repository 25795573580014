import React from "react";
import {translate} from "react-i18next";
import Global from "../assets/css/Global.module.css";
const renderHtml = props => (
    <span dangerouslySetInnerHTML={{ __html: props }} />
);

class BrokerQuestions extends React.Component {
    render() {
        const { data } = this.props;

        return(<div>
            <div className="d-flex flex-wrap" style={{marginBottom: '20px'}}>
                <div className="container mb-4 d-flex flex-wrap">
                    <div className="questions_wrap main_with_left">
                        <h2>FAQ</h2>
                        <div className={`d-flex ${Global.bg_wrap}`}>
                            <div {...{className: 'wrapper w-100'}}>
                                <ul {...{className: 'accordion-list'}}>
                                    {data ? data.map((item, key) => {
                                        return (
                                            <li {...{className: 'accordion-list__item', key}}>
                                                <AccordionItem {... item} />
                                            </li>
                                        )
                                    }) : "" }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

}
class AccordionItem extends React.Component {
    state = {
        opened: false
    }

    render() {
        const {
            state: {
                opened
            }
        } = this;
        return (
            <div
                {...{
                    className: `accordion-item, ${opened && 'accordion-item--opened'}`,
                    onClick: () => {
                        this.setState({opened: !opened})
                    }
                }}
            >
                <div {...{className: 'accordion-item__line'}}>
                    <div className="accordion-item__title">
                        <div className="author-block">
                            <h3>{this.props.title}</h3>
                        </div>
                    </div>

                    <span {...{className: 'acc-arrow'}}/>
                </div>
                <div {...{className: 'accordion-item__inner'}}>
                    <div {...{className: 'accordion-item__content'}}>
                        <p {...{className: 'accordion-item__paragraph'}}>
                            {renderHtml(this.props.content)}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default translate("common")(BrokerQuestions);