import React from "react";
import "./../assets/css/components/about_us/about_us.css";
import Global from "./../assets/css/Global.module.css";
import AllVideos from "../containers/videoOvervie/Videos";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import ExpertCorner from "../containers/articles/ExpertCorner";

const renderHtml = props => (
    <div
        className="about_us_content"
        dangerouslySetInnerHTML={{__html: props}}
    ></div>
);

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staticPageData: ""
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.language !== prevProps.language) {

            fetch(`https://www.ifcm.co.uk:2083/page/about-us?lang=${this.props.language}`).then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        this.setState({staticPageData: data});
                    });
                }
            }).catch();
        }
    }

    componentDidMount() {
        fetch(
            `https://www.ifcm.co.uk:2083/page/about-us?lang=${this.props.language}`,
            {
                method: "get",
                headers: {"Content-Type": "application/x-www-form-urlencoded"}
            }
        )
            .then(res => res.json())
            .then(data => {
                this.setState({staticPageData: data});
            })
            .catch(err => console.log(err));
        if (this.props.marketOverview) {
            this.setState({marketOverview: this.props.marketOverview});
        }
    }

    render() {
        const { t } = this.props;
        // console.log("about us",this.state.staticPageData.content)
        return (
            <div>
                <Helmet>
                    <title>{`About ForexGrad | ForexGrad`}</title> todo
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    {/*{renderHtml(this.state.staticPageData.content)}*/}
                    <div className="main_with_left about_us_wrap">
                        <h1>{t("ABOUT_US")}</h1>
                        <div className="txt_wrap about_us_cont">
                            {renderHtml(t("THE_BEST_REQUIREMENTS"))}
                        </div>
                    </div>

                    <div className="right_box_block">
                        <div className="expel_class">
                            <AllVideos/>
                        </div>
                        <div className={Global.expel_class}>
                            <ExpertCorner/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        language: store.language
    };
};

export default translate("common")(connect(mapStateToProps)(AboutUs));
