import React from "react";
import EconomicCalendar from "../../containers/EconomicCalendar";
import GainersLosers from "../../containers/GainersLosers";

export default class News extends React.Component {
    // componentDidMount() {
    //     document.querySelector('.main_half_cont').style.width = window.innerWidth + 'px';
    // }
    render() {
    return (
      <div className="container mb-4 main_half_cont d-flex flex-wrap">
        <EconomicCalendar width={"widthcalendar"} heigth={"heigth"} />
        <GainersLosers />
      </div>
    );
  }
}
