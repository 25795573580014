import React from "react";
import {
  BROKERS_CHARTS_CUR_CREATOR
} from "../../configs/constants";
import "../../assets/css/components/charts/live_charts.css";
import TopBrokers from "../../containers/TopBrokers";
import Analysis from "../../containers/Analysis";
import RightBoxBlock from "./RightBoxBlock";
import ChartTable from "./ChartTable";
import CurrencyPairs from "./CurrencyPairs";
import PriceHistory from "./PriceHistory";
import Select from "react-select";
import ChartCFD from "./chart2/ChartCFD";
import {
  changeInstrGroup,
  changeInstrName,
  getGroupInstruments
} from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#404040"
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};

const initialInstrGroup = [
  { value: "forex", label: "forex" },
  { value: "indices", label: "indices" },
  { value: "commodities", label: "commodities" },
  { value: "stocks", label: "stocks" },
  { value: "precious-metals", label: "precious-metals" },
  { value: "gold-trading", label: "gold-trading" },
  { value: "cfd-etf", label: "cfd-etf" },
  { value: "crypto", label: "crypto" },
];

class LiveChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instrGroup: { value: "forex", label: "forex" },
      selectedInstrument: [],
      countPerPage: 1,
      activePage: 1,
      changeState: true
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {type} = props.match.params;

    if(state.changeState === true && type !== undefined && state.instrGroup.value !== type && props.instrName.value !== "") {
      props.changeInstrGroup(type);
      props.getGroupInstruments(type);

    return {
      instrGroup: { value: type, label: type },
      instrumentChanged: false
      }
    }
  }

  handleInstrumentChange = instrName => {
    this.setState({ instrumentChanged: false }, () => {
      this.props.changeInstrName(instrName);
      const kaka = this.getByValue2(
        this.props.chartInstrument,
        instrName.value,
        "name"
      );

      this.props.history.push(
        BROKERS_CHARTS_CUR_CREATOR(this.props.language,this.state.instrGroup.value, kaka.slug)
      );
    });
  };

  getByValue2(arr, value, findValue) {
    return arr.find(obj => obj[findValue] === value) || { name: "" };
  }

  handleInstrGroupChange = instrGroup => {
    this.setState({instrGroup, instrumentChanged: true, changeState: false }, () => {
      this.props.changeInstrGroup(instrGroup.value);
      this.props.getGroupInstruments(instrGroup.value);
      this.props.changeInstrName({ value: "", label: "select" });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.instrGroup.value !== prevState.instrGroup.value) {
      this.props.changeInstrName({ value: null, label: "Select..." });
    }
  }

  componentDidMount() {
    if (this.props.match.params.type) {
      this.setState({
        instrGroup: {
          value: `${this.props.match.params.type}`,
          label: `${this.props.match.params.type}`
        }
      });
    }
    this.props.getGroupInstruments(
      `${
        this.props.match.params.type
          ? `${this.props.match.params.type}`
          : "forex"
      }`
    );
  }

  componentWillUnmount() {
    this.props.changeInstrName({ value: "AUDUSD", label: "AUDUSD" });
  }
  getHeaders(instrGroup) {
    const { t } = this.props;
    let headers = {}
    if(this.props.match.params.cur === undefined){
      headers = this.getByValue2(this.props.chartInstrument, this.props.instrName.value, 'name');
    }else{
      headers = this.getByValue2(this.props.chartInstrument, this.props.match.params.cur, 'slug');
    }
    let header1 = "Forex currencies live charts";
    let header2 = '';
    if(headers.alternate_name !== undefined || headers.slug !== undefined) {
      switch (instrGroup.value) {
        case 'indices' :{
          header1 = `${headers.alternate_name}  ${t('$_PRICE_TODAY')}`;
          header2 = `${headers.alternate_name}  ${t('$_CHART')}`;
          break;
        }
        case 'commodities' :{
          header1 = `${headers.alternate_name}  ${t('$_PRICE_HISTORY_lIVE_RATES')}`;
          header2 = `${headers.alternate_name}  ${t('$_PRICE_CHART')}`;
          break;
        }
        case 'stocks' :{
          header1 = `${headers.alternate_name}  ${t('$_STOCK_PRICE_HISTORY')} - ${headers.slug}`;
          header2 = `${headers.alternate_name}  ${t('$_CHART')}`;
          break;
        }
        case 'precious-metals':
        case'cfd-etf' :{
          header1 = `${headers.alternate_name}  ${t('$_PRICE_TODAY')}`;
          header2 = `${headers.alternate_name}  ${t('$_LIVE_CHART')}`;
            break;
          }
        case 'gold-trading' :{
          header1 = `${headers.alternate_name}  ${t('$_PRICE')} - ${headers.slug}`;
          header2 = `${headers.alternate_name}  ${t('$_CHART')} - ${headers.slug}`;
          break;
        }

        //forex
        default: {
          header1 = `${headers.alternate_name}  ${t('$_EXCHANGE_RATE_LIVE_QUOTES')}`;
          header2 = `${headers.alternate_name}  ${t('$_LIVE_CHART')}`;
          break;
        }
      }
    }
      return {header1: header1, header2: header2};
  }

  getInstrumentValue() {
    const {
      match: { params },
      instrName,
      language,
      chartInstrument
    } = this.props;
    const { instrumentChanged } = this.state;
    if (instrumentChanged) {
      return {
        value: null,
        label: `Select...`
      };
    }

    if (!params.type || !params.cur) {
      return instrName;
    }
    return {
      value: `${
        this.getByValue2(chartInstrument, this.props.match.params.cur, "slug")
          .name
      }`,
      label: `${
        this.getByValue2(chartInstrument, this.props.match.params.cur, "slug")
          .name
      }`
    };
  }

  render() {
    const { chartInstrument, instrName, t } = this.props;
     const { instrGroup } = this.state;
     this.state.changeState = true;
    const instruments = chartInstrument.map(item => ({
      value: `${item.name}`,
      label: `${
        item.alternate_name
          ? `${`${item.name} ${item.alternate_name}`}`
          : `${item.name}`
      }`
    }));

    return (
      <div>
        <div className="container mb-4 d-flex flex-wrap chart_div">
          <h1>
            {(instrGroup !== undefined) ? this.getHeaders(instrGroup).header1 : t("$_FOREX_CURRENCIES_LIVE_CHARTS")}
          </h1>
          <div className="bg_wrap d-flex flex-wrap">
            <div className="chart_wrapper_block">
              <div className="d-flex flex-wrap chart_top">
                <h2>{`${
                  this.props.match.params.cur
                    ? this.getHeaders(instrGroup).header2
                    : instrName.value + ' Live Chart'
                    ? instrName.value + ' Live Chart'
                    : "AUDUSD Live Chart"
                }`}</h2>
                <div className="category_block">
                  <div className="category_group">
                    <Select
                      value={instrGroup}
                      onChange={this.handleInstrGroupChange}
                      options={initialInstrGroup}
                      styles={customStyles}
                    />
                  </div>
                  <div className="category_group">
                    <Select
                      value={this.getInstrumentValue()}
                      onChange={this.handleInstrumentChange}
                      options={instruments}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>
              <div className="char_wrapper">
                <ChartCFD currency={this.getInstrumentValue()} />
              </div>
              <div className="d-flex flex-wrap chart_btm">
                <a
                  className="btn btn_sm button_green"
                  href={
                    "https://www.ifcmarkets.com/en/open-trading-account?utm_campaign=forexGrad-website"
                  }
                  target="_blank" rel="noopener noreferrer"
                >
                  {t("REG_BANNER_HEADER")}
                  {/*Start Trading Now*/}
                </a>
                <div>
                  {/*Sign up with recommended broker***/}
                  {t("RECOMMENDED_BROKER")}
                </div>
              </div>
            </div>
            <div className="right_box_block block_val">
              <RightBoxBlock
                currency={this.getInstrumentValue()}
                title={`${
                  this.getByValue2(
                    chartInstrument,
                    this.props.match.params.cur,
                    "slug"
                  ).name
                }`}
              />
              <ChartTable
                currency={
                  this.props.match.params.cur
                    ? {
                        value: `${this.props.match.params.cur}`,
                        label: `${this.props.match.params.cur}`
                      }
                    : instrName
                }
              />
            </div>
          </div>
        </div>
        <div className="container mb-4 d-flex flex-wrap">
          <div className="main_with_left curr_p_wrap">
            <CurrencyPairs
              currency={
                this.props.match.params.cur
                  ? {
                      value: `${this.props.match.params.cur}`,
                      label: `${this.props.match.params.cur}`
                    }
                  : instrName
              }
            />
            <PriceHistory
              cur={(this.props.match.params.cur) ?
                this.getByValue2(
                  chartInstrument,
                  this.props.match.params.cur,
                  "slug"
              ) : this.getByValue2(
                      chartInstrument,
                      'audusd',
                      "slug"
                  )}
              currency={
                this.props.match.params.cur
                  ? {
                      value: `${this.props.match.params.cur}`,
                      label: `${this.props.match.params.cur}`
                    }
                  : instrName
              }
            />
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <TopBrokers />
            </div>
            <div className="expel_class">
              <Analysis />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    language: store.language,
    instrName: store.instrName,
    instrGroup: store.instrGroup,
    chartInstrument: store.chartInstrument
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeInstrName: instrName => dispatch(changeInstrName(instrName)),
    changeInstrGroup: Group => dispatch(changeInstrGroup(Group)),
    getGroupInstruments: Group => dispatch(getGroupInstruments(Group))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(LiveChart)
);
