import React from "react";
import "./../assets/css/components/trading_platforms.css";
import "./../assets/css/components/articles.css";
import TopPromotionsBlock from "../containers/TopPromotionsBlock.js";
import TopBrokers from "../containers/TopBrokers";
import { attemptGetPlatformList } from "../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { osName } from "react-device-detect";
import { PLATFORM_DETAILS_PAGE_CREATOR } from "../configs/constants";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import RightBanner from "./RightBanner";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class TradingPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.attemptGetPlatformList(this.props.language);
    }
  }

  componentDidMount() {
    this.props.attemptGetPlatformList(this.props.language);
    this.setState({ isLoading: false });
  }

  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const { t,language} = this.props;
    return (
      <div>
        <Helmet>
          <title>{`Trading platforms | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container mb-4 d-flex flex-wrap">
          <div className="trading_plt main_with_left">
            <h1>
              {t("TRADING_PLATFORM")}
            </h1>
            <div className="bg_wrap fixed_height">
              {this.props.platform && !this.state.isLoading ? (
                this.props.platform.list.length ? (
                  this.props.platform.list.map((item, index) => {
                    let downloadUrl = null;
                    item.platformDevices.forEach(e => {
                      if (e.device_name === osName) {
                        downloadUrl = e.download;
                      }
                    });

                    return (
                      <div className="block_see_also" key={index}>
                        <div className="ninja_top d-flex flex-wrap">
                          <Link
                            onClick={this.scrolling.bind(this)}
                            to={PLATFORM_DETAILS_PAGE_CREATOR(language,item.sysname)}
                          >
                            <img src={`${item.logo}`} alt={item.name + ' Trading Platform'}  title={item.name + ' Trading Platform'}/>
                          </Link>
                          {!item.platformDevices.length ? (
                            <button
                              className={`btn btn_md button_green ${
                                !downloadUrl ? "disabled" : ""
                              }`}
                              disabled={!!downloadUrl}
                            >
                              No devices
                            </button>
                          ) : (
                            <a
                              className={`btn btn_md button_green ${
                                !downloadUrl ? "disabled" : ""
                              }`}
                              rel="nofollow"
                              href={`${downloadUrl}`}
                              disabled={!downloadUrl}
                            >
                              {/*Download {item.name}*/}
                              {`${t("DOWNLOAD")}`}
                            </a>
                          )}
                        </div>
                        {item.platformDevices.length === 0 ? (
                          <h2>No devices</h2>
                        ) : (
                          <div className="plt_option">
                            {item.platformDevices.map((e, index) => {
                              return (
                                <span key={index} className="light_orange">
                                  {e.device_name}
                                </span>
                              );
                            })}
                          </div>
                        )}

                        <div className="platform_text_cont">
                          {renderHtml(item.description)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h3>
                    {t("NO_DATA_FOR_SHOW")}
                  </h3>
                )
              ) : (
                <h1 className="loader">
                  {t("LOADING")}
                </h1>
              )}
            </div>
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <TopBrokers />
            </div>
            <div className="expel_class">
              <TopPromotionsBlock />
            </div>
            <RightBanner/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    platform: store.platform,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetPlatformList: lang => dispatch(attemptGetPlatformList(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(TradingPlatform)
);
