import React from "react";
import "./../assets/css/components/top_promotions.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { attemptGetTopPromotions } from "../redux/actions/indexActions";

class TopPromotionsBlock extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.attemptGetTopPromotions(this.props.language);
    }
  }

  componentDidMount() {
    this.props.attemptGetTopPromotions(this.props.language);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="right_box">
        <h2>
          {/*Top Promotions***/}
          {t("TOP_PROMOTIONS")}
        </h2>
        <div className="top_promotions">
          {this.props.topPromotions ? (
            <div className="promotions_block">
              {this.props.topPromotions.length ? (
                this.props.topPromotions.map((item, index) => {
                  return (
                    <div className="promotions_right_block" key={index}>
                      <span>{item.brokerName}</span>
                      <div>
                        <a href={item.link} style={{ color: "#4a4a4a" }}>
                          {item.promotion}
                        </a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h2>
                  {/*NO DATA FOR SHOW***/}
                  {t("NO_DATA_FOR_SHOW")}
                </h2>
              )}
            </div>
          ) : (
            <h1 className="loader">
              {/*loading***/}
              {t("LOADING")}
            </h1>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    topPromotions: store.topPromotions
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetTopPromotions: lang => dispatch(attemptGetTopPromotions(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(TopPromotionsBlock)
);
