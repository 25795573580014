import React from "react";
import "./../assets/css/components/news.css";
import Tnews from "../assets/css/components/TopNews.module.css";
import Global from "../assets/css/Global.module.css";
import {getLastMarketOverview} from "../redux/actions/indexActions";
import {connect} from "react-redux";
import {translate} from "react-i18next";

const renderHtml = props => (
    <div dangerouslySetInnerHTML={{__html: props}}></div>
);

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            marketOverview: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.language !== prevProps.language) {
            this.props.getLastMarketOverview(this.props.language);
        }
    }

    componentDidMount() {
        this.props.getLastMarketOverview(this.props.language);
        if (this.props.marketOverview) {
            this.setState({marketOverview: this.props.marketOverview});
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className={"main_with_left"}>
                <h5>{t("TOP_NEWS")}</h5>
                <div className={`${Global.bg_wrap} ${Tnews.news_wrap}`}>
                    {this.props.marketOverview && this.props.marketOverview.img ? (
                        <div className={Tnews.news_left_part}>
                            {/*<div className="newsimage">*/}
                            <img
                                src={this.props.marketOverview && this.props.marketOverview.img}
                                alt={"marketOverview img"}
                                width={"100%"}
                                height={"100%"}
                            />
                            {/*</div>*/}
                        </div>
                    ) : null}

                    <div className={Tnews.news_right_part}>
                        <div className={Tnews.news_content}>

                            <span className={Tnews.news_title}>
                                <a
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    href={
                                        this.props.marketOverview && this.props.marketOverview.url
                                    }
                                >
                                    {this.props.marketOverview && this.props.marketOverview.title}
                                </a>
                            </span>
                            {this.props.marketOverview &&
                            renderHtml(this.props.marketOverview.text)}
                            <div className={`${Global.date_block} ${Tnews.date_block}`}>
                                {this.props.marketOverview && this.props.marketOverview.date}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        marketOverview: store.marketOverview,
        language: store.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getLastMarketOverview: lang => dispatch(getLastMarketOverview(lang))
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(News)
);
