import React from "react";
import {Link} from "react-router-dom";
import {
  BROKERS_ABOUT_US_MAKER,
  BROKERS_CHARTS_MAKER,
  BROKERS_ROUTE_MAKER,
  BROKERS_TRADING_PLATFORMS_MAKER,
  BROKERS_CALENDARS_MAKER,
  BROKERS_EDUCATIONS_MAKER,
  VIDEO_TRADERS_MAKER,
  BROKERS_INNOVATION_MAKER,
  ARTICLES_MAKER,
  QUESTIONS_MAKER,
  MARKET_OVERVIEW_LIST_MAKER,
  BROKERS_ANALYTICS_MAKER,
  MARKET_SENTIMENT_MAKER,
  MARKET_MOVERS_MAKER,
  MARKET_OVERVIEW_MAKER, TECHNICAL_SUMMARIES_MAKER, ADVISERS_MAKER
} from "../../configs/constants";
import { translate } from "react-i18next";
import Watch from "./Watch";
import "./custom.css";
import {connect} from "react-redux";

class MenuNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "active",
      flag_none: "none"
    };
  }

  openNav = () => {
    document.getElementById("mobMenu").style.right = "0";
    var body = document.body;
    body.classList.add("body_hover");

    document.getElementsByTagName("body")[0].addEventListener("click", e => {
      if (!e.target.classList.contains("dropbtn")) {
        document.getElementById("mobMenu").style.right = "-250px";
        var body = document.body;
        body.classList.remove("body_hover");
      }
    });
  };
  scrolling(e) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const elem = document.querySelectorAll(".toggable");
    elem.forEach(elem => {
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
      }
    });
    e.target.classList.add(this.state.activeClass);
  }
  dropdown = (e) => {
    if(window.innerWidth < 950) {
      let next = e.target.nextSibling.classList;
      let parent = e.target.parentElement.classList;
      next.toggle('active_btn');
      parent.toggle('dropdown-menu-right_close');
    }
  }

  render() {
    const { t,language } = this.props;

    return (
      <div className="menu">
        <div className="container">
          <Watch />
          <div>
            <button type="img" className="close sendsubmit">
              <i className="icon-search"></i>
            </button>
            <div id="menu_button" onClick={this.openNav.bind(this)}>
              &#9776;
            </div>
          </div>
          <div className="top_menu_wrapper">
            <ul id="mobMenu" className="menu_list">
              <li>
                <div className="closebtn">&times;</div>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_ABOUT_US_MAKER(language)}
                >
                  {t("ABOUT_US")}
                </Link>
              </li>

              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_INNOVATION_MAKER(language)}
                >
                  {t("INNOVATIONS")}
                </Link>
              </li>

              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_CHARTS_MAKER(language)}
                >
                  {t("GO_TO_CHART_HEADER")}
                </Link>
              </li>
              <li>
                <Link className="toggable" onClick={this.scrolling.bind(this)} to={BROKERS_ROUTE_MAKER(language)}>
                  {t("BROKERS")}
                </Link>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_TRADING_PLATFORMS_MAKER(language)}
                >
                  {t("TRADING_PLATFORMS")}
                </Link>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_CALENDARS_MAKER(language)}
                >
                  {t("CALENDARS")}
                </Link>
              </li>
              <li className="menu-dropdown-event">
                <div className="dropdown w-100 dropdown-menu-right">
                  <button className="dropbtn w-100 text-right" onClick={this.dropdown.bind(this)}>
                    {t("ANALYTICS")}
                  </button>
                  <div className="dropdown-content">
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={BROKERS_ANALYTICS_MAKER(language)}
                    >
                      {t("TECHNICAL_ANALYTICS")}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={MARKET_SENTIMENT_MAKER(language)}
                    >
                      {t("MARKET_SENTIMENT")}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={MARKET_OVERVIEW_MAKER(language)}
                    >
                      {t("LIST_MARKET_OVERVIEW")}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={MARKET_MOVERS_MAKER(language)}
                    >
                      {t("WIDGETS_MARKET_MOVERS")}
                    </Link>

                    <Link
                        className="toggable"
                        onClick={this.scrolling.bind(this)}
                        to={TECHNICAL_SUMMARIES_MAKER(language)}
                    >
                      {t("TECHNICAL_SUMMARIES")}
                    </Link>

                  </div>
                </div>
              </li>
              <li className="menu-dropdown-event">
                <div className="dropdown w-100 dropdown-menu-right">
                  <button className="dropbtn w-100 text-right" onClick={this.dropdown.bind(this)}>
                    {t("EDUCATION")}
                  </button>
                  <div className="dropdown-content">
                    <Link
                        className="toggable"
                        onClick={this.scrolling.bind(this)}
                        to={BROKERS_EDUCATIONS_MAKER(language)}
                    >
                      {/*Analytics*/}
                      {t("VIDEO_TUTORIALS")}
                    </Link>
                    <Link
                        className="toggable"
                        onClick={this.scrolling.bind(this)}
                        to={VIDEO_TRADERS_MAKER(language)}
                    >
                      {t("VIDEO_LESSONS")}
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={ARTICLES_MAKER(language)}
                >
                  {t("ADVISORS_LINKS")}
                </Link>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={MARKET_OVERVIEW_LIST_MAKER(language)}
                >
                  {/*News*/}
                  {t("WIDGETS_NEWS")}
                </Link>
              </li>
              <li>
                <Link
                    className="toggable"
                    onClick={this.scrolling.bind(this)}
                    to={QUESTIONS_MAKER(language)}
                >
                  {/*About Us*/}
                  {t("EXPERTS_VIEW")}
                </Link>
              </li>
              <li>
                <Link
                    className="toggable"
                    onClick={this.scrolling.bind(this)}
                    to={ADVISERS_MAKER(language)}
                >
                  Advisors
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
    state => ({
      language: state.language
    }),
)(translate("common")(MenuNav));
