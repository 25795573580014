import React from "react";
import "../../assets/css/components/brokers/broker_page.css";
import "../../assets/css/components/brokers/brokers_review.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import {
  attemptGetBrokerList,
  attemptGetBrokerPage, attemptGetBrokerQuestion,
  attemptGetLatestReviews,
  onClearBrokerInfo
} from "../../redux/actions/indexActions";
import TopBrokers from "../TopBrokers";
import ReactStars from "react-stars";
import RegisterForm from "./BrokerReview";
import TopPromotionsBlock from "../../containers/TopPromotionsBlock";
import LatestBrokerReview from "./LatestBrokerReview";
import BrokerQuestions from "../../components/BrokerQuestions";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";

const renderHtml = props => (
  <span dangerouslySetInnerHTML={{ __html: props }} />
);

class BrokerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: false,
      activeIndex: 0,
      showOptions: false,
      arrow: "",
      showInstruments: false,
      showHeadquarters: false,
      arrowInstruments: "",
      arrowHeadquarters: "",
      render: true,
      activePage: 15,
      page: 1,

    };
  }
  getAggregateRating(brokerPage) {
  let data =  {
      // schema truncated for brevity
      "@context": "https://schema.org/",
      "@type": "Review",
      "MainEntityOfPage":{
        "@type":"WebPage",
        "@id": "https://www.forexgrad.com/brokers-ratings/"+ this.props.match.params.sysname,
      },
      "headline": document.querySelector("h1").innerText,
      "dateModified": brokerPage.updated,
      "datePublished": brokerPage.created,
      "itemReviewed": {
        "@type": "Organization",
        "name": brokerPage.sysname,
        "description": brokerPage.description,
        "brand" : brokerPage.sysname,
        "image" : brokerPage.logo
      },
      "description": brokerPage.description,
      "author":
        {
          "@type": "Person",
          "name": brokerPage.authorName
        },
    "reviewRating": {
        "@type": "Rating",
        "ratingValue": brokerPage.averageRate,
        "bestRating": "5",
        "worstRating":"1"
      },	"publisher": {
        "@type": "Organization",
        "name": "ForexGrad",
        "sameAs" : "https://www.forexgrad.com"
      },"image": "https://www.ifcm.co.uk:2083/images/forexgrad.jpg"
    }
    return JSON.stringify(data);
  }
  getAggregateQuestions(brokerQuestion) {
    let FAQ_data = brokerQuestion.length !== 0 ? {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": brokerQuestion[0].title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": brokerQuestion[0].text
        }
      }, {
        "@type": "Question",
        "name": brokerQuestion[1].title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": brokerQuestion[1].text
        }
      }, {
        "@type": "Question",
        "name": brokerQuestion[2].title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": brokerQuestion[2].text
        }
      },
        {
          "@type": "Question",
          "name": brokerQuestion[3].title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": brokerQuestion[3].text
          }
        },
        {
          "@type": "Question",
          "name": brokerQuestion[4].title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": brokerQuestion[4].text
          }
        }]
    } : {}
    return JSON.stringify(FAQ_data);
  }
  addScriptToHead(brokerPage, brokerQuestion){
    if(brokerPage !== null && this.state.render && brokerQuestion !== null){
      this.state.render = false;
      let sc = document.createElement("script");
      sc.innerHTML = this.getAggregateRating(brokerPage);
      sc.setAttribute("type", "application/ld+json");
      let sc2 = document.createElement("script");
      sc2.innerHTML = this.getAggregateQuestions(brokerQuestion);
      sc2.setAttribute("type", "application/ld+json");
      document.head.appendChild(sc);
      document.head.appendChild(sc2);
    }
  }

  componentDidMount() {
    this.props.attemptGetBrokerPage(
      this.props.language,
      this.props.match.params.sysname
    );
    this.props.attemptGetBrokerList(this.props.language, 5, "average_rate");
    this.props.attemptGetBrokerQuestion( this.props.match.params.sysname, this.props.language);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.addScriptToHead(this.props.brokerPage, this.props.brokerQuestion);
    if (
      this.props.match.params.sysname !== prevProps.match.params.sysname ||
      this.props.language !== prevProps.language
    ) {
      this.props.onClearBrokerInfo();
      this.props.attemptGetBrokerPage(
        this.props.language,
        this.props.match.params.sysname
      );
      this.props.attemptGetLatestReviews(
        this.props.sysname,
        10,
        this.state.page,
        this.props.language
      );
      this.props.attemptGetBrokerQuestion(1, "en");
    }
  }

  componentWillUnmount() {
    this.props.onClearBrokerInfo();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  render() {
    const { brokerPage, t, brokerQuestion} = this.props;
    return (
      <div>
        <Helmet>
          <title>{`${
            brokerPage ? brokerPage.sysname : ""
          } Broker Review | ForexGrad`}</title>
          <meta name="description" content={ brokerPage ? (brokerPage.sysname === 'IFC Markets'?"This Review shows that IFC Markets Broker is an experienced, regulated international Forex and CFD broker with many achievements. IFC Markets values its clients.":brokerPage.description.split(".", 2) + '.') : "" }/>
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div>
          {this.props.brokerPage ? (
            <div>
              <div className="container mb-4 d-flex flex-wrap">
                <div className="main_with_left forex_company">
                  <h1>

                    <span>{` ${brokerPage.sysname}`} Broker Review</span>
                  </h1>
                  <div className="bg_wrap">
                    <div className="rating_header">
                      <div>
                        <img
                          className="broker_logo"
                          src={brokerPage.logo}
                          alt={` ${brokerPage.sysname}` + ' Broker Review'}
                          title={` ${brokerPage.sysname}` + ' Broker Review'}
                        />
                      </div>
                      <div>
                        <div className="st_left">
                          <ReactStars
                            count={5}
                            // value={3}
                            value={brokerPage.averageRate}
                            size={30}
                            edit={false}
                            half={true}
                            color1={"#cfcfcf"}
                            color2={"#ffcc36"}
                            // rating={3}
                            rating={brokerPage.averageRate}
                          />
                          <span className="rating_stars">
                            <span>{brokerPage.averageRate}</span> / 5
                          </span>
                        </div>
                        {/*<div className="reviews_block">{`${brokerPage.reviewCount} qualified reviews**`}</div>*/}
                        <div className="reviews_block">
                          {renderHtml(
                            t("QUALIFIED_REVIEWS", {
                              REV: brokerPage.reviewCount
                            })
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="broker_txt_block">
                      <span>{renderHtml(brokerPage.description)}</span>
                    </div>
                    <div className="rating_wrap">
                       <div className="rating_info">
                       <table>
                       <tbody>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="company">🏢</span> {t("COMPANY")}</span>
                             </td>
                             <td className="table_rating_info">
                               <span>{brokerPage.sysname}</span>
                             </td>
                           </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="founded">📆</span> {t("FOUNDED")}</span>
                             </td>
                             <td className="table_rating_info">
                               <span>{brokerPage.founded}</span>
                             </td>
                           </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="Headquarters">🏤</span> {t("HEADQUARTER")}</span>
                             </td>
                             <td className="table_rating_info">

                                   <span>
                                     {brokerPage.headquarters.map((item, index) => {
                                       return <span key={index}>{item.label + " "}</span>;
                                     })}
                                   </span>
                             </td>
                           </tr>
                          <tr id=''>
                             <td className="table_rating">
                                 <span><span role="img" aria-labelledby="Payment Options">💸</span> {t("PAYMENT_OPTIONS")}</span>
                             </td>
                            <td className="table_rating_info">
                               <span>
                                    {brokerPage.paymentOptions.map((item, index) => {
                                      return <span key={index}>{item.label + " "}</span>;
                                    })}
                                  </span>
                            </td>
                          </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="Demo Platforms">🎮</span> {t("DEMO_PLATFORMS")}</span>
                             </td>
                             <td className="table_rating_info">
                               <span>{brokerPage.demoPlatform
                                         .toString()
                                         .replace(/,/g, ",  ")}
                               </span>
                             </td>
                           </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="Account Currencies">💲</span> {t("ACCOUNT_CURRENCIES")}</span>
                             </td>
                             <td className="table_rating_info currencies">
                               <span
                                   className="text-overflow-60"
                                       title={brokerPage.currencies
                                         .toString()
                                         .replace(/,/g, ",  ")}
                                     >
                                       {brokerPage.currencies
                                         .toString()
                                         .replace(/,/g, ",  ")}
                               </span>
                             </td>
                           </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="Maximum Leverage">💰</span> {t("MAXIMUM_LEVERAGE")}</span>
                             </td>
                             <td className="table_rating_info">
                               <span>{brokerPage.maxLeverage}</span>
                             </td>
                           </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="Currency Pairs">💱</span> {t("CURRENCY_PAIRS_NAME")}</span>
                             </td>
                             <td className="table_rating_info">
                               <span>{brokerPage.currencyPairsAmount}</span>
                             </td>
                           </tr>
                           <tr>
                             <td className="table_rating">
                               <span><span role="img" aria-labelledby="Regulated by">⚖</span>️ {t("REGULATED_BY")}</span>
                             </td>
                             <td className="table_rating_info regulation">
                               <span>{brokerPage.regulatedBy
                                         .toString()
                                         .replace(/,/g, ",  ")}
                               </span>
                             </td>
                           </tr>
                       </tbody>
                       </table>
                      </div>



                      <div className="row">
                        <div className="offers">
                          <ul className="ul-tick">
                            <li
                              className={`there_is_${brokerPage.advantages[0].bonusProgram}`}
                            >
                              {/*Bonus offers***/}
                              {t("BONUS_OFFERS")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[1].tradingContests}`}
                            >
                              {/*Trading contests***/}
                              {t("TRADING_CONTESTS")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[2].freeEducation}`}
                            >
                              {/*Free education***/}
                              {t("FREE_EDUCATION")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[3].personalManager}`}
                            >
                              {/*Personal manager***/}
                              {t("PERSONAL_MANAGER")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[4].islamicAccountsAvailable}`}
                            >
                              {/*Islamic accounts available***/}
                              {t("ISLAMIC_ACCOUNTS_AVAILABLE")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[5].tradingByPhone}`}
                            >
                              {/*Trading by telephone***/}
                              {t("TELEPHONE_TRADING")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[6].affiliateProgram}`}
                            >
                              {/*Affiliate program***/}
                              {t("AFFILIATE_PROGRAM")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[7].freeAnalytics}`}
                            >
                              {/*Free analytics***/}
                              {t("FREE_ANALYTICS")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[8].wideChoiceInstruments}`}
                            >
                              {/*Wide Choice Instruments***/}
                              {t("WIDE_CHOICE")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[9].freeMarginInterest}`}
                            >
                              {/*Free Margin Interest***/}
                              {t("FREE_MARGIN")}
                            </li>
                          </ul>
                        </div>
                        <a
                          className="btn btn_md button_green"
                          href={brokerPage.externalUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("START_TRADING_1")}
                        </a>
                      </div>
                    </div>

                    {brokerPage &&
                    brokerPage.accountTypes &&
                    brokerPage.accountTypes.length ? (
                      <div className="acc_type_info">
                        <div className="rating_info">
                          <h2>
                            {/*Account Types*/}
                            {` ${brokerPage.sysname}`} {t("TYPES_OF_ACCOUNTS")}
                          </h2>
                          {brokerPage.accountTypes.map((item, activeIndex) => {
                            return (
                              <span
                                key={activeIndex}
                                className={`${
                                  this.state.activeIndex === activeIndex
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.setState({ activeIndex })}
                              >
                                {`${item.accountType} (${item.tradingPlatform})`}
                              </span>
                            );
                          })}

                          <div>
                            <span>
                              {/*Minimum account size***/}
                              {t("MIN_ACCOUNT_SIZE")}
                            </span>
                            {brokerPage &&
                            brokerPage.accountTypes &&
                            brokerPage.accountTypes.length ? (
                              <span>
                                {" "}
                                {`${brokerPage &&
                                  brokerPage.accountTypes &&
                                  brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].minAccountSize}` || "---"}
                              </span>
                            ) : (
                              <span>"---"</span>
                            )}
                          </div>
                          {brokerPage.accountTypes[this.state.activeIndex]
                              .minFixedSpread ? <div>
                            <span>
                              {/*Minimum fixed spread***/}
                              {t("MIN_FIXED_SPREAD")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .minFixedSpread
                                ? brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].minFixedSpread ||
                                  `${
                                    brokerPage.accountTypes[
                                      this.state.activeIndex
                                    ].minFixedSpread
                                  }`
                                : ""}
                            </span>
                          </div> : " "}
                          {brokerPage.accountTypes[this.state.activeIndex]
                              .minFloatSpread ? <div>
                            <span>
                              {/*Minimum fixed spread***/}
                              {t("MIN_FLOATING_SPREAD")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                  .minFloatSpread
                                  ? brokerPage.accountTypes[
                                      this.state.activeIndex
                                      ].minFloatSpread ||
                                  `${
                                      brokerPage.accountTypes[
                                          this.state.activeIndex
                                          ].minFloatSpread
                                  }`
                                  : ""}
                            </span>
                          </div> : "" }
                          <div>
                            <span>
                              {/*Minimum position size***/}
                              {t("MIN_POSITION_SIZE")}
                            </span>
                            <span>
                              {" "}
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .minPositionSize ||
                                `${
                                  brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].minPositionSize
                                }` ||
                                "---"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Spread type***/}
                              {t("SPREAD_TYPE")}
                            </span>
                            <span>
                              {" "}
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .spreadType ||
                                `${
                                  brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].spreadType
                                }` ||
                                "---"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Scalping***/}
                              {t("SCALPING")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .isScalping
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Expert advisors***/}
                              {t("EXPERT_ADVISORS")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .isExpertAdvisors
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Trading Instruments***/}
                              {t("TRADIN_INSTRUMENTS")}
                            </span>
                            <span
                              onClick={() => {
                                if (this.state.showInstruments) {
                                  this.setState({
                                    showInstruments: false,
                                    arrowInstruments: ""
                                  });
                                } else
                                  this.setState({
                                    showInstruments: true,
                                    arrowInstruments: "u_turn"
                                  });
                              }}
                            >
                              {/*<span className="extra_block">Show Options**</span>*/}
                              {renderHtml(t("SHOW_OPTIONS"))}
                              <span
                                className={`arrow_to_show ${this.state.arrowInstruments}`}
                              />
                            </span>
                          </div>
                          <span
                            className="show_options"
                            style={{
                              display: this.state.showInstruments ? "" : "none"
                            }}
                          >
                            <span className="show_options_cont">
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .tradingInstrument &&
                                brokerPage.accountTypes[
                                  this.state.activeIndex
                                ].tradingInstrument.map((item, index) => {
                                  return <span key={index}>{item}</span>;
                                })}
                              {/*<span> {brokerPage.accountTypes[this.state.activeIndex].tradingInstrument}</span>*/}
                            </span>
                          </span>
                          <div>
                            <span>
                              {/*Trading platforms***/}
                              {t("TRADING_PLATFORMS")}
                            </span>
                            <span>
                              {" "}
                              {
                                brokerPage.accountTypes[this.state.activeIndex]
                                  .tradingPlatform
                              }
                            </span>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    ) : (
                      <div className="acc_type_info">
                        <div className="rating_info">
                          <h2>
                            {/*No Account Types***/}
                            {t("NO_ACCOUNT_TYPES")}
                          </h2>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/*<LatestReviews />*/}
                <div className="right_box_block">
                  <div className="expel_class">
                    <TopBrokers />
                  </div>
                  {/*<BrokrReviwe/>*/}
                  <div className="expel_class">
                    <TopPromotionsBlock />
                  </div>
                </div>
              </div>
              {
                brokerQuestion && brokerQuestion.length !== 0  ?  <BrokerQuestions data={brokerQuestion} /> : ''
              }
              <div className="container mb-4 d-flex flex-wrap">
                <div className="main_with_left comments">
                  <h2>
                    {/*REVIEWS*/}
                    {brokerPage.sysname +  " " + t("REVIEWS").toUpperCase()}
                  </h2>
                  <div className="bg_wrap fb">
                    <LatestBrokerReview
                      sysname={this.props.match.params.sysname}
                      countPerPage={10}
                      handlePageChange={e => this.handlePageChange(e)}
                      activePage={this.state.activePage}
                    />
                    {/*<Pagination*/}
                    {/*activePage={this.state.activePage}*/}
                    {/*itemsCountPerPage={10}*/}
                    {/*totalItemsCount={450}*/}
                    {/*pageRangeDisplayed={5}*/}
                    {/*onChange={(e)=>this.handlePageChange(e)}*/}
                    {/*/>*/}
                  </div>
                  {this.props.latestReviews &&
                  this.props.latestReviews.count ? (
                    this.props.latestReviews.count > 10 ? (
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={
                          this.props.latestReviews &&
                          this.props.latestReviews.count
                        }
                        pageRangeDisplayed={10}
                        onChange={e => this.handlePageChange(e)}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="right_box_block">
                  <RegisterForm
                    sysname={this.props.match.params.sysname}
                    countPerPage={5}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <h1 className="loader">
                {/*loading***/}
                {t("LOADING")}
              </h1>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    // broker: store.brokers[props.match.params.sysname],
    language: store.language,
    brokerPage: store.brokerPage,
    latestReviews: store.latestReviews,
    brokerQuestion: store.brokerQuestion
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetBrokerQuestion: (sysname, lang) =>
        dispatch(attemptGetBrokerQuestion(sysname, lang)),
    attemptGetBrokerPage: (lang, sysname) =>
      dispatch(attemptGetBrokerPage(lang, sysname)),
    attemptGetBrokerList: (lang, limit, orderby) =>
      dispatch(attemptGetBrokerList(lang, limit, orderby)),
    attemptGetLatestReviews: (sysname, countPerPage, page, lang) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, page, lang)),
    onClearBrokerInfo: () => dispatch(onClearBrokerInfo())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(BrokerPage)
);
