import React from "react";
import "./../../assets/css/components/filters/filters.css";

class Checkbox extends React.Component {
  render() {
    return (
      <label onChange={this.props.onChange}>
        <input
          type="checkbox"
          group={this.props.group}
          id={this.props.id}
          checked={this.props.checked}
        />
        <div className="checkmark" />
        <span>{this.props.title}</span>
      </label>
    );
  }
}
export default Checkbox;
