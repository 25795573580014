import React from "react";
import "./../assets/css/components/Tabs.css";
import TabsContUp from "./TabsContUp";
import TabsContDown from "./TabsContDown";
import { translate } from "react-i18next";

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTabIndex: 0 };
  }
  render() {
    const { t } = this.props;
    const content = [
      {
        title: "TOP_GAINERS",
        component: <TabsContUp />
      },
      {
        title: "TOP_LOSERS",
        component: <TabsContDown />
      }
    ];

    return (
      <div className="tab_wrap">
        <div className="tabs_tab">
          {content.map((tab, i) => (
            <div
              key={i}
              className={this.state.activeTabIndex === i ? "active" : ""}
              onClick={() => this.setState({ activeTabIndex: i })}
            >
              {t(tab.title)}
            </div>
          ))}
        </div>
        {content[this.state.activeTabIndex].component}
      </div>
    );
  }
}
export default translate("common")(Tab);
