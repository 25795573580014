import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import { getTechAnalysDetails } from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import TopBrokers from "../TopBrokers";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class TechnicalAnalysisDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getTechAnalysDetails(
      this.props.language,
      this.props.match.params.instrName,
      this.props.match.params.date
    );
  }

  render() {
    const { techAnalysDetails, t } = this.props;

    return (
        <div className="container mb-4 d-flex flex-wrap">
          <div className="main_with_left">
            <h2>
              {/*MARKET OVERVIEW*/}
              {t("LIST_MARKET_OVERVIEW").toUpperCase()}
            </h2>
            <div className="bg_wrap">
              {techAnalysDetails ? (
                <div className="tech_table m_overwiev">
                  <div className="video_less_title">
                    {techAnalysDetails.title}
                  </div>
                  <div className="row">
                    {renderHtml(techAnalysDetails.text)}
                    <div className="vdl_footer_bl">
                      <div className="date_block">{techAnalysDetails.date}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loader">
                  {/*loading***/}
                  {t("LOADING")}
                </div>
              )}
            </div>
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <TopBrokers />
            </div>
          </div>
        </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    techAnalysDetails: store.techAnalysDetails,
    language: store.language
  };
};

function mapDisspatchToProps(dispatch) {
  return {
    getTechAnalysDetails: (lang, instrName, date) =>
      dispatch(getTechAnalysDetails(lang, instrName, date))
  };
}

export default connect(
  mapStateToProps,
  mapDisspatchToProps
)(TechnicalAnalysisDetails);
