import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import Videos from "../../containers/videoOvervie/Videos";
import { getMarketOverviewList } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { ARTICLES_DETAIL_CREATOR } from "../../configs/constants";
import RightBanner from "../../components/RightBanner";

const DATA = {
  "gurutrade-news-finance": "GURUTRADE",
  ifc: "ifc",
  "nbc-device": "cnbc",
  "myfxbook-forex-community": "myfxbook",
  "instaforex-news": "instaforex"
};

// import EducationSections from "./MarketOverviewDetail";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class MarketSentiment extends React.Component {
  constructor(props) {
    super(props);
    const path = this.props.history.location.pathname.split("/")[2] || "ifc";
    this.state = {
      brokerName:
        DATA[this.props.history.location.pathname.split("/")[2]] || "ifc",
      news: "gurutrade-news-finance",
      brokerData: { value: path, label: DATA[path] },
      rssItems: null,
      newsCount: 0,
      activePage: 1,
      page: 1
    };
  }

  getDate = arg => {
    const date = new Date(arg);
    let month = null;
    if (date.getMonth() + 1 < 10) {
      month = "0" + (date.getMonth() + 1);
    } else month = date.getMonth() + 1;
    let day = null;
    if (date.getDate() + 1 < 10) {
      day = "0" + (date.getDate() + 1);
    } else day = date.getDate() + 1;

    return date.getFullYear() + "-" + month + "-" + day;
  };

  attemptGetData(lang, activePage, countPerPage = 10) {
    fetch(
        process.env.REACT_APP_API_URL + `/articles?lang=${lang}&page=${activePage}&countPerPage=${countPerPage}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data.list, newsCount: data.count }, () =>
          console.log(this.state.rssItems, "YUYUYUY")
        );
      })
      .catch();
  }

  componentDidMount() {
    this.attemptGetData(this.props.language, this.state.activePage);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    // this.attemptGetData(this.props.language, this.state.activePage)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.language !== prevProps.language ||
      this.state.activePage !== prevState.activePage
    ) {
      this.attemptGetData(this.props.language, this.state.activePage);
    }
  }

  render() {
    const { t,language} = this.props;
    const { rssItems } = this.state;

    return (
      <div>
        <Helmet>
          <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
          <link rel='canonical' href={'https://www.forexgrad.com/' + language + '/financial-news'} />
        </Helmet>
          <div className="container mb-4 d-flex flex-wrap">
            <div className="video_less_wrap main_with_left">
              <h1>
                {/*NEWS*/}
                {t("ADVISORS_LINKS")}
              </h1>
              <div className="bg_wrap">
                {/*{marketOverviewList ? (*/}
                {/*    marketOverviewList.length > 0 ? (*/}
                {/*        marketOverviewList.map((item, index) => {*/}
                {rssItems ? (
                  rssItems.map((item, index) => {
                    return (
                      <div key={index} className="video_cont_block">
                        {/*<div className="vid_cont_block_txt p-0">*/}
                        <div>
                          <div className="video_less_title">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={item.link}
                              // href={item.url}
                            >
                              {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                              {item.title}
                              {/*</Link>*/}
                            </a>
                          </div>
                          <div className={"new_cont_block expert-block"}>
                            <Link to={ARTICLES_DETAIL_CREATOR(language,item.slug)}>
                              <img src={item.picture} alt="expert" />
                            </Link>
                            <div className="new_cont_block">
                              {renderHtml(item.description)}
                              {/*{renderHtml(item.text)}*/}
                            </div>
                          </div>

                          <div className="vdl_footer_bl">
                            {/*<div className="date_block">{this.getDate(item.date)}</div>*/}
                            <div className="date_block">{`${
                              item.date.split("T")[0]
                            } ${item.date.split("T")[1].slice(0, 5)}`}</div>
                            <div>
                              source by:{" "}
                              <Link to={ARTICLES_DETAIL_CREATOR(language,item.slug)}>
                                {item.author}
                              </Link>
                            </div>
                            {/*<div className="date_block">{item.date}</div>*/}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.newsCount}
                  pageRangeDisplayed={5}
                  onChange={e => this.handlePageChange(e)}
                />
              </div>
            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className="expel_class">
                <TopBrokers />
              </div>
              <RightBanner/>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    marketOverviewList: store.marketOverviewList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getMarketOverviewList: lang => dispatch(getMarketOverviewList(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketSentiment))
);
