import React from "react";
import * as RSSParser from "rss-parser";
import "./styles/TopTrades.css";

class CommodityMarketCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topTradesList: []
    };
  }
  componentDidMount() {
    const MY_URL =
      "http://www.ifcmarkets.com/en/api/commodity-market-calendar.xml&showDesc=true";
    let parser = new RSSParser();
    fetch(MY_URL).then(response =>
      parser.parseURL(MY_URL, (err, feed) => {
        if (err) throw err;
        this.setState({ topTradesList: feed.items });
      })
    );
  }
  render() {
    return <div></div>;
  }
}

export default CommodityMarketCalendar;
