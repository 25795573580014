import React from "react";
import ModalVideo from "react-modal-video";

class VideoPlayer extends React.Component {
  render() {
    return (
      <div>
        <ModalVideo
          channel="youtube"
          isOpen={this.props.isVideoModalOpened}
          videoId={this.props.videoId}
          onClose={() => this.props.closeModal()}
        />
        <div className="yout_video_icn">
          <div />
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
