import React from "react";

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.outerStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      textAlign: "center",
      zIndex: 1
    };

    // default style
    this.style = {
      modal: {
        position: "relative",
        display: "inline-block",
        padding: 20,
        boxSizing: "border-box",
        // backgroundColor: "#fff",
        margin: "40px auto",
        borderRadius: 3,
        zIndex: 2,
        textAlign: "center",
        ...this.props.style.modal
      },
      overlay: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        ...this.props.style.overlay
      }
    };
  }

  // render modal
  render() {
    return (
      <div
        style={{
          ...this.outerStyle,
          display: this.props.isModalOpen ? "block" : "none"
        }}
      >
        <div style={this.style.overlay} onClick={this.props.closeModal} />
        <div onClick={this.props.closeModal} />
        <div style={this.style.modal}>{this.props.children}</div>
      </div>
    );
  }
}

export default Modal;
