import React from "react";
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import apiMiddleWare from "./redux/apiMiddleWare";
import mainReducer from "./redux/reducers";
import Header from "./components/header/Index";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/global.css";
import "./assets/css/fonts.css";
import "./assets/css/gorexgrad.css";
import MenuNav from "./components/header/MenuNav";
import { Router,Switch } from 'react-router-dom';
import Routes from "./configs/routes";
import TagManager from "react-gtm-module";


const tagManagerArgs = {
  gtmId: "GTM-KCLGMJQ"
};
export const history = createBrowserHistory();

TagManager.initialize(tagManagerArgs);
const store = createStore(
  mainReducer,
  applyMiddleware(
    // logger,
    apiMiddleWare
    // logger
  )
);

export default function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <div className='head'>
                    <Header />
                    <MenuNav />
                </div>
                <div className="content">
                <Switch >
                    <Routes />
                </Switch>
                </div>
            <Footer />
            </Router>
        </Provider>
    );
}
