import React from "react";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ARTICLES,
  ARTICLES_DETAIL_CREATOR,
  OUR_ARTICLES_DETAIL_CREATOR
} from "../../configs/constants";
import {getArticleList, onClearArticleDetails} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import Global from "./../../assets/css/Global.module.css";
import ExpertCor from "../../assets/css/components/ExpertCorner.module.css";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class ExpertCorner extends React.Component {
  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  componentDidMount() {
    this.props.getArticleList(this.props.language, 5, 1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.getArticleList(this.props.language, 5, 1);
    }
  }

  render() {
    const { t, article,language} = this.props;
    return (
        <div
            className={`${Global.right_box_block} ${Global.right_box} ${ExpertCor.right_box_block}`}
        >
          <h5>{t("EXPERT_CORNER")}</h5>
          <div className={ExpertCor.expert_corner}>
            <div>
              {article &&
              article.list.map((item, index) => {
                return (
                    <div className={ExpertCor.expert_block} key={index}>
                      {item.company !== "ifcm" ? (
                          <Link
                              onClick={this.scrolling.bind(this)}
                              to={ARTICLES_DETAIL_CREATOR(language,item.slug)}
                          >
                            <img
                                src={item.picture}
                                alt={"ex cor."}
                                width={"50px"}
                                height={"50px"}
                            />
                          </Link>
                      ) : (
                          <Link
                              onClick={this.scrolling.bind(this)}
                              to={OUR_ARTICLES_DETAIL_CREATOR(language,item.slug)}
                          >
                            <img
                                src={item.picture}
                                alt={"ex cor."}
                                width={"50px"}
                                height={"50px"}
                            />
                          </Link>
                      )}

                      <div className={ExpertCor.expert_right_block}>
                        {item.company !== "ifcm" ? (
                            <Link to={ARTICLES_DETAIL_CREATOR(language,item.slug)}>
                              <div className={ExpertCor.expert_title}>
                                {item.title}
                              </div>
                            </Link>
                        ) : (
                            <Link to={OUR_ARTICLES_DETAIL_CREATOR(language,item.slug)}>
                              <div className={ExpertCor.expert_title}>
                                {item.title}
                              </div>
                            </Link>
                        )}
                        <div className={ExpertCor.expert_date_bl}>{`${
                            item.date.split("T")[0]
                        } / ${item.author}`}</div>
                      </div>
                    </div>
                );
              })}
              <Link onClick={this.scrolling.bind(this)} to={ARTICLES}>
                {/*See all articles*/}
                {/*<span className="right_arrow">*/}
                {/*  <i className="icon-right-small" />*/}
                {/*</span>*/}
                {/****/}
                {renderHtml(t("SEE_ALL_ATRICLES"))}
              </Link>
            </div>
          </div>
        </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language,
    article: store.article
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getArticleList: (lang, countPerPage, page) =>
        dispatch(getArticleList(lang, countPerPage, page)),
    onClearArticleDetails: () => dispatch(onClearArticleDetails())
  };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(ExpertCorner)
);
