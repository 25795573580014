import React from "react";
import ForexGradSocket from "../api/WebSocket";

class TopLosersItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      livePrice: "--- / ---"
    };
  }

  getTopLosersItemQuote() {
    const Socket = new ForexGradSocket(
      () => Socket.sendQuote(this.props.symbol),
      data => {
        this.setState({ livePrice: `${data.ask} / ${data.bid}` });
      }
    );
  }

  getTopLosersItemQuoteClose() {
    const Socket = new ForexGradSocket(() => Socket.close());
  }

  componentDidMount() {
    this.getTopLosersItemQuote();
  }

  componentWillUnmount() {
    this.getTopLosersItemQuoteClose();
  }

  render() {
    const { symbol, transname, percent, change, number } = this.props;
    return (
      <tr key={number}>
        <td className="table_head">
          {/*Name*/}
          {symbol}
        </td>
        <td className="table_head">
          {/*Instrument*/}
          {transname}
        </td>
        <td className="table_head">
          {/*Change*/}
          {percent}%
        </td>
        <td className="table_head">
          {/*Instrument*/}${change}
        </td>
        <td className="table_head">
          {/*Change*/}
          {this.state.livePrice}
        </td>
      </tr>
    );
  }
}
export default TopLosersItem;
