import React from "react";
import "./../../assets/css/components/brokers/latest_reviews.css";
import ReactStars from "react-stars";
import { attemptGetLatestReviews } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";

class LatestBrokerReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      page: 1
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.language !== prevProps.language ||
      this.props.activePage !== prevProps.activePage
    ) {
      this.props.attemptGetLatestReviews(
        this.props.sysname,
        10,
        this.props.activePage,
          this.props.language
      );
    }
  }

  componentDidMount() {
    this.props.attemptGetLatestReviews(this.props.sysname, 10, this.state.page, this.props.language);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="">
        {this.props.latestReviews ? (
          this.props.latestReviews.list &&
          this.props.latestReviews.list.length > 0 ? (
            this.props.latestReviews.list.map((item, index) => {
              return (
                <div
                  className={`reviews_cont ${
                    item.rate * 2 < 4
                      ? "wewak"
                      : item.rate * 2 > 7
                      ? "yello_green"
                      : "hawkes_blue"
                  }`}
                  key={index}
                >
                  <div>
                    <div className="row">
                      <p className="reviews_author">{item.name}</p>
                      <span className="slash">/</span>
                      <div className="reviews_date">{item.created}</div>
                    </div>
                    <div className="left_part_reviews">
                      <div className="rating">
                        <ReactStars
                          count={5}
                          value={item.rate}
                          size={20}
                          edit={false}
                          half={true}
                          color1={"#cfcfcf"}
                          color2={"#ffcc36"}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="reviews_txt">{item.review}</p>
                </div>
              );
            })
          ) : (
            <h1>
              {/*NO COMMENTS***/}
              {t("NO_COMMENTS")}
            </h1>
          )
        ) : (
          <div className="loader">
            {/*loading***/}
            {t("LOADING")}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    latestReviews: store.latestReviews
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetLatestReviews: (sysname, countPerPage, page, lang) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, page, lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(LatestBrokerReview)
);
