import React from "react";
import LiveQuotes from "./LiveQuotes";
import News from "./News";
import BrokerRatings from "../../containers/BrokerRatings";
import CalendarContainer from "./../content/CalendarContainer";
import ExpertCorner from "../../containers/articles/ExpertCorner";
import TopTradesBlock from "../../containers/topTrades/TopTradesBlock";

export default class Index extends React.Component {
    render() {
        return (
            <div>
                <LiveQuotes/>
                <News/>
                <BrokerRatings/>
                <CalendarContainer/>
                <div className="container mb-4 d-flex flex-wrap">
                    <TopTradesBlock/>
                    <ExpertCorner/>
                </div>
            </div>
        );
    }
}
