import React from "react";
import "./main.css";
import ReactStars from "react-stars";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { attemptGetLatestReviews } from "../../../redux/actions/indexActions";

class RegisterForm extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      ratingChanged: 0,
      render: true,
      rateMassage: "none",
      showModal: "none"
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["username"] = "";
      fields["emailid"] = "";
      fields["massage"] = "";
      this.setState({ fields: fields, showModal: "" });
    }
  }

  submituserReview() {
    const query = {
      lang: this.props.language,
      sysname: this.props.sysname,
      name: this.state.fields["username"],
      review: this.state.fields["massage"],
      email: this.state.fields["emailid"],
      rate: this.state.ratingChanged
    };
    const queryParams = Object.keys(query)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
      })
      .join("&");
    fetch(process.env.REACT_APP_API_URL +`/broker/add-review`, {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: queryParams
    })
      .then(res => {
        // alert("Review added successfully");
        this.props.attemptGetLatestReviews(
          this.props.sysname,
          this.props.countPerPage,
            this.props.language
        );
      })
      .catch();
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "*Please enter your username.";
    }
    if (typeof fields["username"] !== "undefined") {
      if (
        !fields["username"].match(/[a-zA-Z ]*$/) ||
        fields["username"].length > 20
      ) {
        formIsValid = false;
        errors["username"] = "*Please enter more shortly name version.";
      }
    }
    if (!fields["massage"]) {
      formIsValid = false;
      errors["massage"] = "*Please enter massage.";
    }
    if (typeof fields["massage"] !== "undefined") {
      if (!fields["massage"].match(/[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["massage"] = "*Please enter some text only.";
      }
    }
    if (!fields["emailid"]) {
      formIsValid = false;
      errors["emailid"] = "*Please enter your email-ID.";
    }
    if (typeof fields["emailid"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["emailid"])) {
        formIsValid = false;
        errors["emailid"] = "*Please enter valid email-ID.";
      }
    }
    if (!this.state.ratingChanged) {
      formIsValid = false;
      this.setState({ rateMassage: "" });
    }
    if (formIsValid) {
      this.setState({ rateMassage: "none" });
      this.submituserReview();
    } else {
      this.setState({ rateMassage: "" });
    }

    this.setState({
      errors: errors,
      ratingChanged: 0
    });

    return formIsValid;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="right_box" id="main-registration-container">
        <h2>
          {/*Submit Your Review***/}
          {t("SUBMIT_REIVEW")}
        </h2>
        <div id="register">
          <div
            style={{
              height: "calc(100% - 40px)",
              width: "calc(100% - 40px)",
              backgroundColor: "#ffffff",
              display: this.state.showModal,
              position: "absolute",
              zIndex: 1000,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div className="ok_wrap">
              <div className="txt_1">
                {/*Review added successfully***/}
                {t("REVIEW_ADDED")}
              </div>
              <button
                className="txt_2 btn btn_sm button_grayish_blue"
                onClick={() => this.setState({ showModal: "none" })}
              >
                OK
              </button>
            </div>
          </div>
          <p>
            {/*Your review will be checked by a moderator and published on this*/}
            {/*page. Please, try to avoid profanity and foul language in the text*/}
            {/*of your review, or it will be declined from publishing.***/}
            {t("CHECKING_REVIEW")}
          </p>
          <form
            className="form_review"
            method="post"
            name="userRegistrationForm"
            onSubmit={this.submituserRegistrationForm}
          >
            <label>
              {/*Your Name * ***/}
              {t("YOUR_NAME")}
            </label>
            <input
              type="text"
              name="username"
              value={this.state.fields.username}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.username}</div>
            <label>
              {/*Your Email Address * ***/}
              {t("$_EMAIL_ADDRESS")}
            </label>
            <input
              type="text"
              name="emailid"
              value={this.state.fields.emailid}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.emailid}</div>
            <label>
              {/*Review * ***/}
              {t("YOUR_REVIEW")}
            </label>
            <textarea
              type="text"
              name="massage"
              value={this.state.fields.massage}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.massage}</div>

            <div className="reactStars">
              <div className="your_rate">
                {/*Your rate * */}
                {t("STAR_RATING")}
              </div>
              <ReactStars
                size={25}
                onChange={newRating =>
                  this.setState({ ratingChanged: newRating })
                }
                value={this.state.ratingChanged}
                half={false}
                count={5}
                color1={"#cfcfcf"}
                color2={"#ffcc36"}
              />
              <div
                className="errorMsg"
                style={{ display: this.state.rateMassage }}
              >
                {/**choose rating***/}
                {/*{t("YOUR_NAME")}*/}
              </div>
            </div>
            <input
              type="submit"
              className="btn button_green"
              value="Send Review"
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    language: store.language,
    brokerPage: store.brokerPage
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetLatestReviews: (sysname, countPerPage, lang) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
);
