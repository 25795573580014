import React from "react";
import TimezonePicker from "react-timezone";

export default class Watch extends React.Component {
  render() {
    return (
      <div className="watch">
        <TimezonePicker
          value="Asia/Yerevan"
          inputProps={{
            placeholder: "Timezone...",
            name: "timezone",
            className: "time_zone"
          }}
        />
      </div>
    );
  }
}
