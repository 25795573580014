import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import { connect } from "react-redux";
import {
  getCurrencyDescription,
  getSymbolQuotData
} from "../../redux/actions/indexActions";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";
import {Helmet} from "react-helmet";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class CurrencyPairs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstDesc: "",
      secondDesc: ""
    };

    this.getCurrencyDesc = this.getCurrencyDesc.bind(this);
  }

  componentDidMount() {
    this.getCurrencyDesc();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currency.value !== prevProps.currency.value || this.props.language !== prevProps.language) {
      this.getCurrencyDesc(this.props.language);
    }
  }

  getCurrencyDesc() {
    if (
      !this.props.match.params.type ||
      this.props.match.params.type === "forex"
    ) {
      let firstCur = `${this.props.currency.value.slice(0, 3).toUpperCase()}`;
      let secondCur = `${this.props.currency.value.slice(3, 6).toUpperCase()}`;
      const query = {
        lang: `${this.props.language}`,
        phrases: firstCur + `_F_PROFILE,` + firstCur
      };
      const query_2 = {
        lang: `${this.props.language}`,
        phrases: secondCur + `_F_PROFILE,` + secondCur
      };
      const queryParams = Object.keys(query)
        .map(key => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        })
        .join("&");
      const queryParams_2 = Object.keys(query_2)
        .map(key => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(query_2[key])
          );
        })
        .join("&");

      fetch(process.env.REACT_APP_API_URL+ `/phrases`, {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: queryParams
      })
        .then(res => res.json())
        .then(res => {
          this.setState({ firstDesc: res });
        })
        .catch();
      fetch(process.env.REACT_APP_API_URL + `/phrases`, {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: queryParams_2
      })
        .then(res2 => res2.json())
        .then(res2 => {
          this.setState({ secondDesc: res2 });
        })
        .catch();
    } else {
      // fetch(process.env.REACT_APP_API_URL + `/page/about-us?lang=${this.props.language}`, {
      fetch(
          process.env.REACT_APP_API_URL + `/instrument/description?lang=en&instrName=${this.props.currency.value}`,
        {
          method: "get",
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
      )
        .then(res => res.json())
        .then(data => {
          this.setState({ firstDesc: data, secondDesc: "" });
        })
        .catch();
    }
  }

  getTitle(category){
      const { t } = this.props;
      let title = "";

    if(this.state.firstDesc.alternate_name !== undefined || this.state.firstDesc[`${this.props.currency.value.slice(0, 3).toUpperCase()}`] !== undefined) {
      switch (category) {
        case 'indices':
          title = `${this.state.firstDesc.alternate_name} ${t('$_PRICE')} | ${this.state.firstDesc.alternate_name} ${t('$_QUOTE')} | ${this.state.firstDesc.alternate_name} ${t('$_CHART')} | ForexGrad`;
          break;
        case 'commodities':
          title = `${this.state.firstDesc.alternate_name} ${t('$_PRICE_CHART')} | ${t('$_CURRENT')} ${this.state.firstDesc.alternate_name} ${t('$_PRICE')} | ${this.state.firstDesc.alternate_name} ${t('$_PRICE_HISTORY')} | ForexGrad`;
          break;
        case 'stocks':
          title = `${this.state.firstDesc.alternate_name} ${t('$_STOCK_PRICE_HISTORY')} | ${this.state.firstDesc.alternate_name} ${t('$_STOCK_QUOTE')} | ${this.props.currency.value.toUpperCase()} ${t('$_QUOTE')} | ForexGrad`;
          break;
        case 'precious-metals':
          title = `${this.props.match.params.cur.toUpperCase()}  ${t('$_LIVE_CHART')} | ${this.props.match.params.cur.toUpperCase()}  ${t('$_PRICE_HISTORY')} | ForexGrad`;
          break;
        case 'gold-trading':
          title = `${this.state.firstDesc.alternate_name} | ${this.state.firstDesc.alternate_name} ${t('$_CHART')} | ${this.state.firstDesc.alternate_name} ${t('$_PRICE')} | ${this.props.match.params.cur.toUpperCase()} | ForexGrad`;
          break;
        case 'cfd-etf':
          title = `${this.props.currency.value.toUpperCase()} ${t('$_LIVE_CHART')} | ForexGrad`;
          break;

          //Forex
        default:
          title = `${this.props.currency.value.toUpperCase()} ${t("$_EXCHANGE_RATE")} | ${this.props.currency.value.toUpperCase()} ${t('$_LIVE_CHART')} |
           ${this.state.firstDesc[`${this.props.currency.value.slice(0, 3).toUpperCase()}`]}  ${this.state.secondDesc[`${this.props.currency.value.slice(3, 6).toUpperCase()}`]}  ${t('$_RATE')} | ForexGrad`;
          break;
      }
    }
    return title;
  }

  render() {
    return (
      <div className="bg_wrap container mb-4 section_cont">
        <Helmet>
          <title>{(this.props.match.params.type)? this.getTitle(this.props.match.params.type) : "AUDUSD Exchange Rate | AUDUSD Live Chart | Australian Dollar Dollar Rate"}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>

        <h2>About {this.props.currency.value}</h2>
        {!this.props.match.params.type ||
        this.props.match.params.type === "forex" ? (
          <div className="txt_bl_curr curr_p_block">
            <div>
              <div className="subheadline_block">
                {renderHtml(
                  this.state.firstDesc[
                    `${this.props.currency.value
                      .slice(0, 3)
                      .toUpperCase()}_F_PROFILE`
                  ]
                )}
              </div>
            </div>
            <div>
              <div className="subheadline_block">
                {renderHtml(
                  this.state.secondDesc[
                    `${this.props.currency.value
                      .slice(3, 6)
                      .toUpperCase()}_F_PROFILE`
                  ]
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="txt_bl_curr curr_p_block">
            <div className="one_column">
              <div className="subheadline_block">
                {renderHtml(this.state.firstDesc.description)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    newQuoteData: store.newQuoteData,
    instrName: store.instrName,
    chartInstrument: store.chartInstrument,
    currencyDescriotion: store.currencyDescriotion,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getSymbolQuotData: newCurrency => dispatch(getSymbolQuotData(newCurrency)),
    getCurrencyDescription: curDesc => dispatch(getCurrencyDescription(curDesc))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrencyPairs))
);