import React from "react";
import "./Rss.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "./Modal";

// overwrite style
const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0,0.5)"
  }
};

const mainStyle = {
  app: {},
  button: {
    backgroundColor: "#408cec",
    border: 0,
    padding: "12px 20px",
    color: "#fff",
    margin: "0 auto",
    width: 150,
    display: "block",
    borderRadius: 3
  }
};

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class RssParserWithImageClick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rssItems: null,
      show: false,
      imageUrl: "",
      isModalOpen: false,
      isInnerModalOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  // close modal (set isModalOpen, true)
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  // open modal (set isModalOpen, false)
  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  attemptGetData() {
    fetch(
      process.env.REACT_APP_API_URL +
        `/rss/${this.props.currentPage}?lang=${this.props.language}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data }, () => {
          const images = Array.from(document.querySelectorAll("img"));
          images.forEach(image => {
            image.addEventListener("click", () => {
              this.setState({ imageUrl: image.src }, () => this.openModal());
            });
          });
        });
      })
      .catch();
  }

  componentDidMount() {
    this.attemptGetData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      this.attemptGetData();
    }
  }

  getDate = arg => {
    const date = new Date(arg);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  render() {
    const { rssItems } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div style={mainStyle.app}>
          <div className="container">
            <div className="row">
              <div className="main-without-left">
                <div className="container page_title">
                  <div className="row">
                    <h1 className="">{this.props.currentPage}</h1>
                  </div>
                </div>
                <div className="page_content">
                  <div className="container">
                    {rssItems
                      ? rssItems.items.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="childlist childlist_wrapper">
                                <div className="news_title component_title">
                                  <div>
                                    <h2>
                                      <a href={item.link}>{item.title}</a>
                                    </h2>
                                  </div>
                                </div>
                                <div className="ifc_markets_new_wrapper_data">
                                  <div className="ifc_markets_new_wrapper_data_cont">
                                    {this.getDate(item.isoDate)}
                                    {/*{item.pubDate}*/}
                                  </div>
                                </div>
                                <div className="advant_cont_block component_text3">
                                  {/*{*/}
                                  {/*    this.props.images &&  <img src={images[Math.round(Math.floor(Math.random() * 10))]} height="50px" alt="image"/>*/}
                                  {/*}*/}
                                  <p ref={this.myRef} className={"aboasdas"}>
                                    {renderHtml(item.content)}
                                  </p>
                                  <div className="button_line">
                                    <div className="getchild_wrap">
                                      <a
                                        className=" btn-sm button_outline_green"
                                        href={item.link}
                                      >
                                        {t("LEARN_MORE")}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : t("LOADING")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main>
          <Modal
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            style={modalStyle}
          >
            <img
              width="100%"
              style={{ borderRadius: 3, height: 500, width: "auto" }}
              src={this.state.imageUrl}
              alt="unsplash"
            />
          </Modal>
        </main>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language
  };
};
export default translate("common")(
  connect(mapStateToProps)(withRouter(RssParserWithImageClick))
);
