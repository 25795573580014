import React from "react";
import "../../assets/css/components/right_box/videos.css";
import VideoOverview from "../../assets/css/components/VideoOverview.module.css";
import Global from "../../assets/css/Global.module.css";
import logo from "../../assets/images/video/video_overview/video_overview.jpg";
import { getLastVideo } from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { translate } from "react-i18next";
import {BROKERS_FOREX_VIDEO_OVERVIEW_MAKER} from "../../configs/constants";
import "./videoPlayBtn.css";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      isVideoModalOpened: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.getLastVideo(this.props.language);
    }
  }

  componentDidMount() {
    this.props.getLastVideo(this.props.language);
    if (this.props.video) {
      this.setState({ video: this.props.video });
    }
  }

  render() {
    const { t,language } = this.props;
    return (
      <div className={Global.right_box}>
        <h5>
          {/*Video Overview*/}
          {t("VIDEO_OVERVIEW")}
        </h5>
        <div className={VideoOverview.see_all_video_wrap}>
          <div className={VideoOverview.video_date_block}>
            <img
              src={logo}
              width="100%"
              height="auto"
              alt="VIDEO OVERVIEW..."
              onClick={() => this.setState({ isVideoModalOpened: true })}
            />
            <div
              className="videoButton"
              onClick={() => this.setState({ isVideoModalOpened: true })}
            >
              <div />
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isVideoModalOpened}
            videoId={this.props.video && this.props.video.videoId}
            onClose={() => this.setState({ isVideoModalOpened: false })}
          />
          <div className={VideoOverview.text_data_bl}>
            <span className={VideoOverview.video_news_title}>
              {this.props.video && this.props.video.title}
            </span>
            <span className={VideoOverview.see_all_date}>
              <span className={Global.date_block}>
                {this.props.video && this.props.video.date}
              </span>
              <span className={VideoOverview.link_color}>
                <Link to={BROKERS_FOREX_VIDEO_OVERVIEW_MAKER(language)}>
                  {/*See all videos <span className="icon-right-small"></span>***/}
                  {renderHtml(t("ALL_VIDEOS"))}
                </Link>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    video: store.video,
    language: store.language
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getLastVideo: lang => dispatch(getLastVideo(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(Videos)
);
