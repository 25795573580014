import React from "react";
import FxVideoTutorial from "../../assets/css/components/education/ForexVideoTutorial.module.css";
import Global from "../../assets/css/Global.module.css";
import TopBrokers from "../.././containers/TopBrokers";
import EducationSections from "../../containers/education/EducationSections";
import { getVideoList } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
const VideoTutorials = [
  {
    leftSide: {
      videoTitle: "What is Bitcoin Futures?",
      videoShortText:
        "In this video tutorial you will learn what Bitcoin is and how it works."
    },
    rightSide: {
      videoTitle: "What is Bitcoin Futures?",
      videoShortText:
        "In this video tutorial you will learn what Bitcoin is and how it works."
    }
  },
  {
    leftSide: {
      videoTitle: "What is Bitcoin Futures?",
      videoShortText:
        "In this video tutorial you will learn what Bitcoin is and how it works."
    },
    rightSide: {
      videoTitle: "What is Bitcoin Futures?",
      videoShortText:
        "In this video tutorial you will learn what Bitcoin is and how it works."
    }
  },
  {
    leftSide: {
      videoTitle: "What is Bitcoin Futures?",
      videoShortText:
        "In this video tutorial you will learn what Bitcoin is and how it works."
    }
  }
];

class ForexVideoTutorial extends React.Component {
  componentDidMount() {
    this.props.getVideoList(this.props.language);
  }

  render() {
    return (
      <div className="container mb-4 d-flex flex-wrap">
        <div
          className={`main_with_left ${Global.video_tutorial_cont}`}
        >
          <h2>Education</h2>
          <div className={Global.bg_wrap}>
            {VideoTutorials.map((videoTutorial, index) => (
              <div key={index} className={FxVideoTutorial.video_tutorial}>
                <div className={FxVideoTutorial.leftSide}>
                  <div className={FxVideoTutorial.video_les} data-video-hash="">
                    <div className="iframe_video_wrapper" data-source="">
                      <div />
                    </div>
                    <div className={Global.yout_video_icn}>
                      <div />
                    </div>
                  </div>
                  <h3>{videoTutorial.leftSide.videoTitle}</h3>
                  <p>{videoTutorial.leftSide.videoShortText}</p>
                </div>
                {videoTutorial.rightSide && (
                  <div className={FxVideoTutorial.rightSide}>
                    <div
                      className={FxVideoTutorial.video_les}
                      data-video-hash=""
                    >
                      <div className="iframe_video_wrapper" data-source="">
                        <div />
                      </div>
                      <div className={Global.yout_video_icn}>
                        <div />
                      </div>
                    </div>
                    <h3>{videoTutorial.rightSide.videoTitle}</h3>
                    <p>{videoTutorial.rightSide.videoShortText}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={Global.right_box_block}>
          <div className={Global.expel_class}>
            <EducationSections />
          </div>
          <div className={Global.expel_class}>
            <TopBrokers />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    broker: store.brokers[props.match.params.id],
    language: store.language,
    videoList: store.videoList
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getVideoList: (lang, sysname) => dispatch(getVideoList(lang, sysname))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(ForexVideoTutorial)
);
