import React, {useState} from 'react';
import LangMenu from "./LangMenu";
import ButtonNav from "./ButtonNav";
import "./../../assets/css/components/head.css";
import {Link} from "react-router-dom";
import {translate} from "react-i18next";
import {connect} from "react-redux";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slug: '',
            imageLink: '',
            ready: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.handleChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.language !== prevProps.language) {
            this.handleChange();
        }
    }

    handleChange() {
        const langObject = {
            en: [
                {
                    url: "https://www.ifcmarkets.com/en/ifcm-invest-landing?utm_campaign=forexGrad-website",
                    imageLink: 'https://www.ifcm.co.uk:2083/images/banners/ifcm-invest-728x90-EN.png'
                }
            ],
            fa: [
                {
                    url: "https://www.ifcmiran.com/fa/ifcm-invest-landing",
                    imageLink: 'https://www.ifcm.co.uk:2083/images/banners/ifcm-invest-728x90-FA.png'
                }
            ]
        };

        const langObjectItem = langObject[this.props.language];
        if(langObjectItem) {
            const randomItem = langObjectItem[Math.floor(Math.random() * langObjectItem.length)];
            this.setState({
                imageLink: randomItem.imageLink,
                slug: randomItem.url
            }, () => {
                this.setState({ready: true});
            });
        }
    }

    render() {
        return (
            <div className="header container">
                <div className="top">
                    <div><Link to={"/"} className="logo"/></div>
                    <a href={`${this.state.slug}`} id={"with-ifc-banner"} style={{
                        height: "90px",
                        maxWidth: "728px",
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        marginLeft: "20px",
                        backgroundImage: "url(" + this.state.imageLink + ")",
                    }}>

                    </a>
                    <div className="right_part">
                        <LangMenu/>
                        <ButtonNav/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        language: store.language
    };
};

export default translate("common")(connect(mapStateToProps)(Index));
