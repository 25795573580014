import React from "react";

class Robots extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        }
    }
    render() {
        fetch(process.env.REACT_APP_API_URL + "/robots.txt")
            .then((r) => r.text())
            .then(text  => {
                this.setState({text: text})
            })
        return (
            document.body.innerHTML = this.state.text
        )
    }

}

export default Robots;
