import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import ChtTb from "../../assets/css/components/ChartTable.module.css";
import { attemptSelectInstrumentsList } from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import ChartTableItem from "./ChartTableItem";
import { translate } from "react-i18next";


class ChartTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className={ChtTb.chart_table_wrap}>
        <div className={ChtTb.swipe}>
          Swipe table** <span className="icon-right-small"></span>
        </div>
        <div className={ChtTb.chart_table}>
          <table>
            <thead>
              <tr>
                <th>
                  {/*Symbol*/}
                  {t("SYMBOL")}
                </th>
                <th>
                  {/*Live Price*/}
                  {t("LIVE_PRICES")}
                </th>
                <th>
                  {/*Change         */}
                  {t("CHANGE")}
                </th>
              </tr>
            </thead>
            <tbody>
              <ChartTableItem/>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    selectInstruments: store.selectInstruments
  };
};

function mapDisspatchToProps(dispatch) {
  return {
    getInstrumentsList: group => dispatch(attemptSelectInstrumentsList(group))
  };
}

export default connect(
  mapStateToProps,
  mapDisspatchToProps
)(translate("common")(ChartTable));
