import React, {Component} from "react";
import {translate} from "react-i18next";
import {connect} from "react-redux";

class RightBanner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {
                    (this.props.language !== 'fa')
                        ?
                        <div className="banner_class">
                            <a href={`https://www.ifcmarkets.com/${this.props.language}/ifcm-invest-landing?utm_campaign=forexGrad-website`} target='_blank'>
                                <img src="https://www.ifcm.co.uk:2083/images/banners/invest-EN.png"/>
                            </a>
                        </div>
                        :
                        <div className="banner_class">
                            <a href={`https://www.ifcmiran.asia/${this.props.language}/ifcm-invest-landing?utm_campaign=forexGrad-website`} target='_blank'>
                                <img src="https://www.ifcm.co.uk:2083/images/banners/invest-FA.png"/>
                            </a>
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        language: store.language
    };
};

export default translate("common")(connect(mapStateToProps)(RightBanner));