import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import { connect } from "react-redux";
import {AiOutlineArrowUp} from "react-icons/ai";
import {AiOutlineArrowDown} from "react-icons/ai";

class PriceHistoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;

    return (
      <tr>
        <td>{data.ctm.slice(0, 10)}</td>
        <td>{data.open}</td>
        <td>{data.high}</td>
        <td>{data.low}</td>
        <td> {data.close}</td>
          <td style={((100 * (data.open - data.close)) / data.open).toFixed(2) > 0 ? {color : 'green'} : {color : 'red'}}>
              { ((100 * (data.open - data.close)) / data.open).toFixed(2) > 0 ?
                  <AiOutlineArrowUp color = 'green' />:  <AiOutlineArrowDown color = 'red' />}
              {((100 * (data.open - data.close)) / data.open).toFixed(2) > 0
                  ? ((100 * (data.open - data.close)) / data.open).toFixed(2)
                  : ((100 * (data.open - data.close)) / data.open).toFixed(2) * -1}
              %
          </td>
      </tr>
    );
  }
}
const mapStateToProps = store => {
  return {
    selectInstruments: store.selectInstruments
  };
};

export default connect(mapStateToProps)(PriceHistoryItem);
