import {
  GET_INSTRUMENTS_LIST_SUCCESS,
  GET_INSTRUMENTS_LIST_FAILED,
  GET_LAST_MARKET_OVERVIEW_SUCCESS,
  GET_LAST_MARKET_OVERVIEW_FAILED,
  GET_LAST_VIDEO_SUCCESS,
  GET_LAST_VIDEO_FAILED,
  GET_TECH_ANALYS_LIST_SUCCESS,
  GET_TECH_ANALYS_LIST_FAILED,
  LANGUAGE_CHANGE,
  LIVE_QUOTE_CHANGE_SUCESSED,
  GET_BROKER_LIST_SUCCESS,
  GET_BROKER_LIST_FAILED,
  GET_PLATFORM_LIST_SUCCESS,
  GET_PLATFORM_LIST_FAILED,
  GET_BROKERS_FILTER_SUCCESS,
  ATTEMPT_GET_BROKER_PAGE_SUCCESS,
  ATTEMPT_GET_BROKER_PAGE_FAILED,
  CLEAR_BROKER_INFO,
  GET_TOP_TRADES_SUCCESS,
  GET_TOP_TRADES_FAILED,
  CLEAR_GROUP_INFO,
  SELECT_INSTRUMENTS_LIST_SUCCESS,
  SELECT_INSTRUMENTS_LIST_FAILED,
  GET_TOP_PROMOTIONS_FAILED,
  GET_TOP_PROMOTIONS_SUCCESS,
  GET_LATEST_REVIEWS_FAILED,
  GET_LATEST_REVIEWS_SUCCESS,
  ATTEMPT_GET_PLATFORM_DETAILS_SUCCESS,
  ATTEMPT_GET_PLATFORM_DETAILS_FAILED,
  GET_VIDEO_LIST_SUCCESS,
  CLEAR_PLATFORM_DETAILS_INFO,
  GET_MARKET_OVERVIEW_LIST_SUCCESS,
  GET_MARKET_OVERVIEW_LIST_FAILED,
  CLEAR_MARKET_OVERVIEW_DETAILS_INFO,
  GET_MARKET_OVERVIEW_DETAILS_SUCCESS,
  GET_MARKET_OVERVIEW_DETAILS_FAILED,
  GET_TECH_ANALYS_DETAILS_FAILED,
  GET_TECH_ANALYS_DETAILS_SUCCESS,
  GET_TECH_ARTICLE_LIST_SUCCESS,
  GET_TECH_ARTICLE_LIST_FAILED,
  CLEAR_ARTICLE_DETAILS_INFO,
  GET_TECH_ARTICLE_DETAILS_SUCCESS,
  GET_TECH_ARTICLE_DETAILS_FAILED,
  CLEAR_ARTICLE_LIST,
  CLEAR_ARTICLE,
  GET_SYMBOL_QUOTE_DATA_SUCCESS,
  CHANGE_INSTRNAME,
  CHANGE_INSTRGROUP,
  GET_GROUP_INSTRUMENTS_SUCCESS,
  GET_GROUP_INSTRUMENTS_FAILED,
  SET_STOCK_EXCHANGE_ARRAY,
  SET_SELECTOR_ARRAY,
  GET_PERIOD_NAME,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_FAILED,
  GET_VIDEO_TRADERS_SUCCESS,
  GET_VIDEO_TRADERS_FAILED,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILED,
  GET_QUESTION_PAGE_SUCCESS,
  GET_QUESTION_PAGE_FAILED,
  GET_BROKE_QUESTION_SUCCESS
} from "../actions/indexActions";

const initialState = {
  instruments: [],
  article: null,
  articleDetails: null,
  techAnalysDetails: null,
  marketOverviewList: [],
  videoList: null,
  topPromotions: null,
  selectInstruments: [],
  latestReviews: [],
  groupData: null,
  brokers: null,
  brokerPage: null,
  videos: null,
  videoTraders: null,
  questions: null,
  questionDetails: null,
  platformDetails: null,
  MarketOverviewDetails: null,
  platform: null,
  brokersLang: [],
  instrName: { value: "AUDUSD", label: "AUDUSD" },
  instrGroup: { value: "forex", label: "forex" },
  chartInstrument: [],
  currencyDescriotion: null,
  newQuoteHistory: null,
  stockExchangeArray: [],
  selectorArray: [],
  periodName: "Forex",
  brokerQuestion: null,
  newQuoteData: null,

  live_quote_0: {
    name: "EURUSD",
    value: "---",
    desc: "--- / ---"
  },
  live_quote_1: {
    name: "GBPUSD",
    value: "---",
    desc: "--- / ---"
  },
  live_quote_2: {
    name: "USDJPY",
    value: "---",
    desc: "--- / ---"
  },
  live_quote_3: {
    name: "#S-GILD",
    value: "---",
    desc: "--- / ---"
  },
  live_quote_4: {
    name: "#C-BRENT",
    value: "---",
    desc: "--- / ---"
  },
  //language: localStorage.getItem("language") || "en"
  language: "en"
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BROKE_QUESTION_SUCCESS:
      return {
        ...state,
        brokerQuestion: payload.brokerQuestion
      };
    case GET_QUESTION_PAGE_SUCCESS:
      return {
        ...state,
        questionDetails: payload.questionDetails
      };
    case GET_QUESTION_PAGE_FAILED:
      return {
        ...state,
        globalError: payload.error
      };
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        questions: payload.questions
      };
    case GET_QUESTION_FAILED:
      return {
        ...state,
        globalError: payload.error
      };
    case GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: payload.videos
      };
    case GET_VIDEOS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };
    case GET_VIDEO_TRADERS_SUCCESS:
      return {
        ...state,
        videoTraders: payload.videoTraders
      };
    case GET_VIDEO_TRADERS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };
    case GET_INSTRUMENTS_LIST_SUCCESS:
      return {
        ...state,
        instruments: payload.instruments
      };

    case GET_INSTRUMENTS_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };
    case SELECT_INSTRUMENTS_LIST_SUCCESS:
      return {
        ...state,
        selectInstruments: payload.selectInstruments
      };

    case SELECT_INSTRUMENTS_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_BROKER_LIST_SUCCESS:
      return {
        ...state,
        brokers: payload.brokers
      };

    case GET_BROKER_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case ATTEMPT_GET_BROKER_PAGE_SUCCESS:
      return {
        ...state,
        brokerPage: payload.brokerPage
      };

    case ATTEMPT_GET_BROKER_PAGE_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_LAST_MARKET_OVERVIEW_SUCCESS:
      return {
        ...state,
        marketOverview: payload.marketOverview
      };

    case GET_LAST_MARKET_OVERVIEW_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_MARKET_OVERVIEW_LIST_SUCCESS:
      return {
        ...state,
        marketOverviewList: payload.marketOverviewList
      };

    case GET_MARKET_OVERVIEW_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_MARKET_OVERVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        MarketOverviewDetails: payload.MarketOverviewDetails
      };

    case GET_MARKET_OVERVIEW_DETAILS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_LAST_VIDEO_SUCCESS:
      return {
        ...state,
        video: payload.video
      };

    case GET_VIDEO_LIST_SUCCESS:
      return {
        ...state,
        videoList: payload.videoList
      };

    case GET_TOP_TRADES_SUCCESS:
      return {
        ...state,
        groupData: payload.groupData
      };

    case GET_TOP_TRADES_FAILED:
      return {
        ...state,
        groupData: payload.error
      };

    case GET_BROKERS_FILTER_SUCCESS:
      return {
        ...state,
        brokersLang: payload.brokersLang
      };

    case GET_LAST_VIDEO_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_TECH_ANALYS_LIST_SUCCESS:
      return {
        ...state,
        techAnalys: payload.techAnalys
      };

    case GET_TECH_ANALYS_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_TECH_ANALYS_DETAILS_SUCCESS:
      return {
        ...state,
        techAnalysDetails: payload.techAnalysDetails
      };

    case GET_TECH_ANALYS_DETAILS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_TECH_ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        article: payload.article
      };

    case GET_TECH_ARTICLE_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_TECH_ARTICLE_DETAILS_SUCCESS:
      return {
        ...state,
        articleDetails: payload.articleDetails
      };

    case GET_TECH_ARTICLE_DETAILS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_PLATFORM_LIST_SUCCESS:
      return {
        ...state,
        platform: payload.platform
      };

    case GET_PLATFORM_LIST_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case ATTEMPT_GET_PLATFORM_DETAILS_SUCCESS:
      return {
        ...state,
        platformDetails: payload.platformDetails
      };

    case ATTEMPT_GET_PLATFORM_DETAILS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_TOP_PROMOTIONS_SUCCESS:
      return {
        ...state,
        topPromotions: payload.topPromotions
      };

    case GET_TOP_PROMOTIONS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case GET_LATEST_REVIEWS_SUCCESS:
      return {
        ...state,
        latestReviews: payload.latestReviews
      };

    case GET_LATEST_REVIEWS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case CLEAR_BROKER_INFO:
      return {
        ...state,
        brokerPage: null
      };

    case CLEAR_PLATFORM_DETAILS_INFO:
      return {
        ...state,
        platformDetails: null
      };

    case CLEAR_MARKET_OVERVIEW_DETAILS_INFO:
      return {
        ...state,
        MarketOverviewDetails: null
      };

    case CLEAR_ARTICLE_DETAILS_INFO:
      return {
        ...state,
        articleDetails: null
      };

    case CLEAR_GROUP_INFO:
      return {
        ...state,
        groupData: null
      };

    case CLEAR_ARTICLE_LIST:
      return {
        ...state,
        article: null
      };
    case CLEAR_ARTICLE:
      return {
        ...state,
        article: null
      };

    case GET_GROUP_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        chartInstrument: payload.chartInstrument
      };
    case GET_GROUP_INSTRUMENTS_FAILED:
      return {
        ...state,
        globalError: payload.error
      };

    case LANGUAGE_CHANGE:
      return {
        ...state,
        language: payload.language
      };

    case LIVE_QUOTE_CHANGE_SUCESSED:
      return {
        ...state,
        [`live_quote_${payload.index}`]: payload.newQuote
      };
    case GET_SYMBOL_QUOTE_DATA_SUCCESS:
      return {
        ...state,
        newQuoteData: payload.newQuoteData
      };
    case CHANGE_INSTRNAME:
      return {
        ...state,
        instrName: payload.instrName
      };
    case SET_STOCK_EXCHANGE_ARRAY:
      return {
        ...state,
        stockExchangeArray: payload.componentState
      };
    case GET_PERIOD_NAME:
      return {
        ...state,
        periodName: payload.periodName
      };
    case SET_SELECTOR_ARRAY:
      return {
        ...state,
        selectorArray: payload.componentState
      };
    case CHANGE_INSTRGROUP:
      return {
        ...state,
        instrGroup: payload.instrGroup
      };

    default:
      return state;
  }
};
