import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import Videos from "../../containers/videoOvervie/Videos";
import { getMarketOverviewList } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";
import Pagination from "react-js-pagination";
import RightBanner from "../../components/RightBanner";

const scrolling = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const DATA = {
  "gurutrade-news-finance": "GURUTRADE",
  ifc: "ifc",
  "nbc-device": "cnbc",
  "myfxbook-forex-community": "myfxbook",
  "instaforex-news": "instaforex"
};

// import EducationSections from "./MarketOverviewDetail";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class MarketSentiment extends React.Component {
  constructor(props) {
    super(props);
    const path = this.props.history.location.pathname.split("/")[2] || "ifc";
    this.state = {
      rssItems: null,
      brokerName:
        DATA[this.props.history.location.pathname.split("/")[2]] || "ifc",
      news: "gurutrade-news-finance",
      brokerData: { value: path, label: DATA[path] },

      itemsCount: 0,
      activePage: 1,
      page: 1
    };
  }

  getDate = arg => {
    const date = new Date(arg);
    let month = null;
    if (date.getMonth() + 1 < 10) {
      month = "0" + (date.getMonth() + 1);
    } else month = date.getMonth() + 1;
    let day = null;
    if (date.getDate() + 1 < 10) {
      day = "0" + (date.getDate() + 1);
    } else day = date.getDate() + 1;

    return date.getFullYear() + "-" + month + "-" + day;
  };

  handleChangeBroker(name, news) {
    this.setState({ brokerName: name, news });
  }

  attemptGetData(lang, countPerPage, activePage) {
    fetch(
        process.env.REACT_APP_API_URL + `/market-sentiment?lang=${lang}&countPerPage=${countPerPage}&page=${activePage}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data.list, itemsCount: data.count });
      })
      .catch();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    // this.attemptGetData(this.props.language,5, this.state.activePage)
  }

  componentDidMount() {
    this.attemptGetData(this.props.language, 5, this.state.activePage);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.language !== prevProps.language ||
      this.state.activePage !== prevState.activePage
    ) {
      this.attemptGetData(this.props.language, 5, this.state.activePage);
      scrolling();
    }
  }

  render() {
    const { t } = this.props;
    const { rssItems } = this.state;

    return (
      <div>
        <Helmet>
          <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
          <div className="container mb-4 d-flex flex-wrap">
            <div className={`${Global.main_with_left}`}>
              <h1>
                {t("MARKET_SENTIMENT")}
              </h1>
              <div className="bg_wrap">
                {rssItems ? (
                  rssItems.map((item, index) => {
                    return (
                      <div key={index} className={`${Analytics.blockItem}`}>
                          <a
                              className={`${Analytics.title}`}
                            target={"_blank"}
                            rel="noopener noreferrer"
                            href={item.link}
                            // href={item.url}
                          >
                            {item.title}
                          </a>
                          <div className={`${Analytics.textBlock} mt-2`}>
                            {renderHtml(item.description)}
                          </div>
                          <div className="mb-3 d-flex justify-content-between align-items-center">
                            <div className="date_block">{item.date}</div>

                            <div>
                              {t("SOURCE_BY")}: <a href={item.link}>{item.sysname}</a>
                            </div>
                          </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="loader">
                    {t("LOADING")}
                  </h1>
                )}
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={5}
                  totalItemsCount={this.state.itemsCount}
                  pageRangeDisplayed={5}
                  onChange={e => this.handlePageChange(e)}
                />
              </div>

            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className="expel_class">
                <TopBrokers />
              </div>
              <RightBanner/>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    marketOverviewList: store.marketOverviewList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getMarketOverviewList: lang => dispatch(getMarketOverviewList(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketSentiment))
);