import React from "react";
import MarketMoversSelects from "./MarketMoversSelects";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import AllVideos from "../../../containers/videoOvervie/Videos";
import {Helmet} from "react-helmet";
import RightBanner from "../../../components/RightBanner";

class MarketMovers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Helmet>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    <div className="market_movers main_with_left">
                        <h1 className="">
                            {t("TOP_LEADERS_LOSERS")}
                        </h1>
                        <div className="bg_wrap container mb-4">
                            <div className="top_losers_container">
                                <p>
                                    Top Market Gainers and Losers is a unique and powerful
                                    analytical tool, that is able to instantaneously calculate top
                                    market movers. Within a second the tool provides 10 different
                                    trading instruments that showed the highest increase (Top
                                    Gainers) and the biggest decrease (Top Losers) for selected
                                    period of time (from 1 day to 1 year).
                                </p>
                                <p>
                                    You may calculate Gainers and Losers according to the selected
                                    instrument group –{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-stocks">
                                        stocks
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-currencies">
                                        currency pairs
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-precious-metals">
                                        precious metals
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-indices">
                                        indices
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-commodities">
                                        commodities
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-gold-cfds">
                                        gold instruments
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-cfd-etf">
                                        ETF
                                    </a>
                                    ,{" "}
                                    <a href="https://dev.forexgrad.com/market_movers/top-personal-instruments">
                                        PCI library
                                    </a>
                                    .
                                </p>
                                <p>
                                    Additionally, for stock market, you can filter different stock
                                    exchanges as well as industries of your interest.
                                </p>
                            </div>
                            <div className="filterTitle">
                                <h2>{t("TOP_RISERS_AND_FALLERS")} - {this.props.periodName}</h2>
                                <p className="mb30" style={{color: "#707070"}}>
                                    <i>Select the instrument group and calculation parameters:</i>
                                </p>
                            </div>
                            <MarketMoversSelects/>
                        </div>
                        <div className={"txt_wrap"}>
                            <div className="market_mover_container">
                                <div className="container">
                                    <h5>Market Movers Calculation Notes:</h5>
                                    <p>
                                        <i>
                                            For the "Today" range, the initial price is the closing
                                            price of yesterday's session, and the final price is the
                                            current price of an instrument.
                                        </i>
                                    </p>
                                    <p>
                                        <i>
                                            For the "Yesterday" range, the initial price is the closing
                                            price of the day before yesterday's session, and the final
                                            price is the closing price of yesterday's session.
                                        </i>
                                    </p>
                                    <p>
                                        <i>
                                            The calculation prices of other ranges such as week, month,
                                            year etc, are the closing prices of previous completed
                                            trading sessions, counting from the dates of the set period
                                            range.
                                        </i>
                                    </p>
                                    <h2>Interested in Market Analysis?</h2>
                                    <p style={{fontStyle: "normal"}}>
                                        Discover the benefits of IFCM own trading platform -
                                        NetTradeX. You can do much more in depth analysis in real time
                                        and trade more than 500 instruments of your choice.
                                    </p>
                                    <p style={{fontStyle: "normal"}}>
                                        In addition, you can create your own trading instruments -{" "}
                                        <a href="https://dev.forexgrad.com/innovation-in-trading">
                                            Personal Composite Instruments (PCI)
                                        </a>{" "}
                                        and portfolios based on them, make a full Technical analysis
                                        of their charts and trade them as common instruments.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="right_box_block">
                        <div className="expel_class">
                            <AllVideos/>
                        </div>
                        <div className="expel_class">{/*<EducationSections/>*/}</div>
                        <RightBanner/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        language: store.language,
        periodName: store.periodName
    };
};
export default translate("common")(
    connect(mapStateToProps)(withRouter(MarketMovers))
);
