import React from "react";
import { translate } from "react-i18next";
import TopTradesGroup from "./TopTradesGroup";

class TopTradesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      name: ""
    };
  }
  render() {
    const { t } = this.props;
    const content = [
      {
        title: "COMMODITIES",
        component: <TopTradesGroup group="Commodities" />
      },
      {
        title: "INDICES",
        component: <TopTradesGroup group="Indices" />
      },
      {
        title: "PRECIOUS_METALS",
        component: <TopTradesGroup group="Precious-metals" />
      },
      {
        title: "FOREX",
        component: <TopTradesGroup group="Forex" />
      },
      {
        title: "STOCKS",
        component: <TopTradesGroup group="Stocks" />
      },
      {
        title: "ETF_S",
        component: <TopTradesGroup group="Etf_s" />
      },
      {
        title: "BITCOIN",
        component: <TopTradesGroup group="Bitcoin" />
      }
    ];

    return (
      <div className={"main_with_left"}>
        <h5>
          {/*Top Trades*/}
          {t("TOP_TRADES")}
        </h5>
        <div>
          <div className="top_trades tabs_tab">
            <span className="select_cont select_mobile">
              <select onChange={e => this.setState({ name: e.target.value })}>
                {content.map((tab, i) => (
                  <option
                    key={tab.title}
                    className={`${
                      tab.title === this.state.name.toUpperCase()
                        ? "active"
                        : ""
                    }`}
                    onSelect={i => {
                      this.setState({ activeTabIndex: i });
                    }}
                  >
                    {t(tab.title)}
                  </option>
                ))}
              </select>
            </span>

            {content.map((tab, i) => (
              <div
                key={tab.title}
                className={this.state.activeTabIndex === i ? "active" : ""}
                onClick={() => this.setState({ activeTabIndex: i })}
                style={{
                  pointerEvents: `${
                     i === 5 || i === 6 ? "none" : ""
                  }`,
                  opacity: `${ i === 5 || i === 6 ? 0.3 : 1}`
                }}
              >
                {t(tab.title)}
              </div>
            ))}
          </div>
          {content[this.state.activeTabIndex].component}
        </div>
      </div>
    );
  }
}
export default translate("common")(TopTradesTab);
