import React from "react";
import GLBTAB from "./glbTab";
import { translate } from "react-i18next";
import "../assets/css/components/gainers_losers.css";

class GainersLosers extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="top_losers_tables main_half">
        <h5>
          {t("TOP_GAINERS")} / {t("LOSERS")}
        </h5>
        <div className="swipe">
          {t("SWIPE_TABLE")} <span className="icon-right-small"></span>
        </div>
        <GLBTAB />
      </div>
    );
  }
}
export default translate("common")(GainersLosers);
