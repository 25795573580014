import React from "react";
import Global from "../../assets/css/Global.module.css";
import NewsContainer from "../../containers/News";
import AllVideos from "../../containers/videoOvervie/Videos";
import {Helmet} from "react-helmet";

export default class News extends React.Component {
    render() {
        return (
            <div className="container mb-4 d-flex flex-wrap">
                <Helmet>
                </Helmet>
                    <NewsContainer/>
                    <div className={Global.right_box_block}>
                        <AllVideos/>
                    </div>
            </div>
        );
    }
}
