import React from 'react';
import {Helmet} from "react-helmet";
import logo from "../assets/images/not-found.png"

const NotFound = () =>
    <div className="container row">
        <Helmet>
            <title>{"404: This page does not exist | Forexgrad"}</title>
            <meta name="description" content="" />
            <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div class="not-found">
            <div>
            <h1>404 page not found</h1>
                <img src={logo} width='50%' alt={"Not Found"}/>
                <p></p>
            </div>
        </div>
    </div>

export default NotFound;
