import React from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import {
    BROKERS_ABOUT_US,
    BROKERS_ANALYTICS,
    BROKERS_CALENDARS,
    BROKERS_CHARTS,
    BROKERS_EDUCATIONS,
    VIDEO_TRADERS,
    BROKERS_FOREX_TRADING_BOOK,
    BROKERS_FOREX_VIDEO_TUTORIAL,
    BROKERS_ROUTE,
    BROKERS_TRADING_PLATFORMS,
    HOME_PAGE_ROUTE,
    BROKER_PAGE,
    BROKERS_INNOVATION,
    EDUCATION_TRADERS_GLASSARY,
    BROKER_DETAILS_PAGE,
    ARTICLES,
    PLATFORM_DETAILS_PAGE,
    BROKERS_FOREX_VIDEO_OVERVIEW,
    MARKET_OVERVIEW_LIST,
    MARKET_OVERVIEW_DETAILS,
    BROKERS_ANALYTICS_DETAILS,
    ARTICLES_DETAIL,
    BROKERS_CHARTS_CUR,
    MARKET_SENTIMENT,
    MARKET_MOVERS,
    MARKET_OVERVIEW,
    MARKET_COMMODITY_CALENDAR,
    QUESTION_PAGE,
    QUESTIONS,
    RSS_1,
    RSS_2,
    RSS_3,
    RSS_4,
    RSS_5,
    RSS_6,
    RSS_7,
    RSS_8,
    RSS_9,
    RSS_10,
    RSS_11,
    RSS_12,
    RSS_13,
    RSS_14,
    RSS_15,
    RSS_16,
    RSS_18,
    RSS_17,
    RSS_19,
    OUR_ARTICLES_DETAIL,
    ROBOTS,
    TECHNICAL_SUMMARIES,
    MAIN_ROUTE,
    ADVISER,
    ADVISER_MAKER,
    ADVISERS,
    BUY_ADVISER,
    ADVISER_INSTRUCTION,
    BUY_ADVISER_MAKER,
    ADVISER_FAILED_PAY,ADVISER_SUCCESSFULL_PAY,ADVISER_NOTE_PAY,ADVISER_FAILED_PAY_MAKER,ADVISER_SUCCESSFULL_PAY_MAKER,ADVISER_NOTE_PAY_MAKER,
    ADVISERS_MAKER,
    ADVISER_INSTRUCTION_MAKER,
    HOME_PAGE_ROUTE_MAKER,
    BROKERS_ABOUT_US_MAKER,
    BROKERS_CHARTS_MAKER,
    ROBOTS_MAKER,
    BROKERS_ROUTE_MAKER,
    BROKERS_TRADING_PLATFORMS_MAKER,
    BROKERS_CALENDARS_MAKER,
    VIDEO_TRADERS_MAKER,
    BROKERS_FOREX_TRADING_BOOK_MAKER,
    BROKERS_FOREX_VIDEO_TUTORIAL_MAKER,
    BROKERS_FOREX_VIDEO_OVERVIEW_MAKER,
    BROKER_PAGE_MAKER,
    EDUCATION_TRADERS_GLASSARY_MAKER,
    ARTICLES_MAKER,
    QUESTIONS_MAKER,
    MARKET_OVERVIEW_LIST_MAKER,
    BROKERS_ANALYTICS_MAKER,
    MARKET_SENTIMENT_MAKER,
    MARKET_COMMODITY_CALENDAR_MAKER,
    MARKET_MOVERS_MAKER,
    MARKET_OVERVIEW_MAKER,
    TECHNICAL_SUMMARIES_MAKER,
    RSS_1_MAKER,
    RSS_2_MAKER,
    RSS_3_MAKER,
    RSS_4_MAKER,
    RSS_5_MAKER,
    RSS_9_MAKER,
    RSS_10_MAKER,
    RSS_15_MAKER,
    RSS_16_MAKER,
    RSS_6_MAKER,
    RSS_7_MAKER,
    RSS_8_MAKER,
    RSS_11_MAKER,
    RSS_12_MAKER, RSS_13_MAKER, RSS_14_MAKER, RSS_17_MAKER, RSS_18_MAKER, RSS_19_MAKER
} from "./constants";
import Content from "../components/content/Index";
import Robots from "../components/Robots";
import AboutUs from "../components/AboutUs";
import Broker from "../components/Broker";
import TradingPlatform from "../components/TradingPlatform";
import Educations from "../containers/Educations";
import Questions from "../containers/Questions";
import QuestionPage from "../containers/QuestionPage";
import ForexTradingBooks from "../containers/education/ForexTradingBooks";
import ForexVideoTutorial from "../containers/education/ForexVideoTutorial";
import LiveChart from "../containers/charts";
import AllEconomicCalendar from "../containers/AllEconomicCalendar";
import BrokerPage from "../containers/brokers/BrokerPage";
import Innovations from "../containers/innovations/Innovations";
import EducationTradersGlossary from "../containers/EducationTradersGlossary";
import Articles from "../containers/articles/Articles";
import {connect} from "react-redux";
import TradingPlatformDetails from "../containers/TradingPlatformDetails";
import VideoOverview from "../containers/videoOvervie/VideoOverview";
import MarketOverViewList from "../containers/marketOverview/MarketOverViewList";
import MarketOverviewDetail from "../containers/marketOverview/MarketOverviewDetail";
import TechnicalAnalysisDetails from "../containers/analysis/TechnicalAnalysisDetails";
import AllArticles from "../containers/articles/AllArticles";
import MarketSentiment from "../containers/analytics/MarketSentiment";
import CommodityMarketCalendar from "../containers/analytics/CommodityMarketCalendar";
import MarketMovers from "../containers/analytics/MarketMovers/MarketMovers";
import TechnicalSummaries from "../containers/analytics/TechnicalSummaries";
import MarketOverview from "../containers/analytics/MarketOverview";
import RssParser from "../containers/rss/RssParser";
import {images} from "../containers/rss/helper";
import RssParserWithImage from "../containers/rss/RssParserWithImage";
import RssParserWithImageClick from "../containers/rss/RssParserWithImageClick";
import RssParserExpert from "../containers/rss/RssParserExpert";
import TechnicalAnalysisRss from "../containers/analysis/TechnicalAnalysisRss";
import ArticlesRss from "../containers/articles/ArticlesRss";
import OurArticles from "../containers/articles/OurArticles";
import NotFound from "../components/NotFound"
import VideoTrader from "../containers/VideoTrader";
import Advisers from "../containers/Advisers";
import Adviser from "../containers/Adviser";
import buyAdviser from "../containers/buyAdviser";
import AdviserInstruction from "../containers/AdviserInstruction";

class Routes extends React.Component {

    render() {
        return (
            <Switch>
                <Route exact path={MAIN_ROUTE} component={Content}/>
                <Route exact path={BROKERS_ABOUT_US} component={AboutUs}/>
                <Redirect exact from="/about-us" to={BROKERS_ABOUT_US_MAKER(this.props.language)}/>
                <Route exact path={HOME_PAGE_ROUTE} component={Content}/>
                <Redirect exact from="/" to={HOME_PAGE_ROUTE_MAKER(this.props.language)}/>
                <Route exact path={BROKERS_ROUTE} component={Broker}/>
                <Redirect exact from="/brokers-ratings" to={BROKERS_ROUTE_MAKER(this.props.language)}/>
                <Route path={BROKER_DETAILS_PAGE} component={BrokerPage}/>
                <Redirect from='/brokers-ratings/:sysname' to={`/${this.props.language}/brokers-ratings/:sysname`}/>
                <Route
                    exact
                    path={PLATFORM_DETAILS_PAGE}
                    component={TradingPlatformDetails}
                />
                <Redirect from='/trading-platforms/:sysname' to={`/${this.props.language}/trading-platforms/:sysname`}/>
                <Route
                    path={BROKERS_CALENDARS}
                    component={() => (
                        <AllEconomicCalendar
                            width={"widthcalendarTab"}
                            heigth={"heigthNewTab"}
                        />
                    )}
                />
                <Redirect from='/economic-calendar' to={BROKERS_CALENDARS_MAKER(this.props.language)}/>
                <Route path={BROKERS_TRADING_PLATFORMS} component={TradingPlatform}/>
                <Redirect from='/trading-platforms' to={BROKERS_TRADING_PLATFORMS_MAKER(this.props.language)}/>
                <Route path={BROKERS_EDUCATIONS} component={Educations}/>
                <Route path={VIDEO_TRADERS} component={VideoTrader}/>
                <Redirect from='/video-traders' to={VIDEO_TRADERS_MAKER(this.props.language)}/>
                <Route
                    path={EDUCATION_TRADERS_GLASSARY}
                    component={EducationTradersGlossary}
                />
                <Redirect from='/traders_glossary' to={EDUCATION_TRADERS_GLASSARY_MAKER(this.props.language)}/>
                <Route path={BROKERS_FOREX_TRADING_BOOK} component={ForexTradingBooks}/>
                <Redirect from='/forex-trading-books' to={BROKERS_FOREX_TRADING_BOOK_MAKER(this.props.language)}/>
                <Route
                    path={BROKERS_FOREX_VIDEO_TUTORIAL}
                    component={ForexVideoTutorial}
                />
                <Redirect from='/forex-video-tutorials' to={BROKERS_FOREX_VIDEO_TUTORIAL_MAKER(this.props.language)}/>
                <Route path={BROKERS_FOREX_VIDEO_OVERVIEW} component={VideoOverview}/>
                <Redirect from='/forex-video-overview' to={BROKERS_FOREX_VIDEO_OVERVIEW_MAKER(this.props.language)}/>
                <Route exact path={BROKERS_CHARTS} component={LiveChart}/>
                <Redirect exact from="/live-charts" to={BROKERS_CHARTS_MAKER(this.props.language)}/>
                <Route exact path={BROKERS_CHARTS_CUR} component={LiveChart}/>
                <Redirect exact from="/live-charts/:type/:cur" to={`/${this.props.language}/live-charts/:type/:cur`}/>
                <Route path={BROKER_PAGE} component={BrokerPage}/>
                <Redirect exact from="/broker_page" to={BROKER_PAGE_MAKER(this.props.language)}/>
                <Route exact path={BROKERS_INNOVATION} component={Innovations}/>
                <Redirect exact from="/innovation-in-trading" to={BROKERS_CHARTS_MAKER(this.props.language)}/>
                <Route exact path={ARTICLES} component={AllArticles}/>
                <Redirect exact from="/trading-articles" to={ARTICLES_MAKER(this.props.language)}/>
                <Route path={ARTICLES_DETAIL} component={Articles}/>
                <Redirect exact from="/trading-articles/:slug" to={`/${this.props.language}/trading-articles/:slug`}/>
                <Route path={OUR_ARTICLES_DETAIL} component={OurArticles}/>
                <Redirect exact from="/trading-article/:slug" to={`/${this.props.language}/trading-article/:slug`}/>
                <Route exact path={ROBOTS} component={Robots}/>
                <Redirect exact from="/robots.txt" to={ROBOTS_MAKER(this.props.language)}/>
                <Route
                    exact
                    path={BROKERS_ANALYTICS}
                    component={TechnicalAnalysisRss}
                />
                <Redirect exact from="/analytics" to={BROKERS_ANALYTICS_MAKER(this.props.language)}/>
                <Route
                    exact
                    path={MARKET_COMMODITY_CALENDAR}
                    component={CommodityMarketCalendar}
                />
                <Redirect exact from="/trade-calendar" to={MARKET_COMMODITY_CALENDAR_MAKER(this.props.language)}/>
                <Route path={MARKET_MOVERS} component={MarketMovers}/>
                <Redirect exact from="/market-movers" to={MARKET_MOVERS_MAKER(this.props.language)}/>
                <Route path={TECHNICAL_SUMMARIES} component={TechnicalSummaries}/>
                <Redirect exact from="/technical-summaries" to={TECHNICAL_SUMMARIES_MAKER(this.props.language)}/>
                <Route
                    exact
                    path={BROKERS_ANALYTICS_DETAILS}
                    component={TechnicalAnalysisDetails}
                />
                <Redirect exact from="/analytics/:instrName/:date" to={`/${this.props.language}/analytics/:instrName/:date`}/>

                <Route path={MARKET_OVERVIEW_LIST} component={MarketOverViewList}/>
                <Redirect from='/financial-news' to={MARKET_OVERVIEW_LIST_MAKER(this.props.language)}/>
                <Route
                    path={MARKET_OVERVIEW_DETAILS}
                    component={MarketOverviewDetail}
                />
                <Redirect from='/financial-news/:slug' to={`/${this.props.language}/financial-news/:slug`}/>
                <Route
                    path={RSS_1}
                    component={() => <RssParser currentPage="economictimes-forex"/>}
                />
                <Redirect from='/rss1' to={RSS_1_MAKER(this.props.language)}/>
                <Route
                    path={RSS_2}
                    component={() => (
                        <RssParser
                            currentPage="myfxbook-forex-news"
                        />
                    )}
                />
                <Redirect from='/rss2' to={RSS_2_MAKER(this.props.language)}/>
                <Route
                    path={RSS_3}
                    component={() => (
                        <RssParser
                            images={images}
                            currentPage="gurutrade-fundamental-analysis"
                        />
                    )}
                />
                <Redirect from='/rss3' to={RSS_3_MAKER(this.props.language)}/>
                <Route
                    path={RSS_4}
                    component={() => (
                        <RssParser images={images} currentPage="cnbc-device"/>
                    )}
                />
                <Redirect from='/rss4' to={RSS_4_MAKER(this.props.language)}/>
                <Route
                    path={RSS_5}
                    component={() => (
                        <RssParser
                            currentPage="tradingfloor-steen-jakobsen"
                        />
                    )}
                />
                <Redirect from='/rss5' to={RSS_5_MAKER(this.props.language)}/>
                <Route
                    path={RSS_9}
                    component={() => (
                        <RssParser
                            currentPage="tradingfloor-john-j-hardy"
                        />
                    )}
                />
                <Redirect from='/rss9' to={RSS_9_MAKER(this.props.language)}/>
                <Route
                    path={RSS_10}
                    component={() => (
                        <RssParser
                            currentPage="tradingfloor-ole-hansen"
                        />
                    )}
                />
                <Redirect from='/rss10' to={RSS_10_MAKER(this.props.language)}/>
                <Route
                    path={RSS_15}
                    component={() => (
                        <RssParser
                            currentPage="tradingfloor-tv-rss"
                        />
                    )}
                />
                <Redirect from='/rss15' to={RSS_15_MAKER(this.props.language)}/>
                <Route
                    path={RSS_16}
                    component={() => (
                        <RssParser
                            currentPage="tradingfloor-tv-atom"
                        />
                    )}
                />
                <Redirect from='/rss16' to={RSS_16_MAKER(this.props.language)}/>

                <Route
                    path={RSS_6}
                    component={() => (
                        <RssParser
                            currentPage="instaforex-news"
                        />
                    )}
                />
                <Redirect from='/rss6' to={RSS_6_MAKER(this.props.language)}/>
                <Route
                    path={RSS_7}
                    component={() => (
                        <RssParser
                            currentPage="instaforex-analytics"
                        />
                    )}
                />
                <Redirect from='/rss7' to={RSS_7_MAKER(this.props.language)}/>
                <Route
                    path={RSS_8}
                    component={() => (
                        <RssParser
                            currentPage="myfxbook-forex-community"
                        />
                    )}
                />
                <Redirect from='/rss8' to={RSS_8_MAKER(this.props.language)}/>
                <Route
                    path={RSS_11}
                    component={() => (
                        <RssParserWithImageClick
                            currentPage="orbex-technical-analysis"
                        />
                    )}
                />
                <Redirect from='/rss11' to={RSS_11_MAKER(this.props.language)}/>
                <Route
                    path={RSS_12}
                    component={() => (
                        <RssParserWithImage
                            currentPage="orbex-fundamental-analysis"
                        />
                    )}
                />
                <Redirect from='/rss12' to={RSS_12_MAKER(this.props.language)}/>
                <Route
                    path={RSS_13}
                    component={() => (
                        <RssParser
                            currentPage="liteforex-trading"
                        />
                    )}
                />
                <Redirect from='/rss13' to={RSS_13_MAKER(this.props.language)}/>
                <Route
                    path={RSS_14}
                    component={() => (
                        <RssParser
                            currentPage="freshforex-analitics"
                        />
                    )}
                />
                <Redirect from='/rss14' to={RSS_14_MAKER(this.props.language)}/>
                <Route
                    path={RSS_17}
                    component={() => (
                        <RssParser
                            currentPage="ezinearticle-category"
                        />
                    )}
                />
                <Redirect from='/rss17' to={RSS_17_MAKER(this.props.language)}/>
                <Route
                    path={RSS_18}
                    component={() => (
                        <RssParserExpert
                            currentPage="ezinearticles-Ahsan-Khan"
                        />
                    )}
                />
                <Redirect from='/rss18' to={RSS_18_MAKER(this.props.language)}/>
                <Route
                    path={RSS_19}
                    component={() => (
                        <RssParserExpert
                            currentPage="ezinearticles-Lance-Winslow"
                        />
                    )}
                />
                <Redirect from='/rss19' to={RSS_19_MAKER(this.props.language)}/>
                <Route path={MARKET_SENTIMENT} component={MarketSentiment}/>
                <Redirect exact from="/markets-sentiment" to={MARKET_SENTIMENT_MAKER(this.props.language)}/>
                <Route path={MARKET_OVERVIEW} component={MarketOverview}/>
                <Redirect exact from="/market-overview" to={MARKET_OVERVIEW_MAKER(this.props.language)}/>
                <Route path={ARTICLES} component={ArticlesRss}/>
                <Route exact path={QUESTIONS} component={Questions}/>
                <Redirect exact from="/questions" to={QUESTIONS_MAKER(this.props.language)}/>
                <Route exact path={QUESTION_PAGE} component={QuestionPage}/>
                <Redirect exact from="/question-page/:slug" to={`/${this.props.language}/question-page/:slug`}/>
                <Route exact path={ADVISERS} component={Advisers}/>
                <Redirect exact from="/advisers" to={ADVISERS_MAKER(this.props.language)}/>
                <Route exact path={ADVISER} component={Adviser}/>
                <Redirect from='/advisor/:slug' to={`/${this.props.language}/advisor/:slug`}/>
                <Route exact path={BUY_ADVISER} component={buyAdviser}/>
                <Redirect from='/buy-advisor/:slug' to={`/${this.props.language}/buy-advisor/:slug`}/>
                <Route exact path={ADVISER_INSTRUCTION} component={AdviserInstruction}/>
                <Redirect exact from="/advisor-instruction" to={ADVISER_INSTRUCTION_MAKER(this.props.language)}/>
                <Route exact path={ADVISER_SUCCESSFULL_PAY}/>
                <Redirect exact from="/advisors/successful-payment" to={ADVISER_SUCCESSFULL_PAY_MAKER(this.props.language)}/>
                <Route exact path={ADVISER_FAILED_PAY}/>
                <Redirect exact from="/advisors/failed-payment" to={ADVISER_FAILED_PAY_MAKER(this.props.language)}/>
                <Route exact path={ADVISER_NOTE_PAY}/>
                <Redirect exact from="/advisors/notifications" to={ADVISER_NOTE_PAY_MAKER(this.props.language)}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }
}

export default connect(
    state => ({
        language: state.language

    })
)(translate('common')(withRouter(Routes)))
