import React from "react";
import "./../assets/css/components/trading_platforms.css";
import "./../assets/css/components/video_lessons.css";
import Global from "../assets/css/Global.module.css";
import TopBrokers from "../containers/TopBrokers";
import {attemptGetVideos} from "../redux/actions/indexActions";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import RightBanner from "../components/RightBanner";
import EducationTabs from "../components/content/EducationTabs";

class Educations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            video: null,
            isVideoModalOpened: false,
            videoId: null
        };
    }

    componentDidMount() {
        this.props.attemptGetVideos(this.props.language,1);
        if (this.props.videos) {
            this.setState({video: this.props.videos});
        }
    };


    render() {
        const {videos} = this.props;
        const {t} = this.props;
        return (
            <div className="d-flex flex-wrap">
                <div className="container mb-4 d-flex flex-wrap">
                    <div className="video_less_wrap main_with_left">
                        <h1>{t("EDUCATION")}</h1>
                        {<EducationTabs />}
                        <div className={`d-flex flex-wrap ${Global.bg_wrap}`}>
                            {videos ? videos.map((item, index) => {
                                return (
                                    <div key={index} className="video_cont_blocks education-video">
                                        <div className={""}>
                                            <div
                                                className={`video_les ${item.broker}`}
                                                onClick={() => {
                                                    this.setState({isVideoModalOpened: true});
                                                    this.state.videoId = item.link
                                                }}
                                            >
                                                <img alt={item.title} width={320} height={"auto"} src={`https://img.youtube.com/vi/${item.link}/0.jpg`}/>
                                                <div className={Global.yout_video_icn}>
                                                    <div/>
                                                </div>
                                            </div>
                                            <ModalVideo
                                                channel="youtube"
                                                isOpen={this.state.isVideoModalOpened}
                                                videoId={this.state.videoId}
                                                onClose={() => this.setState({isVideoModalOpened: false})}
                                            />
                                        </div>

                                        <div className="vid_cont_block_txt p-0">
                                            <div className="video_less_title">{item.title}</div>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                );
                            }) : ""}
                        </div>
                    </div>
                    <div className={Global.right_box_block}>
                        {/*<div className={Global.expel_class}>*/}
                        {/*<EducationSections/>*/}
                        {/*</div>*/}
                        <div className={Global.expel_class}>
                            <TopBrokers/>
                        </div>
                        <RightBanner/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        language: store.language,
        videos: store.videos,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        attemptGetVideos: (lang,broker) =>
            dispatch(attemptGetVideos(lang,broker)),
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(Educations)
);
