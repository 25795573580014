import React from "react";
import btn_nav from "../../assets/css/components/ButtonNav.module.css";
import { translate } from "react-i18next";

class LangMenu extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <a
        className={btn_nav.button_green}
        href={
          "https://www.ifcmarkets.com/en/open-trading-account?utm_campaign=forexGrad-website"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>{t("START_TRADING_1")}</div>
        <div>{t("RECOMMENDED_BROKER")}</div>
        {/*<div>with recommended broker**</div>*/}
      </a>
    );
  }
}

export default translate("common")(LangMenu);
