import React from "react";
import "./../../assets/css/components/menu.css";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {onLanguageChange} from "../../redux/actions/indexActions";
import {translate} from "react-i18next";
import {Helmet} from "react-helmet";

class LangMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            cur: 0,
            show: "none",
            show2: "none",
            // common: "en",
            data: [
                {
                    name: "United Kingdom",
                    lang: "flag_24 en",
                    href: "/",
                    common: "en"
                }
                // {
                //     name: "Iran",
                //     lang: "flag_24 fa",
                //     // href: "/${this.props.language}",
                //     href: "/",
                //     common: "fa"
                // }
            ]
        };
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();
        this.setState({showMenu: true}, () => {
            document.addEventListener("click", this.closeMenu);
        });
    }

    closeMenu() {
        this.setState({showMenu: false}, () => {
            document.removeEventListener("click", this.closeMenu);
        });
    }

    hydrateStateWithLocalStorage() {
        // for all items in state
        for (let key in this.state) {
            // if the key exists in localStorage
            if (localStorage.hasOwnProperty(key)) {
                // get the key's value from localStorage
                let value = localStorage.getItem(key);
                // parse the localStorage string and setState
                try {
                    value = JSON.parse(value);
                    this.setState({[key]: value});
                } catch (e) {
                    // handle empty string
                    this.setState({[key]: value});
                }
            }
        }
    }


    componentDidMount() {
        this.hydrateStateWithLocalStorage();
        this.checkIfLangChanged();
    }

    checkIfLangChanged() {
        document.body.dir = `${this.props.language === 'fa' ? 'rtl' : "ltr"}`;
        if (this.props.location.pathname !== "/") {
            const availableLangs = ['en'];
            const pathArray = this.props.location.pathname.split("/");
            let language = pathArray[1];
            if (!availableLangs.includes(language)) {
                language = 'en'
            }
            if (language !== this.props.language) {
                localStorage.setItem("language", language);
                this.setState({common: language});
                this.props.onLanguageChange(language)
                this.props.i18n.changeLanguage(language);
            }
        }
    }

    render() {
        const {language, i18n} = this.props;
        const langList = i18n.options.resources
        let withoutLang = this.props.location.pathname.replace(`/${language}/`, '');
        if (withoutLang === '/fa' || withoutLang === '/en') {
            withoutLang = '';
        }

        return (
            <div/>
            // <div className="lang_menu">
            //     <ul>
            //         <li className="flag" onClick={this.showMenu}>
            //             <span className={`flag_24 ${language}`}/>
            //             <span className="flag_text">
            //   {localStorage.getItem("language") || "en"}
            // </span>
            //             <span className="flag_arrow"/>
            //
            //             {this.state.showMenu ? (
            //                 <div className="flag_none flex-column" style={{display: "flex"}}>
            //                     {this.state.data.map((item, index) => {
            //                         return (
            //                             <div className=" " key={index}>
            //                                 <a
            //                                     href={item.href}
            //                                     className="local_href"
            //                                     onClick={() => {
            //                                         this.props.onLanguageChange(item.common);
            //                                         i18n.changeLanguage(item.common);
            //                                         document.body.dir = `${
            //                                             item.common === "fa" ? "rtl" : "ltr"
            //                                         }`;
            //                                         this.setState({cur: index, common: item.common});
            //                                         // localStorage.setItem (index, index);
            //                                         localStorage.setItem("cur", JSON.stringify(index));
            //                                         localStorage.setItem("language", item.common);
            //                                         // localStorage.setItem("common", JSON.stringify(index));
            //                                     }}
            //                                 >
            //                                     <span className={item.lang}/>
            //                                     {item.name}
            //                                 </a>
            //                             </div>
            //                         );
            //                     })}
            //                 </div>
            //             ) : null}
            //         </li>
            //     </ul>
            //     <Helmet>
            //         {Object.keys(langList).map((item, index) => {
            //             return (
            //                 <link rel="alternate" hrefLang={item}
            //                       href={'https://www.forexgrad.com/' + item + (withoutLang ? '/' + withoutLang : '')}/>
            //             );
            //         })}
            //         <link rel='canonical'
            //               href={'https://www.forexgrad.com/' + language + (withoutLang ? '/' + withoutLang : '')}/>
            //     </Helmet>
            //
            //
            // </div>
        );
    }
}

export default connect(
    state => ({
        language: state.language
    }),
    dispatch => ({
        onLanguageChange: language => dispatch(onLanguageChange(language))
    })
)(translate("common")(withRouter(LangMenu)));
