export const MAIN_ROUTE = "/";
export const HOME_PAGE_ROUTE = "/:language";
export const HOME_PAGE_ROUTE_MAKER = (language) =>
    `/${language}`;
export const BROKERS_ABOUT_US = "/:language/about-us";
export const BROKERS_ABOUT_US_MAKER = (language) => `/${language}/about-us`;
export const BROKERS_CHARTS = "/:language/live-charts";
export const BROKERS_CHARTS_MAKER =  (language) =>`/${language}/live-charts`;
export const ROBOTS = "/:language/robots.txt";
export const ROBOTS_MAKER = (language) =>`/${language}/robots.txt`;
export const BROKERS_CHARTS_CUR = "/:language/live-charts/:type/:cur";
export const BROKERS_CHARTS_CUR_CREATOR = (language,type, cur) => `/${language}/live-charts/${type}/${cur}`;
export const BROKERS_ROUTE = "/:language/brokers-ratings";
export const BROKERS_ROUTE_MAKER = (language) =>`/${language}/brokers-ratings`;
export const BROKER_DETAILS_PAGE = "/:language/brokers-ratings/:sysname";
export const BROKER_DETAILS_PAGE_CREATOR =  (language,sysname) => `/${language}/brokers-ratings/${sysname}`;
export const BROKERS_TRADING_PLATFORMS = "/:language/trading-platforms";
export const BROKERS_TRADING_PLATFORMS_MAKER = (language) =>`/${language}/trading-platforms`;
export const BROKERS_CALENDARS = "/:language/economic-calendar";
export const BROKERS_CALENDARS_MAKER = (language) =>`/${language}/economic-calendar`;
export const BROKERS_EDUCATIONS = "/:language/education";
export const BROKERS_EDUCATIONS_MAKER =  (language) =>`/${language}/education`;
export const VIDEO_TRADERS = "/:language/video-traders";
export const VIDEO_TRADERS_MAKER =  (language) =>`/${language}/video-traders`;
export const BROKERS_INNOVATION = "/:language/innovation-in-trading";
export const BROKERS_INNOVATION_MAKER = (language) =>`/${language}/innovation-in-trading`;
export const BROKERS_FOREX_TRADING_BOOK = "/:language/forex-trading-books";
export const BROKERS_FOREX_TRADING_BOOK_MAKER = (language) =>`/${language}/forex-trading-books`;
export const BROKERS_FOREX_VIDEO_TUTORIAL = "/:language/forex-video-tutorials";
export const BROKERS_FOREX_VIDEO_TUTORIAL_MAKER = (language) =>`/${language}/forex-video-tutorials`;
export const BROKERS_FOREX_VIDEO_OVERVIEW = "/forex-video-overview";
export const BROKERS_FOREX_VIDEO_OVERVIEW_MAKER = (language) =>`/${language}/forex-video-overview`;
export const BROKER_PAGE = "/:language/broker_page";
export const BROKER_PAGE_MAKER = (language) =>`/${language}/broker_page`;
export const QUESTION_PAGE = "/:language/question-page/:slug";
export const QUESTION_DETAILS_PAGE_CREATOR = (language,slug) => `/${language}/question-page/${slug}`;
export const EDUCATION_TRADERS_GLASSARY = "/:language/traders_glossary";
export const EDUCATION_TRADERS_GLASSARY_MAKER = (language) =>`/${language}/traders_glossary`;
export const ARTICLES = "/:language/trading-articles";
export const ARTICLES_MAKER = (language) =>`/${language}/trading-articles`;
export const QUESTIONS = "/:language/questions";
export const QUESTIONS_MAKER = (language) =>`/${language}/questions`;
export const ARTICLES_DETAIL = "/:language/trading-articles/:slug";
export const ARTICLES_DETAIL_CREATOR = (language,slug) => `/${language}/trading-articles/${slug}`;

export const ADVISERS = "/:language/advisors";
export const ADVISERS_MAKER = (language) =>`/${language}/advisors`;

export const ADVISER = "/:language/advisor/:slug";
export const ADVISER_MAKER =  (language,slug) => `/${language}/advisor/${slug}`;

export const BUY_ADVISER = "/:language/buy-advisor/:slug";
export const BUY_ADVISER_MAKER =  (language,slug) => `/${language}/buy-advisor/${slug}`;

export const ADVISER_INSTRUCTION = "/:language/advisor-instruction";
export const ADVISER_INSTRUCTION_MAKER =  (language) => `/${language}/advisor-instruction`;

export const ADVISER_SUCCESSFULL_PAY = "/:language/advisors/successful-payment";
export const ADVISER_SUCCESSFULL_PAY_MAKER =  (language) => `/${language}/advisors/successful-payment`;

export const ADVISER_FAILED_PAY = "/:language/advisors/failed-payment";
export const ADVISER_FAILED_PAY_MAKER =  (language) => `/${language}advisors/failed-payment`;

export const ADVISER_NOTE_PAY = "/:language/advisors/notifications";
export const ADVISER_NOTE_PAY_MAKER =  (language) => `/${language}/advisors/notifications`;

export const OUR_ARTICLES_DETAIL = "/:language/trading-article/:slug";
export const OUR_ARTICLES_DETAIL_CREATOR = (language,slug) => `/${language}/trading-article/${slug}`;

export const PLATFORM_DETAILS_PAGE = `:language/trading-platforms/:sysname`;
export const PLATFORM_DETAILS_PAGE_CREATOR = (language,sysname) =>
  `/${language}/trading-platforms/${sysname}`;
export const MARKET_OVERVIEW_LIST = "/:language/financial-news";
export const MARKET_OVERVIEW_LIST_MAKER =  (language) =>`/${language}/financial-news`;
export const MARKET_OVERVIEW_DETAILS = `:language/financial-news/:slug`;
export const BROKERS_ANALYTICS = "/:language/analytics";
export const BROKERS_ANALYTICS_MAKER = (language) =>`/${language}/analytics`;
export const MARKET_SENTIMENT = "/:language/markets-sentiment";
export const MARKET_SENTIMENT_MAKER = (language) =>`/${language}/markets-sentiment`;
export const MARKET_COMMODITY_CALENDAR = "/:language/trade-calendar";
export const MARKET_COMMODITY_CALENDAR_MAKER = (language) =>`/${language}/trade-calendar`;
export const MARKET_MOVERS = "/:language/market-movers";
export const MARKET_MOVERS_MAKER = (language) =>`/${language}/market-movers`;
export const TOP_TRADERS = "/:language/top-traders";
export const TOP_TRADERS_MAKER = (language) =>`/${language}/top-traders`;
export const MARKET_OVERVIEW = "/:language/market-overview";
export const MARKET_OVERVIEW_MAKER = (language) =>`/${language}/market-overview`;
export const BROKERS_ANALYTICS_DETAILS = `:language/analytics/:instrName/:date`;
export const TECHNICAL_SUMMARIES = "/:language/technical-summaries";
export const TECHNICAL_SUMMARIES_MAKER = (language) =>`/${language}/technical-summaries`;

export const RSS_1 = "/:language/rss1";
export const RSS_1_MAKER = (language) =>`/${language}/rss1`;
export const RSS_2 = "/:language/rss2";
export const RSS_2_MAKER = (language) =>`/${language}/rss2`;
export const RSS_3 = "/:language/rss3";
export const RSS_3_MAKER = (language) =>`/${language}/rss3`;
export const RSS_4 = "/:language/rss4";
export const RSS_4_MAKER = (language) =>`/${language}/rss4`;
export const RSS_5 = "/:language/rss5";
export const RSS_5_MAKER = (language) =>`/${language}/rss5`;
export const RSS_6 = "/:language/rss6";
export const RSS_6_MAKER = (language) =>`/${language}/rss6`;
export const RSS_7 = "/:language/rss7";
export const RSS_7_MAKER = (language) =>`/${language}/rss7`;
export const RSS_8 = "/:language/rss8";
export const RSS_8_MAKER = (language) =>`/${language}/rss8`;
export const RSS_9 = "/:language/rss9";
export const RSS_9_MAKER = (language) =>`/${language}/rss9`;
export const RSS_10 = "/:language/rss10";
export const RSS_10_MAKER = (language) =>`/${language}/rss10`;
export const RSS_11 = "/:language/rss11";
export const RSS_11_MAKER = (language) =>`/${language}/rss11`;
export const RSS_12 = "/:language/rss12";
export const RSS_12_MAKER = (language) =>`/${language}/rss12`;
export const RSS_13 = "/:language/rss13";
export const RSS_13_MAKER = (language) =>`/${language}/rss13`;
export const RSS_14 = "/:language/rss14";
export const RSS_14_MAKER = (language) =>`/${language}/rss14`;
export const RSS_15 = "/:language/rss15";
export const RSS_15_MAKER = (language) =>`/${language}/rss15`;
export const RSS_16 = "/:language/rss16";
export const RSS_16_MAKER = (language) =>`/${language}/rss16`;
export const RSS_17 = "/:language/rss17";
export const RSS_17_MAKER = (language) =>`/${language}/rss17`;
export const RSS_18 = "/:language/rss18";
export const RSS_18_MAKER = (language) =>`/${language}/rss18`;
export const RSS_19 = "/:language/rss19";
export const RSS_19_MAKER = (language) =>`/${language}/rss19`;
export const RSS_20 = "/:language/rss20";
export const RSS_20_MAKER = (language) =>`/${language}/rss20`;
export const RSS_21 = "/:language/rss21";
export const RSS_21_MAKER = (language) =>`/${language}/rss21`;
export const RSS_22 = "/:language/rss22";
export const RSS_22_MAKER = (language) =>`/${language}/rss22`;

export const EXPERTS = "/:language/experts";
export const EXPERTS_MAKER = (language) =>`/${language}/experts`;
export const ARTICLES_RSS = "/expert_rss";
export const ARTICLES_RSS_MAKER = (language) =>`/${language}/expert_rss`;

export const CRYPTO_SHOP_ID = "qxwS40ocgs1XYfH1";
export const CRYPTO_API_KEY = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NTgwMywiZXhwIjo4ODA3Njk2OTc5MX0.miNfpIXJwVcnEJdjjg0eH9Tg6tiohz4oLqvA-tpY0Bg";

/**
 * Sockets
 */

export const SOCKET_URL = "wss://q.ifccd.net";
