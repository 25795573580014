export const SELECT_CATEGORY = [
  { value: 1, option: "Forex" },
  { value: 2, option: "Index CFDs" },
  { value: 3, option: "Commodity Futures" },
  { value: 4, option: "Stocks CFDs" },
  { value: 5, option: "Precious Metal" },
  { value: 6, option: "Gold Instruments" },
  { value: 7, option: "CFDs on ETF" },
  { value: 8, option: "Personal Instruments" }
];
export const SELECT_CATEGORY_ARR = [
  "Select All",
  "Forex",
  "Index CFDs",
  "Commodity Futures",
  "Stocks CFDs",
  "Precious Metal",
  "Gold Instruments",
  "CFDs on ETF",
  "Personal Instruments"
];
export const SELECT_CATEGORY_ARR_ROUTING = [
  "Select_All",
  "top-currencies",
  "top-indices",
  "top-commodities",
  "top-stocks",
  "top-precious-metals",
  "top-gold-cfds",
  "top-cfd-etf",
  "top-personal-instruments"
];
export const PERIOD_DATE_RANGE = [
  "Today",
  "Yesterday",
  "Within 7 days",
  "Within 30 Days",
  "Within 90 Days",
  "Within 365 Days",
  "From the Start of a Week",
  "From the Start of a Month",
  "From the Start of a Quarter",
  "From the Start of a Year",
  "Custom Range"
];
export const PERIOD_DATE_RANGE_ROUTING = [
  "within_day",
  "yesterday",
  "week",
  "month",
  "district",
  "year",
  "beginning-week",
  "beginning-month",
  "beginning-district",
  "beginning-year",
  "custom-date-range"
];
export const colourOptions = [
  { value: "ocean1", label: "Ocean", color: "#00B8D9" },
  { value: "blue", label: "Blue", color: "#0052CC" },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630" },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" }
];
