import React from "react";
import "../assets/css/components/footer.css";
import { translate } from "react-i18next";
import MenuNavFooter from "./header/MenuNavFooter";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF,  faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
library.add(faFacebookF, faInstagram, faTwitter);

class Footer extends React.Component {
  render() {
    const date = new Date().getFullYear();
    const { t } = this.props;
    return (
      <div className="footer">
            <div className='social_block'>
                <a href='https://www.facebook.com/ForexGradPlatform' aria-label='facebook' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                <a href='https://www.instagram.com/forexgrad/' aria-label='instagram' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                <a href='https://twitter.com/forex_grad' aria-label='twitter' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
            </div>
        <div className="f_text">
          <div className="container">{t("RISK_DISCLAIMER")}</div>
        </div>
        <div>
          <div className="container">
            <p>© {date} ForexGrad. All Rights Reserved</p>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("common")(Footer);
