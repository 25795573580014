import image1 from "./../../assets/images/bitcoin.png";
import image2 from "./../../assets/images/logos/NTX.png";
import image3 from "./../../assets/images/logos/MT4.png";
import image4 from "./../../assets/images/logos/MT5.png";
export const images = [
  image1,
  image2,
  image1,
  image4,
  image2,
  image3,
  image4,
  image2,
  image3,
  image4
];
