import React from "react";
import LatestReviews from "../containers/brokers/LatestReviews";
import PerfectBroker from "../containers/brokers/ChoosingPerfectBroker";
import BrokerRating from "../components/BrokerRating";
import "../assets/css/components/brokers/forex_brokers_rating.css";
import {
  attemptGetBrokerList,
  getBrokersFilter
} from "../redux/actions/indexActions";
import { connect } from "react-redux";
import Checkbox from "../containers/filters/Checkbox";
import { Helmet } from "react-helmet";
import "../assets/css/components/brokers/sorting.css";
import { translate } from "react-i18next";
import {isMobile} from "react-device-detect";
import RightBanner from "./RightBanner";

const BROKER_RATING_HEADERS = [
  "",
  "",
  "",
  "",
  "MINIMUM_SPREAD",
  "MINIMUM_DEPOSIT",
  "FOUNDED",
  ""
];

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class Broker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForBrokers: {
        paymentOptions: [],
        languages: [],
        advantages: [],
        tradingPlatforms: [],
        pageCount: 25,
        currentPage: 1,
      },
      fullItemsPay: 8,
      fullItemsAdv: 8,
      fullItemsPlat: 8,
      fullItemsLang: 8,
      orderBy: "rating",
      sortBy: "desc"
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserFilterForm = this.submituserFilterForm.bind(this);
  }

  handleChange(type, value) {
    let { filterForBrokers } = this.state;
    let newArray = filterForBrokers[type];
    if (filterForBrokers[type].includes(value)) {
      // remove element frm array
      newArray = filterForBrokers[type].filter(fil => fil !== value);
    } else {
      newArray.push(value);
    }
    this.setState({
      filterForBrokers: {
        ...this.state.filterForBrokers,
        [type]: newArray
      }
    });
  }
  isOnlyMt4Checked() {
    const {
      filterForBrokers: {
        tradingPlatforms,
        paymentOptions,
        languages,
        advantages
      }
    } = this.state;
    if (paymentOptions.length || languages.length || advantages.length) {
      return false;
    }
    if (tradingPlatforms.length === 1 && tradingPlatforms[0] === "mt4") {
      return true;
    }
  }

  submituserFilterForm() {
    const query = "?mt4-brokers";
    if (this.isOnlyMt4Checked()) {
      this.props.history.push(query);
    } else this.props.history.push("/brokers-ratings");
    this.props.attemptGetBrokerList(
      this.props.language,
      this.state.pageCount,
      this.state.currentPage,
      this.state.sortBy,
      this.state.orderBy,
      { filterData: this.state.filterForBrokers }
    );
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    var window_width = window.innerWidth;
    if (window_width <= 900) {
      document.getElementById("fil").style.width = "0";
      var content = document.getElementsByClassName("content")[0];
      content.classList.remove("content_hover");
      document
        .getElementsByClassName("black_opacity")[0]
        .classList.remove("active");
      document.body.classList.remove("body_overflow_hidden");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.attemptGetBrokerList(
        this.props.language,
        this.state.pageCount,
        this.state.currentPage,
        this.state.sortBy,
        this.state.orderBy
      );
      this.props.getBrokersFilter(this.props.language);
    }
  }

  componentDidMount() {
    if (
      this.props.location.search &&
      this.props.location.search === "?mt4-brokers"
    ) {
      this.setState(
        {
          filterForBrokers: {
            ...this.state.filterForBrokers,
            tradingPlatforms: ["mt4"]
          }
        },
        () => {
          this.props.attemptGetBrokerList(
            this.props.language,
            25,
            this.state.currentPage,
            this.state.sortBy,
            this.state.orderBy,
            { filterData: this.state.filterForBrokers }
          );
          this.props.getBrokersFilter(this.props.language);
        }
      );
    } else {
      this.props.attemptGetBrokerList(
        this.props.language,
        25,
        this.state.currentPage,
        this.state.sortBy,
        this.state.orderBy
      );
      this.props.getBrokersFilter(this.props.language);
    }
    document.body.classList.add("filter_body");
    document.getElementsByTagName('html')[0].classList.add('filter_html');
  }

  attemptGetBrokerList() {
    this.props.attemptGetBrokerList(
      this.props.language,
      this.state.pageCount,
      this.state.currentPage,
      this.state.sortBy,
      this.state.orderBy,
      { filterData: this.state.filterForBrokers }
    );
  }

  openFilter = () => {
    document.getElementById("fil").style.width = "300px";
    document.getElementsByClassName("black_opacity")[0].classList.add("active");
    var content = document.getElementsByClassName("content")[0];
    content.classList.add("content_hover");
    document.body.classList.add("body_overflow_hidden");
    document.getElementsByTagName('html')[0].classList.add('body_overflow_hidden');
  };

  closeFilter = () => {
    document.getElementById("fil").style.width = "0";
    var content = document.getElementsByClassName("content")[0];
    content.classList.remove("content_hover");
    document
      .getElementsByClassName("black_opacity")[0]
      .classList.remove("active");
    document.body.classList.remove("body_overflow_hidden");
    document.getElementsByTagName('html')[0].classList.remove('body_overflow_hidden');
  };
  changeElementsCount = (name, length) => {
    this.setState({[name]: [length]});
  }

  render() {
    const {
      filterForBrokers: { tradingPlatforms }
    } = this.state;
    const { history, t,language } = this.props;
    const date = new Date().getFullYear();

    return (
      <div>
        <Helmet>
          <title>
            {`Best Forex Brokers ${date} | Forex Brokers Reviews | ForexGrad`}
          </title>
          <meta name="description" content= {t("BROKER_PAGE_DESCRIPTION")} />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container mb-4 d-flex flex-wrap">
          <div
            className="black_opacity"
            onClick={this.closeFilter.bind(this)}
          />
          <div className="left_box_block left_box" id="fil">
            <div className="anim_filter_block">
              <h5>
                {t("FILTERS")}
              </h5>
              <div className="filter_block">
                <div className="fitler_title">
                  {t("PAYMENT_METHODS")}
                </div>
                <div className="filters_list loader_block">
                  {this.props.brokersLang.paymentOptions ? (
                    this.props.brokersLang.paymentOptions.list.length ? (
                      this.props.brokersLang.paymentOptions.list.map(
                        (item, index) => {
                          return (
                              <div key={index}>
                                {
                                  (index < this.state.fullItemsPay || !isMobile) ?
                                  <Checkbox
                                  group="paymentOptions"
                                  key={index}
                                  id={item.sysname}
                                  title={item.name}
                                  onChange={e =>
                                  this.handleChange(
                                  "paymentOptions",
                                  item.sysname
                                  )
                                }
                                  /> : ''
                                }
                              </div>
                          );
                        }
                      )
                    ) : (
                      <h3>
                        {t("NO_DATA_FOR_SHOW")}
                      </h3>
                    )
                  ) : (
                    <div className="loader">
                      {t("LOADING")}
                    </div>
                  )}
                    <div className = 'see_less_more'>
                      {
                        this.state.fullItemsPay < 9 && isMobile?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsPay', this.props.brokersLang.paymentOptions.list.length)} >
                              see more <span className="icon-down-small" /></button> : isMobile ?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsPay', 8)} >
                              see less <span className="icon-up-small" /></button> : ''
                      }
                    </div>
                </div>
              </div>
              <div className="filter_block">
                <div className="fitler_title">
                  {t("SUPPORTED_LANGUAGES")}
                </div>
                <div className="filters_list loader_block">
                  <form onSubmit={this.handleSubmit}>
                    {this.props.brokersLang.languages ? (
                      this.props.brokersLang.languages.length ? (
                        this.props.brokersLang.languages.map((item, index) => {
                          return (
                              <div key={index}>
                                {
                                  (index < this.state.fullItemsLang || !isMobile) ?
                                      <Checkbox
                                          group="languages"
                                          key={index}
                                          id={item.shortCode}
                                          title={item.name}
                                          onChange={e =>
                                              this.handleChange("languages", item.shortCode)
                                          }
                                      /> : ''
                                }
                              </div>
                          );
                        })
                      ) : (
                        <h3>
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <div className="loader">
                        {t("LOADING")}
                      </div>
                    )}
                    <div className = 'see_less_more'>
                      {
                        this.state.fullItemsLang < 9 && isMobile?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsLang', this.props.brokersLang.languages.length)} >
                              see more <span className="icon-down-small" /></button> : isMobile ?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsLang', 8)} >
                              see less <span className="icon-up-small" /></button> : ''
                      }
                    </div>
                  </form>
                </div>
              </div>
              <div className="filter_block">
                <div className="fitler_title">
                  {t("ADVANTAGES")}
                </div>
                <div className="filters_list loader_block">
                  <form onSubmit={this.handleSubmit}>
                    {this.props.brokersLang.advantages ? (
                      this.props.brokersLang.advantages.length ? (
                        this.props.brokersLang.advantages.map((item, index) => {
                          return (
                              <div key={index}>
                                {
                                  (index < this.state.fullItemsAdv || !isMobile) ?
                                      <Checkbox
                                          group="advantages"
                                          key={index}
                                          id={item.sysname}
                                          title={item.name}
                                          onChange={e =>
                                              this.handleChange("advantages", item.sysname)
                                          }
                                      /> : ''
                                } </div>
                          );
                        })
                      ) : (
                        <h3>
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <div className="loader">
                        {t("LOADING")}
                      </div>
                    )}
                    <div className = 'see_less_more'>
                      {
                        this.state.fullItemsAdv < 9 && isMobile?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsAdv', this.props.brokersLang.advantages.length)} >
                              see more <span className="icon-down-small" /></button> : isMobile ?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsAdv', 8)} >
                              see less <span className="icon-up-small" /></button> : ''
                      }
                    </div>
                  </form>
                </div>
              </div>
              <div className="filter_block">
                <div className="fitler_title">
                  {t("TRADING_PLATFORMS")}
                </div>
                <div className="filters_list loader_block">
                  <form onSubmit={this.handleSubmit}>
                    {this.props.brokersLang.tradingPlatforms ? (
                      this.props.brokersLang.tradingPlatforms.length ? (
                        this.props.brokersLang.tradingPlatforms.map(
                          (item, index) => {
                            return (
                                <div key={index}>
                                  {
                                    (index < this.state.fullItemsPlat || !isMobile) ?
                                        <Checkbox
                                            group="tradingPlatforms"
                                            key={index}
                                            id={item.sysname}
                                            title={item.name}
                                            defaultChecked={tradingPlatforms.includes(
                                                item.sysname
                                            )}
                                            onChange={e =>
                                                this.handleChange(
                                                    "tradingPlatforms",
                                                    item.sysname
                                                )
                                            }
                                        />: ''
                                  } </div>
                            );
                          }
                        )
                      ) : (
                        <h3>
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <div className="loader">
                        {t("LOADING")}
                      </div>
                    )}
                    <div className = 'see_less_more'>
                      {
                        this.state.fullItemsPlat < 9 && isMobile?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsPlat', this.props.brokersLang.tradingPlatforms.length)} >
                              see more <span className="icon-down-small" /></button> : isMobile ?
                            <button className="bg-transparent btn-link" onClick={() => this.changeElementsCount('fullItemsPlat', 8)} >
                              see less <span className="icon-up-small" /></button> : ''
                      }
                    </div>
                  </form>
                  {/*<button*/}
                  {/*    className="btn btn_lg button_green"*/}
                  {/*    onClick={this.submituserFilterForm}*/}
                  {/*>*/}
                  {/*  /!*SUBMIT*!/*/}
                  {/*  /!*Search Brokers*!/*/}
                  {/*  {t("SEARCH_BROKER")}*/}
                  {/*</button>*/}
                </div>
              </div>
            </div>
            { (isMobile) ?  <div style={{position: 'fixed', top: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 80,
                                  padding:'15px 30px', boxShadow:"none"}}>
                  <button
                      style={{width: '100%'}}
                    className="btn btn_lg button_green"
                    onClick={this.submituserFilterForm}
                >   {/*SUBMIT*/}
                  {/*Search Brokers*/}
                  {t("SEARCH_BROKER")}
                </button>
                </div>:
                <div className="d-flex justify-content-center expel_class">
                  <button
                      className="btn btn_lg button_green"
                      onClick={this.submituserFilterForm}
                  >
                    {t("SEARCH_BROKER")}
                  </button>
                </div>
             }


            <RightBanner/>
          </div>
          <div className="main_with_right">
            {history.location.search ? (
              <h1>
                {t("MT4_BROKERS_RAITING")}
              </h1>
            ) : (
              <h1>
                {renderHtml(
                  t("BEST_FOREX_BROKERS_DATE", {
                    date: date
                  })
                )}
              </h1>
            )}
            <div className="bg_wrap container mb-4">
              {renderHtml(t("BETS_FOREX_BROKER"))}
            </div>
            <h2>{t("BROKER_TABLE_H2")}</h2>
            <div className="bg_wrap container mb-4">
              <p>{t("BROKER_TABLE_ANNOTATION")}</p>
              <div className="sort_by">
                <ul>
                  <li
                    className="filter_mobile_li"
                    onClick={this.openFilter.bind(this)}
                  >
                    <i className="icon-filter"></i>
                    <div>
                      {t("FILTERS")}
                    </div>
                  </li>
                  <li>
                    {t("SORT_BY")}:
                  </li>

                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "rating"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {t("RAITING")}
                    {this.state.orderBy === "rating" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>

                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "minDeposit"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {t("MINIMUM_DEPOSIT")}
                    {this.state.orderBy === "minDeposit" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>

                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "minSpread"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {t("MINIMUM_SPREAD")}
                    {this.state.orderBy === "minSpread" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "founded"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {t("FOUNDATION_YEAR")}
                    {this.state.orderBy === "founded" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>
                </ul>
              </div>
              <div className="sort_by mobile">
                <div>
                  <ul>
                    <li onClick={this.openFilter.bind(this)}>
                      <i className="icon-filter" />
                      <div>
                        {t("FILTERS")}
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        this.setState(
                          {
                            sortBy:
                              this.state.sortBy === "desc" ? "asc" : "desc",
                            orderBy: "rating"
                          },
                          () => this.attemptGetBrokerList()
                        );
                      }}
                    >
                      <div>
                        {t("SORT_BY")}
                      </div>
                      <div>
                        {t("RAITING")}
                      </div>
                      {this.state.orderBy === "rating" && (
                        <div
                          className={`arrow_${
                            this.state.sortBy === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="swipe">
                Swipe table** <span className="icon-right-small" />
              </div>
              <div className="broker_list">
                <table>
                  <thead className="RatingCustom">
                    <tr>
                      {BROKER_RATING_HEADERS.map((header, index) => (
                        <th key={index}>{t(header)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.brokers ? (
                      this.props.brokers.length ? (
                        this.props.brokers.map((item, index) => {
                          let firstFourRowsClass = null;
                          if(index < 5){
                             firstFourRowsClass = "firs_four_yellow";
                          }
                          return (
                            <BrokerRating
                              id={index}
                              language={language}
                              key={index}
                              logo={item.logo}
                              name={item.name}
                              rate={item.averageRate.toFixed(1)}
                              minSpread={item.minSpread}
                              minDeposit={item.minDeposit}
                              founded={item.founded}
                              btnText={item.externalUrl}
                              sysname={item.sysname}
                              rowClassName={(firstFourRowsClass != null) ? firstFourRowsClass: ""}
                            />
                          );
                        })
                      ) : (
                          <tr>
                            <td>
                              <h3>
                                {t("NO_DATA_FOR_SHOW")}
                              </h3>
                            </td>
                          </tr>

                      )
                    ) : (
                        <tr>
                          <td>
                            <h1 className="loader">
                              {t("LOADING")}
                            </h1>
                          </td>
                        </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <h2>{t("BEST_FOREX_BROKER_h2")}</h2>
            <div className="bg_wrap container mb-4">
              {renderHtml(t("BEST_FOREX_BROKER_TEXT"))}
            </div>
            <h2>{t("FOREX_BROKER_BEGINNER_h2")}</h2>
            <div className="bg_wrap container mb-4">
              {renderHtml(t("FOREX_BROKER_BEGINNER_TEXT"))}
            </div>
            <h2>
              {t("LATEST_REVIEWS")}
            </h2>
            <div className="bg_wrap container mb-4">
              <LatestReviews countPerPage={5} />
            </div>
            {history.location.search ? (
              <h1>
                {t("PERFECT_BROKER_MT4")}
              </h1>
            ) : (
              <h2>
                {t("PERFECT_BROKER")}
              </h2>
            )}
            <div className="bg_wrap">
              <PerfectBroker search={history.location.search} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brokers: store.brokers,
    brokersLang: store.brokersLang,
    language: store.language,
    filterForBrokers: store.filterForBrokers
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetBrokerList: (lang, countPerPage, page, orderby, sortBy, filter) =>
      dispatch(
        attemptGetBrokerList(lang, countPerPage, page, orderby, sortBy, filter)
      ),
    getBrokersFilter: lang => dispatch(getBrokersFilter(lang))
    // getFiltersArray: (e) => dispatch(getFiltersArray(e))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(Broker)
);
