import React from "react";
import "./../../assets/css/components/education/education_section.css";
import { Link } from "react-router-dom";
import roundArrow from "../../assets/images/round_right_arrow.png";
import {connect} from "react-redux";

const arrEduSec = [
  {
    yout_icn: "yout_video_icn",
    text: "Forex Video Tutorials",
    arrow: "right_arrow",
    link_to: "forex-video-tutorials"
  },
  {
    yout_icn: "icon-books",
    text: "Forex and CFD Books",
    arrow: "right_arrow",
    link_to: "forex-trading-books"
  },
  {
    yout_icn: "icon-glossary",
    text: "Forex and CFD Glossary",
    arrow: "right_arrow",
    link_to: "/traders_glossary"
  },
  {
    yout_icn: "icon-faq",
    text: "FAQ",
    arrow: "right_arrow",
    link_to: "forex-trading-books"
  }
];

class EducationSections extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const {language } = this.props;

    return (
      <div className="education_section">
        {arrEduSec.map((item, index) => {
          return (
            <div className="edu_sec">
              <Link to={language + "/" + item.link_to} className="right_box">
                <div key={index}>
                  <div className="edu_sec_blk">
                    <div className="left_part">
                      <div className={item.yout_icn}>
                        <div></div>
                      </div>
                      {item.text}
                    </div>
                    <div className={item.arrow}>
                      <img src={roundArrow} alt="roundArrow" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(
    state => ({
      language: state.language
    }),
)(EducationSections);
