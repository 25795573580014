import React from "react";
import "./../assets/css/components/accardion.css";
import Global from "../assets/css/Global.module.css";
import TopBrokers from "../containers/TopBrokers";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {attemptGetQuestions} from "../redux/actions/indexActions";
import {Link} from "react-router-dom";
import {QUESTION_DETAILS_PAGE_CREATOR} from "../configs/constants";


class Questions extends React.Component {

    componentDidMount() {
        this.props.attemptGetQuestions(
            this.props.language
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.language !== prevProps.language) {
            this.props.attemptGetQuestions(
                this.props.language
            );
        }
    }

    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        const {questions} = this.props;
        const {t,language} = this.props;
        return (

            <div className="d-flex flex-wrap">
                <div className="container mb-4 d-flex flex-wrap">
                    <div className={`${Global.main_with_left}`}>
                        <h1>{t("EXPERT_CORNER")}</h1>
                        {
                            questions && questions.map(item => {
                                return (
                                    <div className={`${Global.bg_wrap} questions_wrap w-100`}>
                                        <div className={"d-flex flex-wrap justify-content-between mb-3 pb-1"}>
                                            <h3>{item ? item.title : ""}</h3>
                                            <div className={`${Global.date_block}`}>{item && item.date}</div>
                                        </div>
                                        {
                                            item.arr.length !== 0 ? item.arr.map((val, index) => {
                                                return (
                                                    <Link to={QUESTION_DETAILS_PAGE_CREATOR(language,val.slug)}
                                                          className={`question-text-link`}
                                                          onClick={this.scrolling.bind(this)}
                                                    >
                                                        {val.title}
                                                    </Link>
                                                )
                                            }) : ""
                                        }
                                    </div>
                                )
                            }).reverse()
                        }
                    </div>
                    <div className={Global.right_box_block}>
                        {/*<div className={Global.expel_class}>*/}
                        {/*    <EducationSections/>*/}
                        {/*</div>*/}
                        <div className={Global.expel_class}>
                            <TopBrokers/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        language: store.language,
        questions: store.questions,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        attemptGetQuestions: (lang) =>
            dispatch(attemptGetQuestions(lang)),
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(Questions)
);