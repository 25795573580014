import React from "react";
import {Helmet} from "react-helmet";

class AdviserInstruction extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <Helmet>
                    <title>{`Advisor ForexGrad | ForexGrad`}</title> todo
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    <h1>MetaTrader 4 Expert Advisors — Installation Tutorial</h1>
                    <div className="txt_wrap">
                        <ul className="ul-tick">
                            <li>Download an MT4 expert advisor and save it anywhere on your PC.</li>
                            <li>Launch your trading platform.</li>
                            <li>Open the platform's data folder via menu <strong><i>File->Open Data Folder</i></strong>.</li>
                            <li>Open <strong><i>MQL4</i></strong> folder</li>
                            <li>Open <strong><i>Experts</i></strong> folder.</li>
                            <li>Paste your expert advisor file <strong><i>(.ex4)</i></strong> into this <strong><i>Experts folder</i></strong>.</li>
                            <li>All you need to do is return to your MetaTrader4, right-click on <strong><i>Expert Advisors</i></strong> in the Navigator panel, and click Refresh <strong><i>(in context menu)</i></strong>. The new expert advisor will appear in the same Navigator panel.</li>
                            <li>To start using the advisor on the trading instrument you must link the advisor to the active chart of this instrument - select the adviser in the <strong><i>Navigator panel</i></strong> in the <strong><i>Experts </i></strong> folder and double-click on the adviser's name.  The name of the Expert Advisor will be displayed in the upper right corner of the instrument chart.</li>
                            <li>In the Expert Advisor's properties (in the chart parameters) check the <strong><i>"Allow trading".</i></strong> Additionally, you can change some of the advisor's input parameters in the Inputs tab.</li>
                            <li> Make sure auto-trading is enabled globally – <strong><i>“Auto Trading”</i></strong> in main panel MT4. </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdviserInstruction ;