import React from "react";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import {
  OUR_ARTICLES_DETAIL_CREATOR
} from "../../configs/constants";
import {
  getArticleList,
  onClearArticleList
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import TopBrokers from "../TopBrokers";
import TopPromotionsBlock from "../../containers/TopPromotionsBlock";
import { Helmet } from "react-helmet";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";
import Pagination from "react-js-pagination";
import RightBanner from "../../components/RightBanner";

const renderHtml = props => (
  <div className="txt_link" dangerouslySetInnerHTML={{ __html: props }}></div>
);

class AllArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsCount: 0,
      activePage: 1,
      page: 1,
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  componentDidMount() {
    this.props.getArticleList(this.props.language, 5, this.state.activePage);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language ||
        this.state.activePage !== prevState.activePage ) {
      this.props.getArticleList(this.props.language,  5, this.state.activePage);
    }
  }

  render() {
    const { article, t,language } = this.props;
    return (
      <div>
        <Helmet>
          <title>{`FOREX ARTICLES | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container mb-4 d-flex flex-wrap">
          <div
            className={`main_with_left ${Analytics.tech_analysis}`}
          >
            <h1>{t("EXPERT_CORNER")}</h1>
            <div className={Global.bg_wrap}>
              {(article && Object.keys(article).length > 0 && Object.getPrototypeOf(article) === Object.prototype) ? (
                article.list.map((item, index) => {
                  return (
                    <div className={Analytics.tech_wrap} key={index}>
                      {
                        item.company === 'ifcm' ?
                            <Link to={OUR_ARTICLES_DETAIL_CREATOR(language,item.slug)}>
                              <div className={Analytics.tech_top}>
                                <h3>{item.title}</h3>
                                <div className="date_block">{`${
                                    item.date.split("T")[0]
                                }`}</div>
                              </div>
                            </Link> :
                            <a href={item.link}>
                              <div className={Analytics.tech_top}>
                                <h3>{item.title}</h3>
                                <div className="date_block">{`${
                                    item.date.split("T")[0]
                                }`}</div>
                              </div>
                            </a>
                      }
                        <div>
                        {item.company === 'ifcm' ?
                          <Link to={OUR_ARTICLES_DETAIL_CREATOR(language,item.slug)}>
                          <div className={Analytics.new_cont_block}>
                          {renderHtml(item.description)}
                          </div>
                          </Link>  :
                        <a href={item.link}>
                          <div className={Analytics.new_cont_block}>
                            {renderHtml(item.description)}
                          </div>
                        </a> }
                      <a href={item.expertLink ? item.expertLink : item.link} target="_blank" rel="noopener noreferrer">
                        <div className={Analytics.expert_block}>
                          <div>
                              <img
                                  src={item.picture}
                                  alt={"ex cor."}
                                  style={{ width: "50px" }}
                              />
                          </div>
                          <div className={Analytics.expert_right_block}>
                            <div>Author</div>
                            <div>{`${item.author}`}</div>
                          </div>
                        </div>
                     </a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1 className="loader">
                  {/*loading***/}
                  {t("LOADING")}
                </h1>
              )}
              <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={article? article.count : 0}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
              />

              <p className={Global.btm_txt}>
                {/*Technical analysis is a useful methodology of predicting market*/}
                {/*direction and making buy/sell decisions using market data. The*/}
                {/*key points of technical analysis are: Forex Trend, Retracement*/}
                {/*Levels, Channels, Support and Resistance Levels. The wide range*/}
                {/*of Technical Indicators and Forex Oscillators are the main tools*/}
                {/*of making technical analysis.{" "}***/}
                {t("TECHNICAL_ANALYSIS_MAIN_TOOL")}
              </p>
            </div>
          </div>
          <div className={Global.right_box_block}>
            <div className={Global.expel_class}>
              <TopBrokers />
            </div>
            <div className={Global.expel_class}>
              <TopPromotionsBlock />
            </div>
            <RightBanner/>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language,
    article: store.article
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getArticleList: (lang, countPerPage, page) =>
      dispatch(getArticleList(lang, countPerPage, page)),
    onClearArticleList: () => dispatch(onClearArticleList())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(AllArticles)
);
