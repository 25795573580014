import React from "react";
//import { saveAs } from 'file-saver';
import {Helmet} from "react-helmet";
import {ADVISER_INSTRUCTION_MAKER, CRYPTO_SHOP_ID,CRYPTO_API_KEY} from "../configs/constants";
import {Link} from "react-router-dom";

// const advisers = {
//     'Reopen_Buy': {'price': 50},
//     'Close_pos_stop': {'price': 30},
//     'MACD_close': {'price': 50},
//     'Reopen_Sell': {'price': 50},
//     'Del_pos_order': {'price': 30},
//     'Ma_cross': {'price': 60},
// };


const advisers = {
    'Reopen_Buy': {'price': 2},
    'Close_pos_stop': {'price': 2},
    'MACD_close': {'price': 2},
    'Reopen_Sell': {'price': 2},
    'Del_pos_order': {'price': 2},
    'Ma_cross': {'price': 2},
};


class buyAdvisers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        };
    }

    handleSubmit = (e) => {
        console.log(this.state.email, this.state.password)
        e.preventDefault();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>{`Advisor ForexGrad | ForexGrad`}</title>
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                    <link href="https://api.cryptocloud.plus/static/widget/v2/css/app.css" rel="stylesheet"></link>
                    <script src="https://api.cryptocloud.plus/static/widget/v2/js/app.js"></script>
                </Helmet>
                <div className="container mb-30">
                </div>
                <div className="container mb-30">
                    <h1>Registration</h1>
                    <p>Enter your email to use this service:</p>
                    <h2>Advisor:  {this.props.match.params.slug} </h2>
                    <vue-widget shop_id={CRYPTO_SHOP_ID}
                                api_key={CRYPTO_API_KEY}
                                currency="USD" amount={advisers[this.props.match.params.slug].price} locale="en"></vue-widget>
                    {/*<form onSubmit={this.handleSubmit}>*/}
                    {/*    <div className="form-group">*/}
                    {/*        <label htmlFor="email">Email:</label>*/}
                    {/*        <input type="email" className="form-control" id="email" placeholder="Enter email"*/}
                    {/*               name="email" value={this.state.email}*/}
                    {/*               onChange={this.handleChange}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-group">*/}
                    {/*        <label htmlFor="password">Password:</label>*/}
                    {/*        <input type="password" className="form-control" id="password" placeholder="Enter password"*/}
                    {/*               name="password" value={this.state.password}*/}
                    {/*               onChange={this.handleChange}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="checkbox">*/}
                    {/*    </div>*/}
                    {/*    <p style={{'font-size': '20px'}} className="font-10">Price: <b>{advisers[this.props.match.params.slug].price}</b> USD</p>*/}
                    {/*    <button type="submit" className="btn btn-primary">Submit</button>*/}
                    {/*</form>*/}
                </div>
                <div className="container mb-4 d-flex flex-wrap">
                    <Link
                        to={ADVISER_INSTRUCTION_MAKER('en')}>
                        Instruction for {this.props.match.params.slug}
                    </Link>
                </div>
            </div>
        );
    }
}

export default buyAdvisers;