import React from "react";
import "./../assets/css/components/accardion.css";
import Global from "../assets/css/Global.module.css";
import TopBrokers from "../containers/TopBrokers";
import {attemptGetQuestionsDetails} from "../redux/actions/indexActions";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {QUESTION_DETAILS_PAGE_CREATOR} from "../configs/constants";

const renderHtml = props => (
    <div dangerouslySetInnerHTML={{__html: props}}></div>
);

class QuestionPage extends React.Component {

    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    componentDidMount() {
        this.props.attemptGetQuestionsDetails(this.props.match.params.slug, this.props.language)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.language !== prevProps.language ||
            this.props.match.params.slug !== prevProps.match.params.slug
        ) {
            this.props.attemptGetQuestionsDetails(this.props.match.params.slug, this.props.language)
        }
    }

    render() {
        const {questionDetails} = this.props;
        const {t} = this.props;
        return (

            <div className="d-flex flex-wrap">
                <div className="container mb-4 d-flex flex-wrap">
                    <div className={`${Global.main_with_left} questions_wrap`}>
                        <h1>{t("EXPERT_CORNER")}</h1>
                        <div className={`${Global.bg_wrap}`}>

                            <h2>{questionDetails ? questionDetails.questions[0].themTitle : ""}</h2>
                            <div className={"d-flex flex-wrap justify-content-between mb-3 pb-1"}>
                                <h3>{questionDetails ? questionDetails.answersList[0].title : " "}</h3>
                                <div>
                                    <span className="date_block">
                                        {questionDetails && questionDetails.answersList[0].date}
                                    </span>
                                </div>
                            </div>
                            <ul>
                                {questionDetails ? questionDetails.answersList.map((data, key) => {
                                    return (
                                        <AccordionItem {...data} key/>
                                    )
                                }) : ""}
                            </ul>

                            {
                                questionDetails ? questionDetails.questions.map((val, index) => {
                                    return (
                                        <Link to={QUESTION_DETAILS_PAGE_CREATOR(val.slug)}
                                              className={"question-text-link"}
                                              onClick={this.scrolling.bind(this)}
                                        >
                                            {val.title}
                                        </Link>
                                    )
                                }) : ""
                            }
                        </div>
                    </div>
                    <div className={Global.right_box_block}>
                        <div className={Global.expel_class}>
                            <TopBrokers/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class AccordionItem extends React.Component {
    state = {
        opened: false
    }

    render() {
        const {
            state: {
                opened
            }
        } = this;
        return (
            <li
                className={`${opened && "accordion-item--opened"}`}
                onClick={() => {
                    this.setState({opened: !opened})
                }}
            >
                <div className="accordion-item__line">
                    <div className="accordion-item__title">
                        <img
                            src={this.props.picture}
                            width={40}
                            height={40}
                            alt="Expert Corner"
                        />
                        <div className="author-block">
                            <span className="author">Author</span>
                            {this.props.firstName + " " + this.props.lastName}
                        </div>
                    </div>

                    <span className="acc-arrow"/>
                </div>
                <div className="accordion-item__inner">
                    <div className="accordion-item__content accordion-item__paragraph">
                        {renderHtml(this.props.content)}
                    </div>
                </div>
            </li>
        )
    }
}

const mapStateToProps = (store, props) => {
    return {
        language: store.language,
        questionDetails: store.questionDetails,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        attemptGetQuestionsDetails: (slug, lang) =>
            dispatch(attemptGetQuestionsDetails(slug, lang)),
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(QuestionPage)
);