import React from "react";
import "../../assets/css/components/innovation/innovation.css";
import TopBrokers from "../../containers/TopBrokers";
import "../../assets/css/components/articles.css";
import {
    getArticleDetails,
    onClearArticleDetails
} from "../../redux/actions/indexActions";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import ExpertCorner from "../../containers/articles/ExpertCorner";
import {Helmet} from "react-helmet";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";

const renderHtml = props => (
    <div className="mt-4" dangerouslySetInnerHTML={{__html: props}}></div>
);

class Articles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }

    attemptGetData(slug, lang = 'en') {
        fetch(process.env.REACT_APP_API_URL + `/articles/details?lang=${lang}&slug=${slug}`, {
            method: "get",
            headers: {"Content-Type": "application/x-www-form-urlencoded"}
        })
            .then(res => res.json())
            .then(data => {
                this.setState({data});
            })
            .catch();
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.attemptGetData(this.props.match.params.slug, this.props.language);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.slug !== prevProps.match.params.slug || this.props.language !== prevProps.language) {
            this.attemptGetData(this.props.match.params.slug, this.props.language);
        }
    }

    render() {
        const {articleDetails, t} = this.props;
        const {data} = this.state;

        return (
            <div>
                <Helmet>
                    <title>{`FOREX ARTICLES ${
                        articleDetails ? `| ${articleDetails.title}` : ""
                    } | ForexGrad`}</title>
                    <meta name="description" content=""/>
                    <meta name="theme-color" content="#008f68"/>
                </Helmet>
                <div className="container mb-4 d-flex flex-wrap">
                    {data ? (
                        <div className="main_with_left">
                            <h1>{data.title}</h1>
                            <div className={Global.bg_wrap}>
                                <a href={data.link} target="_blank" rel="noopener noreferrer"
                                   style={{textDecoration: "none"}}>
                                    <div className={Analytics.expert_block}>
                                        <img
                                            style={{width: "60px"}}
                                            src={data.picture}
                                            alt="ex cor."
                                        />
                                        <div className={Analytics.expert_right_block}>
                                            {`${data.firstName} ${data.lastName}`}
                                        </div>
                                    </div>
                                </a>
                                {renderHtml(data.content)}
                            </div>
                        </div>
                    ) : (
                        <div className="main_with_left">
                            <div className={Global.bg_wrap}>
                                <h1 className="loader">
                                    {t("LOADING")}
                                </h1>
                            </div>
                        </div>
                    )}

                    <div className={Global.right_box_block}>
                        <div className={Global.expel_class}>
                            <TopBrokers/>
                        </div>
                        <div className={`${Global.expel_class} ${Global.top_5}`}>
                            <ExpertCorner/>
                        </div>
                        <div className={Global.expel_class}>
                            <a
                                target="_blank"
                                href="https://ifcmarkets.com/en/open-demo-account"
                                rel="noopener noreferrer"
                                className="btn btn_lg button_grayish_blue demo_btn"
                            >
                                Start Education on Demo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        language: store.language,
        articleDetails: store.articleDetails
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getArticleDetails: (lang, slug) => dispatch(getArticleDetails(lang, slug)),
        onClearArticleDetails: () => dispatch(onClearArticleDetails())
    };
}

export default translate("common")(
    connect(mapStateToProps, mapDispatchToProps)(Articles)
);
