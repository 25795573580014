import React from "react";
import "../assets/css/components/eco_calendar.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";

class EconomicCalendar extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={`eco_calendar ${this.props.heigth}`}>
        <h5>
          {/*Economic Calendar*/}
          {t("LIST_ECANOMIC_CALENDAR")}
        </h5>
        <div className="bg_wrap main_half">
          <div className="swipe">
            {t("SWIPE_TABLE")} <span className="icon-right-small"></span>
          </div>
          <div className="economic_calendar_filter">
            <ul>
              <li>
                <label>
                  <span className="economic-calendar__importance none" />
                  {/*Holidays***/}
                  {t("HOLIDAYS")}
                </label>
              </li>
              <li>
                <label>
                  <span className="economic-calendar__importance low" />
                  {/*Low*/}
                  {t("LOW")}
                </label>
              </li>
              <li>
                <label>
                  <span className="economic-calendar__importance medium" />
                  {/*Medium***/}
                  {t("MEDIUM")}
                </label>
              </li>
              <li>
                <label>
                  <span className="economic-calendar__importance high" />
                  {/*High*/}
                  {t("HIGH")}
                </label>
              </li>
            </ul>
          </div>
          <div id="economicCalendarWidget" />
          <div className="eco_ifrm">
            <iframe
              title={"mt5"}
              // src={`https://www.mql5.com/${this.props.language}/economic-calendar/widget`}
              src={`https://www.mql5.com/en/economic-calendar/widget`}
              frameorder="0"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};

export default translate("common")(connect(mapStateToProps)(EconomicCalendar));
